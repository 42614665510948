import React from 'react';
import eyeIcon from '../../user-dashboard/asstes/dash-icons/eyeIcon.svg';
import editIcon from '../assets/icons/edit-icon.svg';
import deleteIcon from '../../user-dashboard/asstes/dash-icons/deleteIcon.svg';
import DataTable, { Alignment, Direction } from 'react-data-table-component';
import { formatDate, formatRemainingTime } from '../../user-dashboard/utils/helper';

const RecentlyReferred = ({ referData }) => {
    const columns = [
        {
            name: 'Homeowner First Name',
            selector: (row) => row.first_name,
            sortable: false,
            reorder: true
        },
        {
            name: 'Homeowner Last Name',
            selector: (row) => row.last_name,
            sortable: false,
            reorder: true
        },
        {
            name: 'Email',
            selector: (row) => row.email,
            sortable: false,
            reorder: true
        },
        {
            name: 'Account Sign Up Date',
            selector: (row) => row.signup_date || 'N/A',
            sortable: false,
            reorder: true
        },
        {
            name: 'Renewal Date',
            selector: (row) => row.RenewalDate,
            sortable: false,
            reorder: true
        },
        {
            name: 'Remaining Time to Claim Your Royalty',
            selector: (row) => row.RoyaltyClaimTimeLeft,
            sortable: false,
            reorder: true
        }
        // {
        //     name: "Action",
        //     selector: (row) => row.Action,
        //     sortable: false,
        //     reorder: true,
        // }
    ];

    const data = Array.isArray(referData)
        ? referData.map((item) => ({
              id: 1,
              item_id: item.id,
              first_name: <div className="fileName">{item.homeowner_first_name}</div>,
              last_name: <div className="fileName">{item.homeowner_last_name}</div>,
              email: <div className="fileName">{item.homeowner_email}</div>,
              signup_date: <div className="fileName"> {item.signup_date ? formatDate(item.signup_date) : 'N/A'}</div>,
              RenewalDate: <div className="fileName">{item.renewal_date ? new Date(item.renewal_date).toLocaleDateString() : 'N/A'}</div>,
              RoyaltyClaimTimeLeft: <div className="fileName"> {item?.remaining_time_to_claim ? formatRemainingTime(item?.remaining_time_to_claim) : 'N/A'}</div>,
              Action: (
                  <div className="actions">
                      <span>
                          <img src={eyeIcon} alt="View" />
                      </span>
                      <span>
                          <img src={deleteIcon} alt="Delete" />
                      </span>
                  </div>
              )
          }))
        : []; // Return an empty array if referData is not valid

    return (
        <div className="recentFilesTable recentEarning">
            <DataTable
                columns={columns}
                data={data}
                defaultSortFieldId={2}
                selectableRows={false}
                selectableRowsNoSelectAll={false}
                selectableRowsVisibleOnly={false}
                selectableRowsHighlight={false}
                selectableRowsSingle={false}
                expandableRows={false}
                expandOnRowClicked={false}
                expandOnRowDoubleClicked={false}
                expandableRowsHideExpander={false}
                pagination={true}
                highlightOnHover={true}
                striped={false}
                pointerOnHover={false}
                dense={false}
                persistTableHead={true}
                noHeader={false}
                fixedHeader={false}
                fixedHeaderScrollHeight={'300px'}
                progressPending={false}
                noTableHead={false}
                noContextMenu={false}
                direction={Direction.AUTO}
                subHeader={false}
                subHeaderAlign={Alignment.RIGHT}
                subHeaderWrap={true}
                responsive={true}
                disabled={false}
            />
        </div>
    );
};

export default RecentlyReferred;
