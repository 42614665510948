import React, { useEffect, useMemo, useState } from 'react';
import { Container, Form, InputGroup, Card, Spinner, Row, Col } from 'react-bootstrap';
import { getFilesList, getProjectsList, searchPostsList } from '../../axiosCalls/userdashboard';
import OffCanvasBootstrap from '../../off_canvas/OffCanvasBootstrap';
import HomeEssentialsSideNavView from '../components/inc/homeEssentialsSideNavView';
import ReminderSideNavView from '../components/inc/reminderSideNavView';
import PurchasesServiceSideView from '../components/inc/purchasesServiceSideView';
import ProjectsSideNav from '../components/inc/projectsSideNav';
import FilesSideNavView from '../components/inc/filesSideNavView';
import { useDispatch, useSelector } from 'react-redux';
import types from '../../redux/actions/actionTypes';
import { getProperty } from '../utils/helper';

const SearchComponent = () => {
    const dispatch = useDispatch();
    const isGetSearchResults = useSelector((state) => state.root.isGetSearchResults);
    const sideNavEnum = {
        event_list: 'event_list',
        homeEssential: 'home_essentials',
        purchases: 'purchases',
        services: 'services',
        project: 'project',
        file: 'file'
    };

    const property = getProperty();

    const [searchQuery, setSearchQuery] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState(null);
    const [viewType, setViewType] = useState('');
    const [projectsList, setProjectsList] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [showOffCanvas, setShowOffCanvas] = useState(false);

    useEffect(() => {
        fetchProjectsList();
        fetchFileList();
    }, []);

    const fetchProjectsList = async () => {
        try {
            const res = await getProjectsList();
            if (res && res.data) setProjectsList(res.data);
        } catch (error) {
            console.error('Error fetching project list:', error);
        }
    };

    const fetchFileList = async () => {
        try {
            const res = await getFilesList();
            if (res && res.data) setFileList(res.data);
        } catch (error) {
            console.error('Error fetching file list:', error);
        }
    };

    const handleSearch = async (query) => {
        if (query.trim() === '') {
            setResults([]);
            return;
        }

        setLoading(true);
        try {
            const response = await searchPostsList(query);
            setResults(response.data || []);
        } catch (error) {
            console.error('Error fetching search results:', error);
            setResults([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isGetSearchResults) {
            handleSearch(searchQuery);
            dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetSearchResults', data: false } });
        }
    }, [isGetSearchResults]);

    const handleInputChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        handleSearch(query);
    };

    const groupedResults = results.reduce((acc, item) => {
        const type = item.post_type;
        if (!acc[type]) acc[type] = [];
        acc[type].push(item);
        return acc;
    }, {});

    const handleItemClick = (item, type) => {
        setSelectedItems(item);
        setViewType(type);
        setShowOffCanvas(true);
    };

    const closeOffCanvas = () => {
        setShowOffCanvas(false);
        setSelectedItems(null);
        setViewType('');
    };

    const searchPlaceholder = useMemo(() => {
        return (property?.title && `Search ${property?.title}`) || 'Search for projects, files, and more...';
    }, [property]);

    return (
        <Container className="py-4">
            <Form>
                <InputGroup className="mb-4" style={{ height: '45px', maxWidth: '500px', margin: '0 auto' }}>
                    <InputGroup.Text>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path
                                d="M17.9419 17.0575L14.0302 13.1466C15.1639 11.7854 15.7293 10.0395 15.6086 8.27214C15.488 6.50475 14.6906 4.85192 13.3823 3.65748C12.074 2.46304 10.3557 1.81895 8.58462 1.8592C6.81357 1.89945 5.12622 2.62094 3.87358 3.87358C2.62094 5.12622 1.89945 6.81357 1.8592 8.58462C1.81895 10.3557 2.46304 12.074 3.65748 13.3823C4.85192 14.6906 6.50475 15.488 8.27214 15.6086C10.0395 15.7293 11.7854 15.1639 13.1466 14.0302L17.0575 17.9419C17.1156 17.9999 17.1845 18.046 17.2604 18.0774C17.3363 18.1089 17.4176 18.125 17.4997 18.125C17.5818 18.125 17.6631 18.1089 17.739 18.0774C17.8149 18.046 17.8838 17.9999 17.9419 17.9419C17.9999 17.8838 18.046 17.8149 18.0774 17.739C18.1089 17.6631 18.125 17.5818 18.125 17.4997C18.125 17.4176 18.1089 17.3363 18.0774 17.2604C18.046 17.1845 17.9999 17.1156 17.9419 17.0575ZM3.12469 8.74969C3.12469 7.63717 3.45459 6.54963 4.07267 5.62461C4.69076 4.69958 5.56926 3.97861 6.5971 3.55287C7.62493 3.12712 8.75593 3.01573 9.84707 3.23277C10.9382 3.44981 11.9405 3.98554 12.7272 4.77221C13.5138 5.55888 14.0496 6.56116 14.2666 7.65231C14.4837 8.74345 14.3723 9.87445 13.9465 10.9023C13.5208 11.9301 12.7998 12.8086 11.8748 13.4267C10.9497 14.0448 9.86221 14.3747 8.74969 14.3747C7.25836 14.373 5.82858 13.7799 4.77404 12.7253C3.71951 11.6708 3.12634 10.241 3.12469 8.74969Z"
                                fill="#535860"
                            />
                        </svg>
                    </InputGroup.Text>
                    <Form.Control type="text" placeholder={searchPlaceholder} value={searchQuery} onChange={handleInputChange} />
                </InputGroup>
            </Form>

            {loading && (
                <div className="text-center">
                    <Spinner animation="border" />
                </div>
            )}

            {!loading && results.length === 0 && searchQuery && <div className="text-center text-muted">No results found</div>}

            {!loading && results.length > 0 && (
                <Row>
                    {Object.entries(groupedResults).map(([key, items]) => (
                        <Col key={key} xs={12} md={12} lg={12} className="mb-4">
                            <h6 className="text-uppercase text-muted mb-3">
                                {key.replace(/_/g, ' ')} ({items.length})
                            </h6>
                            <div className="p-2" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                {items.map((item) => (
                                    <Card key={item.ID} className="mb-3 shadow-sm" onClick={() => handleItemClick(item, key)}>
                                        <Card.Body>
                                            <Card.Title>{item.title}</Card.Title>
                                            <Card.Text className="text-muted">{item.description}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                ))}
                            </div>
                        </Col>
                    ))}
                </Row>
            )}

            <OffCanvasBootstrap
                placement="end"
                show={showOffCanvas}
                handleClose={() => {
                    closeOffCanvas();
                    setViewType('');
                    setSelectedItems(null);
                }}
            >
                {viewType === sideNavEnum.homeEssential && (
                    <HomeEssentialsSideNavView
                        handleClose={() => {
                            closeOffCanvas();
                            setViewType('');
                            setSelectedItems(null);
                        }}
                        propsValue={selectedItems.ID}
                        fetchHomeEssentials={() => {}} // Add the function to fetch home essentials
                    />
                )}
                {viewType === sideNavEnum.event_list && (
                    <ReminderSideNavView
                        handleClose={() => {
                            closeOffCanvas();
                            setViewType('');
                            setSelectedItems(null);
                        }}
                        eventID={selectedItems?.ID}
                    />
                )}
                {viewType === sideNavEnum.services && (
                    <PurchasesServiceSideView
                        handleClose={() => {
                            closeOffCanvas();
                            setViewType('');
                            setSelectedItems(null);
                        }}
                        itemType={viewType === 'purchases' ? 'purchase' : 'service'}
                        selectedId={selectedItems?.ID}
                    />
                )}
                {viewType === sideNavEnum.purchases && (
                    <PurchasesServiceSideView
                        handleClose={() => {
                            closeOffCanvas();
                            setViewType('');
                            setSelectedItems(null);
                        }}
                        itemType={viewType === 'purchases' ? 'purchase' : 'service'}
                        selectedId={selectedItems?.ID}
                    />
                )}
                {viewType === sideNavEnum.project && (
                    <ProjectsSideNav
                        handleClose={() => {
                            closeOffCanvas();
                            setViewType('');
                            setSelectedItems(null);
                        }}
                        propsValue={selectedItems}
                        handleGetProjectList={() => {
                            fetchProjectsList();
                        }}
                    />
                )}
                {viewType === sideNavEnum.file && (
                    <FilesSideNavView
                        selectValue={selectedItems}
                        handleClose={() => {
                            closeOffCanvas();
                            setViewType('');
                            setSelectedItems(null);
                        }}
                    />
                )}
            </OffCanvasBootstrap>
        </Container>
    );
};

export default SearchComponent;
