import React from 'react';
import swtichIcon from '../assets/icons/refresh-cw.svg';
import { Link } from 'react-router-dom';

const AffiliateTaxRequirements = () => {
    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div className="">
                        <div className="">
                            <div className="row">
                                <div className="col-lg-5 col-md-12 col-sm-12">
                                    <h5 className="mb-2 text-titlecase mb-4">Payments</h5>
                                </div>
                            </div>
                        </div>
                        <div className="seller-payment-page mb-3">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="seller-payment-inner">
                                        <div className="seller-policy">
                                            <label>Affiliates of Remind are considered 1099 contractors.</label>
                                            <p>
                                                For the Affiliate and Homeowner + Affiliate roles at registration, we need to:
                                                <ol>
                                                    <li>Add a checkbox for Affiliates to agree to the Referral Fee Agreement.</li>
                                                    <li>Hyperlink the Agreement for them to review (similar to terms and conditions).</li>
                                                </ol>
                                                If a homeowner already in the system requests to become an Affiliate, they must accept the Referral Fee Agreement before gaining access to the Affiliate Dashboard.
                                            </p>
                                            <p>
                                                Businesses in the United States are required to issue a 1099 form to a taxpayer who has received at least $600 or more in non-employment income during the tax year. This means we must collect the first name, last name, address, and
                                                SSN or Federal ID Number from any Remind affiliate who exceeds that threshold.
                                            </p>
                                            <p>My suggestion is to create an email or alert in the system when an Affiliate exceeds $600 during the tax year. The email or alert will request the affiliate to fill out a W-9 form.</p>
                                            <label>Based on the W-9 form, our system must do two things:</label>
                                            <ol>
                                                <li>
                                                    Create a 1099 form for each Affiliate at the end of the tax year, and automatically email them the form ahead of tax time (i.e. February).
                                                    <ul>
                                                        <li>Messaging in the email may include instructions on how to report on their tax returns, or we can simply state, "Please report income on tax return. If you need further assistance, contact your tax professional."</li>
                                                        <li>Ryan to forward Ropstam W-9 form, 1099 form, and 1099 email example (Acorns).</li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    Batch all Affiliate 1099s for the year into a zip file to e-file them through the{' '}
                                                    <Link className="irisTaxpayer" to="https://www.irs.gov/tax-professionals/iris-application-for-tcc" target="_blank">
                                                        IRIS Taxpayer Portal TCC
                                                    </Link>{' '}
                                                    (Ryan to apply for an IRIS TCC).
                                                </li>
                                            </ol>
                                            <p>If affiliate income is below $600 for the tax year, the outlined process is not required or activated.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AffiliateTaxRequirements;
