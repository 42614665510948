import React, { useState } from 'react';
import SellerSiderbar from '../sidebar/sellerSiderbar';
import '../assets/sellerDashboard.css';
import SellerHeader from '../components/header/sellerHeader';
import Footer from '../components/footer/footer';
import { Link, Navigate } from 'react-router-dom';
import swtichIcon from '../assets/icons/refresh-cw.svg';
// import { ReactComponent as ContactIcon } from '../../asstes/dash-icons/contact-solid-new.svg';
import { ReactComponent as ContactIcon } from '../../user-dashboard/asstes/dash-icons/contact-solid-new.svg';
import ContactModal from '../../user-dashboard/components/modals/ContactModal';

const SellerDashboard = (props) => {
    const { childComp } = props;

    const user = localStorage.getItem('user');
    const userData = user ? JSON.parse(user) : null;
    const userRole = userData?.role || null;

    const [openSideBar, setOpenSideBar] = useState('close');
    const [showModal, setShowModal] = useState(false);

    const handlerSideBar = (val) => {
        setOpenSideBar(val);
    };
    const handlercolseSidebar = () => {
        setOpenSideBar('close');
        document.body.classList.remove('no-scroll');
    };
    const handlerCloseSidebarFromClick = (val) => {
        setOpenSideBar(val);
    };
    const handleShow = () => setShowModal(true); // Open modal
    const handleClose = () => setShowModal(false); // Close modal
    const token = localStorage.getItem('token');
    if (!token) {
        // Not authenticated
        return <Navigate to="/login" />;
    }
    return (
        <div className="container-scroller seller">
            <SellerHeader stateSide={openSideBar} handlerSideBar={handlerSideBar} />
            <div className="page-body-wrapper">
                <SellerSiderbar stateSide={openSideBar} handlerCloseSidebarFromClick={handlerCloseSidebarFromClick} />
                <div className="main-panel">
                    <div className="reminder-top-button homeOwnerRole">
                        {userRole !== 'affiliate' && (
                            <Link to="/dashboard" className="seller-switch-role">
                                <img src={swtichIcon} alt="switch-icon" /> Switch to <b>Homeowner Role</b>
                            </Link>
                        )}

                        <div className="contact_us" onClick={handleShow}>
                            <ContactIcon />
                        </div>
                    </div>
                    {childComp}
                </div>
            </div>
            <ContactModal showModal={showModal} handleClose={handleClose} />
            <Footer />
        </div>
    );
};

export default SellerDashboard;
