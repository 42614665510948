import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Form, Row } from 'react-bootstrap';
import { deleteNotificationsById, get_event_by_id, updateNotificationsStatusById } from '../../../axiosCalls/userdashboard';
import moment from 'moment';
import Loader from '../loader/Loader';
import OffCanvasBootstrap from '../../../off_canvas/OffCanvasBootstrap';
import ReminderSideNav from './reminderSideNav';
import { confirmDelete, formatDate } from '../../utils/helper';
import types from '../../../redux/actions/actionTypes';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const ReminderSideNavView = ({ eventID, handleClose }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedReminder, setSelectedReminder] = useState(null);
    const [selectedHomeEssentials, setSelectedHomeEssentials] = useState([]);
    const [showReminderEdit, setShowReminderEdit] = useState(false);
    const [reminderId, setReminderId] = useState(null);
    useEffect(() => {
        if (eventID) setReminderId(eventID);
    }, [eventID]);

    // Fetch reminder data when eventID changes
    const fetchReminder = async () => {
        if (reminderId) {
            setIsLoading(true);
            try {
                const res = await get_event_by_id(reminderId); // API call to fetch the event details
                const reminderData = res.data;
                setSelectedReminder(reminderData); // Set fetched reminder data to state
                if (reminderData.linked_home_essentials) {
                    setSelectedHomeEssentials(reminderData.linked_home_essentials); // Parse and set linked home essentials
                }
            } catch (error) {
                console.error('Error fetching reminder by ID:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };
    useEffect(() => {
        if (reminderId) fetchReminder();
    }, [reminderId]); // Re-fetch when eventID changes

    if (!selectedReminder) {
        return <Loader />; // Show a loading state while fetching
    }

    const { title, description, frequency, time, date, ID } = selectedReminder;
    const DynamicHtmlRenderer = ({ title }) => {
        const dynamicHtml = `<h3>${title}</h3>`; // Dynamic HTML content

        return <div dangerouslySetInnerHTML={{ __html: dynamicHtml }} />;
    };

    const status_task = selectedReminder?.status_task || '';

    const badgeColors = {
        completed: 'success',
        snooze: 'info',
        dismissed: 'danger'
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <div className="side-nav">
                <div className="side-header d-flex flex-wrap align-items-center gap-2">
                    <DynamicHtmlRenderer title={title} />
                    {status_task && status_task !== 'single-delete' ? <Badge bg={badgeColors[status_task]}>{status_task}</Badge> : <Badge>in-progress</Badge>}
                </div>
                <div className="side-body homeEssentialSideNav">
                    <Form>
                        <Row>
                            <Form.Group md="12" className="mb-3">
                                <div className="link-table-main">
                                    <Form.Label>Description</Form.Label>
                                    <div className="warranty-section">
                                        {/* <span>
                                            <b>Description</b>
                                        </span> */}
                                        <span>
                                            <p>
                                                <div dangerouslySetInnerHTML={{ __html: description }} />
                                            </p>
                                        </span>{' '}
                                        {/* Dynamically render description */}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} md="4" className="mb-3">
                                <div className="link-table-main">
                                    <Form.Label>Repeats</Form.Label>
                                    <p className="text-paragraph">{frequency || 'N/A'}</p> {/* Render repeat frequency */}
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} md="4" className="mb-3">
                                <div className="link-table-main">
                                    <Form.Label>Date</Form.Label>
                                    <p className="text-paragraph">{date ? formatDate(date) : 'N/A'}</p> {/* Render reminder date */}
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} md="4" className="mb-3">
                                <div className="link-table-main">
                                    <Form.Label>Time</Form.Label>
                                    <p className="text-paragraph">{moment(time, 'HH:mm:ss').format('hh:mm A')}</p> {/* Render reminder time */}
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <div className="link-table-main">
                                    <Form.Label>Linked Home Essentials</Form.Label>
                                    <div className="listOfImages">
                                        {selectedHomeEssentials.map((essential, index) => (
                                            <span key={index} className="services-badge type-blue">
                                                {essential.label} {/* Render linked home essentials */}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <div className="link-table-main">
                                    <Form.Label>
                                        Relate to: <Badge>{selectedReminder?.relate_to?.type || 'N/A'}</Badge>
                                    </Form.Label>
                                    <div className="listOfImages">{selectedReminder?.relate_to?.title || 'N/A'}</div>
                                </div>
                            </Form.Group>
                        </Row>
                    </Form>
                </div>
            </div>
            <div className="side-footer form-actions d-flex justify-content-between gap-2">
                <Button
                    className="button-cancel"
                    onClick={() => {
                        handleClose();
                    }}
                >
                    Cancel
                </Button>
                <div className="d-flex gap-2">
                    <Button
                        className="delete-btn"
                        onClick={() => {
                            confirmDelete(async (result) => {
                                if (result.isConfirmed) {
                                    const resp = await deleteNotificationsById(reminderId);
                                    if (resp.status === 200) {
                                        // toast
                                        setReminderId(null);
                                        toast.success('Reminder deleted successfully');
                                        dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetAllReminderEvents', data: true } });
                                        handleClose();
                                    }

                                    // if purchase then call delete purchase api other services
                                }
                            });
                        }}
                    >
                        Delete Series
                    </Button>
                    {frequency !== 'Once' && (
                        <Button
                            onClick={async () => {
                                try {
                                    confirmDelete(async (result) => {
                                        if (result.isConfirmed) {
                                            const resp = await updateNotificationsStatusById(reminderId, {
                                                status_task: 'single-delete'
                                            });
                                            if (resp.status === 200) {
                                                toast.success(resp.data.message);
                                                handleClose();
                                                setReminderId(null);
                                                dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetAllReminderEvents', data: true } });
                                                dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetSearchResults', data: true } });
                                            } else {
                                                toast.error('Something went wrong');
                                            }
                                        }
                                    });
                                } catch (error) {
                                    toast.error('Something went wrong');
                                }
                            }}
                            className="delete-btn"
                        >
                            Delete
                        </Button>
                    )}

                    <Button
                        onClick={() => {
                            setShowReminderEdit(true);
                        }}
                        className="button-primary"
                        type="button"
                    >
                        Edit
                    </Button>
                </div>
            </div>
            <OffCanvasBootstrap
                placement="end"
                name="end"
                closeButton
                show={showReminderEdit}
                handleClose={() => {
                    setShowReminderEdit(false);
                }}
            >
                <ReminderSideNav reminder={selectedReminder} />
            </OffCanvasBootstrap>
        </>
    );
};

export default ReminderSideNavView;
