import React, { useMemo } from 'react';
import { formatDate } from '../../utils/helper';
import BroomIcon from '../../asstes/dash-icons/sidebar/Broom.svg';
import DataTable from 'react-data-table-component';

const Services = ({ services, all, pagination }) => {
    const allServices = useMemo(() => services?.map((p) => p?.meta), [services]);
    const totalAmount = allServices?.reduce((total, purchase) => {
        // Convert the amount to a number
        const amount = parseFloat(purchase.amount);

        // Check if it's a valid number
        if (!isNaN(amount)) {
            total += amount;
        }

        return total;
    }, 0);

    const columns = [
        {
            name: 'Type',
            selector: (row) => row.type,
            sortable: true,
            reorder: true
        },
        {
            name: 'Name',
            selector: (row) => <div dangerouslySetInnerHTML={{ __html: row.name }} />,
            sortable: true,
            reorder: true
        },
        // {
        //     name: 'Make',
        //     selector: (row) => row.make,
        //     sortable: true,
        //     reorder: true
        // },
        // {
        //     name: 'Model #',
        //     selector: (row) => row.model,
        //     sortable: true,
        //     reorder: true
        // },
        {
            name: 'Project',
            selector: (row) => <div dangerouslySetInnerHTML={{ __html: row.project_name }} />,
            sortable: true,
            reorder: true
        },
        {
            name: 'Date',
            selector: (row) => row.date,
            sortable: true,
            reorder: true
        },
        {
            name: 'Cost',
            selector: (row) => row.cost,
            sortable: true,
            reorder: true
        }
    ];

    function formatString(input) {
        if (!input) return '';
        // Replace hyphen with underscore
        const updatedString = input.replace(/-/g, ' ');

        // Capitalize the first letter
        return updatedString.charAt(0).toUpperCase() + updatedString.slice(1);
    }

    const filteredServiceData = allServices?.map((item) => ({
        id: item.id,
        itemType: 'service',
        type: (
            <span className="services-badge type-camel">
                <img src={BroomIcon} alt="Service Icon" /> Service
            </span>
        ),
        name: item.vendor,
        make: item.make || '',
        model: '', // No model number for services
        project: item.project,
        project_name: formatString(item.project_name),
        date: formatDate(item.service_date),
        cost: `$${item.amount}`
    }));

    return (
        <>
            {!all && (
                <div className=" col-md-12 mb-3" id="expense-total">
                    <label className="reportSectiontitile">Expense Totals</label>
                    <div className="expenseTable">
                        <div className="expensItem">
                            <div className="expensTitle">Services</div>
                            <div className="expensAmount">${totalAmount?.toFixed(2)}</div>
                        </div>
                    </div>
                </div>
            )}

            <div className="col-md-12 mb-3" id="project-cost">
                <label className="reportSectiontitile">Services</label>
                <DataTable
                    columns={columns}
                    data={filteredServiceData} // Use the mapped data here
                    selectableRows={false}
                    pagination={pagination}
                    highlightOnHover={true}
                />
            </div>
        </>
    );
};

export default Services;
