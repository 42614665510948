import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
const data = store();
const root = ReactDOM.createRoot(document.getElementById('root'));

// Register the FCM service worker
serviceWorkerRegistration.registerFCM();

root.render(
    // <React.StrictMode>
    <Router>
        <Provider store={data}>
            <App />
        </Provider>
    </Router>
    // </React.StrictMode>
);

if (process.env.NODE_ENV === 'production') {
    serviceWorkerRegistration.register();
} else {
    serviceWorkerRegistration.unregister();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
