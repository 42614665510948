import React, { useEffect, useState } from 'react';
import styles from './style.module.css';
import { ReactComponent as MenuPlusIcon } from '../user-dashboard/asstes/dash-icons/menuPlusIcon.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PermissionModal from './modal/permissionModal';
import { extractData, optionList } from './constant';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
export default function QuickAction({ sidebarInfoFunc }) {
    const location = useLocation();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [selectedQuickAction, setSelectedQuickAction] = useState(null);

    const toggleOptions = () => {
        setIsOpen(!isOpen);
    };

    // if user scroll down and isOpen === true then set back to false
    useEffect(() => {
        const handleScroll = () => {
            if (isOpen) {
                setIsOpen(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isOpen]);

    const selectAction = (quickAction) => {
        const url_path = location.pathname;
        switch (quickAction.id) {
            case 'HOME_ESSENTIAL':
                if (url_path !== quickAction.path) {
                    if (url_path !== '/dashboard') {
                        navigate(quickAction.path);
                        return false;
                    }
                }
                sidebarInfoFunc('HOME_ESSENTIAL');
                break;
            case 'REMINDERS':
                if (url_path !== quickAction.path) {
                    if (url_path !== '/dashboard') {
                        navigate(quickAction.path);
                        return false;
                    }
                }
                sidebarInfoFunc('REMINDERS');
                break;
            case 'PROJECTS':
                if (url_path !== quickAction.path) {
                    if (url_path !== '/dashboard') {
                        navigate(quickAction.path);
                        return false;
                    }
                }
                sidebarInfoFunc('PROJECTS');
                break;
            case 'FILES':
                if (url_path !== quickAction.path) {
                    if (url_path !== '/dashboard') {
                        navigate(quickAction.path);
                        return false;
                    }
                }
                sidebarInfoFunc('FILES');
                break;
            default:
                if (url_path !== quickAction.path) {
                    if (url_path !== '/dashboard') {
                        navigate(quickAction.path);
                        return false;
                    }
                }
                setSelectedQuickAction(quickAction);
                setOpenModal(true);
                break;
        }
        setIsOpen(false);
    };
    const fileDataHandler = (detail) => {
        const info = extractSummaryRelevantDetails(detail?.summary_list);
        const lineItems = extractTypeAndPrice(detail?.lineItem);
        sidebarInfoFunc('PURCHASE_AND_SERVICES', { info, lineItems });
    };
    const extractSummaryRelevantDetails = (response) => {
        const extractedDetails = {
            name: '',
            date: '',
            total: ''
        };
        for (const item of response) {
            const typeText = item.Type.Text;
            const valueText = item.ValueDetection.Text;
            // Check for name
            if (
                typeText.toLowerCase().includes('name')
                // typeText.toLowerCase().includes("vendor")
            ) {
                if (!extractedDetails.name) extractedDetails.name = valueText;
            }

            if (typeText.toLowerCase().includes('date')) {
                extractedDetails.date = moment(valueText).format('YYYY-MM-DD');
            }
            if (typeText.toLowerCase().includes('total')) {
                extractedDetails.total = valueText;
            }
        }
        return extractedDetails;
    };
    const extractTypeAndPrice = (data) => {
        return data.map((item) => {
            const typeField = item.LineItemExpenseFields.find((field) => field.Type.Text === 'ITEM');
            const priceField = item.LineItemExpenseFields.find((field) => field.Type.Text === 'PRICE');

            return {
                Type: typeField ? typeField.ValueDetection.Text : null,
                PRICE: priceField ? priceField.ValueDetection.Text : null
            };
        });
    };
    const closeModal = () => setOpenModal(false);
    return (
        <div className={styles['fab-container']}>
            <button className={styles['fab']} onClick={toggleOptions}>
                <MenuPlusIcon className={styles['icon']} />
            </button>
            <div
                style={{
                    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                    background: 'white',
                    zIndex: 1
                }}
                className={`mb-3 p-2 rounded-3 ${styles['fab-options']} ${isOpen ? styles['show'] : ''}`}
            >
                {optionList.map((option, Indx) => (
                    <MenuOption
                        onClick={() => selectAction(option)}
                        {...{
                            ...option,
                            isOpen
                        }}
                        isOpen={isOpen}
                        key={Indx}
                    />
                ))}
            </div>
            <PermissionModal selectedQuickAction={selectedQuickAction} shown={openModal} onCloseModal={closeModal} fileDataHandler={fileDataHandler} />
        </div>
    );
}
const MenuOption = ({ img, icon, title, onClick, isOpen }) => {
    return (
        <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={isOpen ? <Tooltip id={title}>{title}</Tooltip> : <></>}>
            {isOpen ? (
                <>
                    <div
                        onClick={onClick}
                        style={{
                            width: '210px',
                            cursor: 'pointer'
                        }}
                        className={`${styles['quick-action-menu-item']} d-flex gap-2 align-items-center p-1 rounded-3`}
                    >
                        <button className={styles['fab-option']}>
                            {icon && <span className="nav-icon" dangerouslySetInnerHTML={{ __html: icon }} />}
                            {img && <img height="28px" width="28px" alt="" src={img} />}
                        </button>
                        <div>
                            <span className={styles['fab-option-title']}>{title}</span>
                        </div>
                    </div>
                </>
            ) : (
                <></>
            )}
        </OverlayTrigger>
    );
};
