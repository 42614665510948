import React, { useEffect, useState } from 'react';
import ReminderSideNavView from './reminderSideNavView';
import reminderNextbtn from '../../asstes/dash-icons/reminderNextbtn.svg';
import reminderPrvebtn from '../../asstes/dash-icons/reminderPrvebtn.svg';
import { get_all_event_lists } from '../../../axiosCalls/userdashboard';
import Loader from '../loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../../redux/actions/actionTypes';
import OffCanvasBootstrap from '../../../off_canvas/OffCanvasBootstrap';
import { add, addMonths, set } from 'date-fns';

const DashboardCalender = () => {
    const isGetAllReminderEvents = useSelector((state) => state.root?.isGetAllReminderEvents);
    const dispatch = useDispatch();
    // Helper function to get the Monday of the current week
    const getMonday = (date) => {
        const day = date.getDay();
        const diff = day === 0 ? -6 : 1 - day; // Adjust if it's Sunday
        return new Date(date.setDate(date.getDate() + diff));
    };

    const [startDate, setStartDate] = useState(getMonday(new Date())); // Start from the Monday of the current week
    const [newEvents, setEvents] = useState([]); // API events data
    const [loading, setLoading] = useState(false); // Loading state
    const [selectedEventId, setselectedEventId] = useState(null);

    const currentDate = new Date(); // Today's date

    const frequencyMappings = {
        once: 1,
        daily: { days: 1 },
        weekly: { days: 7 },
        fortnightly: { days: 14 },
        monthly: 'monthly',
        quarterly: 'quarterly',
        six_months: 'six_months',
        yearly: 'yearly',
        yearly_3: 'yearly_3'
    };

    const colorMappings = {
        yearly_3: 'purple',
        yearly: 'purple',
        six_months: 'purple',
        daily: 'red',
        once: 'red',
        weekly: 'camel',
        fortnightly: 'camel',
        monthly: 'green',
        quarterly: 'green'
    };

    const fetchEvents = async () => {
        try {
            setLoading(true);
            const res = await get_all_event_lists();
            const formattedEvents = res.data?.flatMap((event) => {
                const eventDate = new Date(`${event.date}T${event.time}`);
                const frequency = event.reminder_frequency;
                const occurrences = [];
                const interval = frequencyMappings[frequency] || 0;
                const color = colorMappings[frequency] || 'green';

                if (frequency === 'once') {
                    occurrences.push({
                        id: event.ID,
                        title: event.title,
                        description: event.description,
                        type: color,
                        date: eventDate,
                        frequency: event.reminder_frequency,
                        time: event.time
                    });
                } else if (frequency === 'monthly') {
                    for (let i = 0; i < 14; i++) {
                        const nextOccurrence = addMonths(eventDate, i);
                        occurrences.push({
                            id: event.ID,
                            title: event.title,
                            description: event.description,
                            type: color,
                            frequency: event.reminder_frequency,
                            date: set(nextOccurrence, { date: eventDate.getDate() })
                        });
                    }
                } else if (frequency === 'quarterly') {
                    for (let i = 0; i < 14; i++) {
                        const nextOccurrence = addMonths(eventDate, i * 3);
                        occurrences.push({
                            id: event.ID,
                            title: event.title,
                            description: event.description,
                            type: color,
                            frequency: event.reminder_frequency,
                            date: set(nextOccurrence, { date: eventDate.getDate() })
                        });
                    }
                } else if (frequency === 'six_months') {
                    for (let i = 0; i < 14; i++) {
                        const nextOccurrence = addMonths(eventDate, i * 6);
                        occurrences.push({
                            id: event.ID,
                            title: event.title,
                            description: event.description,
                            type: color,
                            frequency: event.reminder_frequency,
                            date: set(nextOccurrence, { date: eventDate.getDate() })
                        });
                    }
                } else if (frequency === 'yearly') {
                    for (let i = 0; i < 14; i++) {
                        const nextOccurrence = addMonths(eventDate, i * 12);
                        occurrences.push({
                            id: event.ID,
                            title: event.title,
                            description: event.description,
                            type: color,
                            frequency: event.reminder_frequency,
                            date: set(nextOccurrence, { date: eventDate.getDate() })
                        });
                    }
                } else if (frequency === 'yearly_3') {
                    for (let i = 0; i < 14; i++) {
                        const nextOccurrence = addMonths(eventDate, i * 36);
                        occurrences.push({
                            id: event.ID,
                            title: event.title,
                            description: event.description,
                            type: color,
                            frequency: event.reminder_frequency,
                            date: set(nextOccurrence, { date: eventDate.getDate() })
                        });
                    }
                } else {
                    for (let i = 0; i < 14; i++) {
                        const nextOccurrence = add(eventDate, interval);
                        occurrences.push({
                            id: event.ID,
                            title: event.title,
                            description: event.description,
                            type: color,
                            frequency: event.reminder_frequency,
                            date: nextOccurrence
                        });
                    }
                }
                return occurrences;
            });
            setEvents(formattedEvents);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching events:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEvents();
    }, [startDate]); // Refetch events when start date changes

    useEffect(() => {
        if (isGetAllReminderEvents) {
            fetchEvents();
            dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetAllReminderEvents', data: false } });
        }
    }, [isGetAllReminderEvents]);

    const generateTwoWeekDates = (start) => {
        const dates = [];
        for (let i = 0; i < 14; i++) {
            const date = new Date(start);
            date.setDate(start.getDate() + i);
            dates.push(date);
        }
        return dates;
    };

    const twoWeekDates = generateTwoWeekDates(startDate);

    const getEventsForDate = (date) => newEvents?.filter((event) => event.date.toDateString() === date.toDateString());

    const nextWeek = () => {
        setStartDate((prev) => {
            const nextDate = new Date(prev);
            nextDate.setDate(prev.getDate() + 14); // Move 14 days forward
            return nextDate;
        });
    };

    const prevWeek = () => {
        setStartDate((prev) => {
            const prevDate = new Date(prev);
            prevDate.setDate(prev.getDate() - 14); // Move 14 days backward
            return prevDate;
        });
    };

    const [showReminderView, setShowReminderView] = useState(false);

    const handlerReminderSideNave = (event) => {
        setselectedEventId(event.id);
        setShowReminderView(true);
    };

    if (loading) return <Loader />;

    return (
        <div>
            <div className="reminder-navigation">
                <h2 className="card-heading">
                    <span>
                        {startDate.toLocaleString('default', {
                            month: 'short',
                            day: 'numeric'
                        })}{' '}
                        - {new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 13).toLocaleString('default', { month: 'short', day: 'numeric' })}
                    </span>

                    <img onClick={prevWeek} src={reminderPrvebtn} alt="Previous Week" />
                    <img onClick={nextWeek} src={reminderNextbtn} alt="Next Week" />
                </h2>
            </div>

            <div className="parent-upcomingDateRow">
                <div className="row upcomingDateRow">
                    {twoWeekDates?.map((date, index) => (
                        <div className={`dayBox ${date.toDateString() === currentDate.toDateString() ? 'current-day' : ''}`} key={index}>
                            <div className="customDates">
                                <span>
                                    {date.toLocaleString('default', {
                                        month: 'short',
                                        day: '2-digit'
                                    })}
                                </span>
                                <span className="day">{date.toLocaleString('en-us', { weekday: 'short' })}</span>
                            </div>

                            <div className="event-list">
                                {getEventsForDate(date)?.map((event, index) => (
                                    <div onClick={() => handlerReminderSideNave(event)} className="parent-event-title" key={event.id}>
                                        <div dangerouslySetInnerHTML={{ __html: `${event.title} <br /> ${event.date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}` }} className={`event-calender-title break-text type-sub-${event.type}`} />
                                    </div>
                                ))}
                            </div>

                            <div className="event_count">
                                <a href="#">Events: {getEventsForDate(date)?.length}</a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <OffCanvasBootstrap
                placement="end"
                name="end"
                closeButton
                show={showReminderView}
                handleClose={() => {
                    setShowReminderView(false);
                }}
            >
                <ReminderSideNavView
                    handleClose={() => {
                        setShowReminderView(false);
                    }}
                    eventID={selectedEventId}
                />
            </OffCanvasBootstrap>
        </div>
    );
};

export default DashboardCalender;
