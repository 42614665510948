import React, { useState, useEffect } from 'react';
import DataTable, { Alignment, Direction } from 'react-data-table-component';
import eyeIcon from '../../asstes/dash-icons/eyeIcon.svg';
import deleteIcon from '../../asstes/dash-icons/deleteIcon.svg';
import refetchIcon from '../../asstes/dash-icons/refetchIcon.svg';
import { Modal, Button } from 'react-bootstrap';
import { getHomeEsstentialReminders, getHomeEsstentialRemindersByType, listOfFrequencies } from '../../../axiosCalls/userdashboard';

const LinkReminders = ({ selectedType, type, onRemindersUpdate }) => {
    const [reminders, setReminders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedReminder, setSelectedReminder] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [frequencies, setFrequencies] = useState([]);

    const fetchReminders = async () => {
        setLoading(true);
        try {
            const response = await getHomeEsstentialRemindersByType(selectedType);
            const updatedReminders = response.data.map((reminder) => ({
                ...reminder,
                start_date: reminder.start_date || new Date().toISOString().split('T')[0] // Set current date if start_date is null
            }));
            setReminders(updatedReminders);
            onRemindersUpdate(updatedReminders);
            const listResponseFreq = await listOfFrequencies();
            setFrequencies(listResponseFreq.data);

            setLoading(false);
        } catch (error) {
            console.error('Error fetching reminders:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchReminders();
    }, [selectedType]);

    const handleViewDetails = (reminder) => {
        setSelectedReminder(reminder);
        setShowModal(true);
    };

    const handleDeleteReminder = (reminderId) => {
        const updatedReminders = reminders.filter((reminder) => reminder.id !== reminderId);
        setReminders(updatedReminders);
        onRemindersUpdate(updatedReminders);
    };

    // Handle date change
    const handleDateChange = (id, newDate) => {
        const updatedReminders = reminders.map((reminder) => (reminder.id === id ? { ...reminder, start_date: newDate } : reminder));
        setReminders(updatedReminders);
        onRemindersUpdate(updatedReminders); // Send updated reminders to the parent component
    };
    const handleFrequencyChange = (id, newFrequencyId) => {
        const selectedFrequency = frequencies.find((freq) => freq.id === parseInt(newFrequencyId, 10)); // Find frequency by ID

        const updatedReminders = reminders.map((reminder) =>
            reminder.id === id
                ? {
                      ...reminder,
                      frequency_id: selectedFrequency.id, // Update `frequency_id`
                      frequency: selectedFrequency.name // Update `frequency` (name for display)
                  }
                : reminder
        );

        setReminders(updatedReminders);
        onRemindersUpdate(updatedReminders); // Send updated reminders to parent component
    };
    const columns = [
        {
            name: 'Reminder Title',
            selector: (row) => row.reminderTitle,
            sortable: false,
            width: '200px',
            reorder: true,
            wrap: true,
            style: {
                wordWrap: 'break-word'
            }
        },
        {
            name: 'Frequency',
            selector: (row) => row.frequency,
            sortable: false,
            width: '130px',
            reorder: true
        },
        {
            name: 'Start Date',
            selector: (row) => row.start_date,
            sortable: false,
            width: '140px',
            reorder: true
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            sortable: false,
            reorder: true
        }
    ];

    const data = reminders.map((reminder) => ({
        id: reminder.id,
        reminderTitle: <div className="REminderTitle">{reminder.title}</div>,
        frequency: (
            <div className="Frequency">
                <select
                    className="form-control"
                    value={reminder.frequency_id} // Ensure this is a string or number
                    onChange={(e) => handleFrequencyChange(reminder.id, e.target.value)}
                >
                    {frequencies.map((freq) => (
                        <option key={freq.id} value={freq.id}>
                            {freq.name}
                        </option>
                    ))}
                </select>
            </div>
        ),
        action: (
            <div className="actions">
                <span onClick={() => handleViewDetails(reminder)}>
                    <img src={eyeIcon} alt="View" />
                </span>
                <span onClick={() => handleDeleteReminder(reminder.id)}>
                    <img src={deleteIcon} alt="Delete" />
                </span>
            </div>
        ),
        start_date: (
            <div className="reminder-start-date">
                <input type="date" className="form-control" value={reminder.start_date} onChange={(e) => handleDateChange(reminder.id, e.target.value)} />
            </div>
        )
    }));

    return (
        <>
            <img src={refetchIcon} alt="Refetch" style={{ cursor: 'pointer', float: 'right', marginRight: '20px' }} onClick={fetchReminders} />

            <div className="linkReminderTable">
                <DataTable
                    columns={columns}
                    data={data}
                    // fixedHeaderScrollHeight="300px"
                    progressPending={loading} // Show a loading state while fetching data
                    direction={Direction.AUTO}
                    subHeaderAlign={Alignment.RIGHT}
                    subHeaderWrap={true}
                    responsive={true}
                    customStyles={{
                        cells: {
                            style: {
                                padding: '10px', // Add padding for readability
                                wordBreak: 'break-word' // Break words to prevent overflow
                            }
                        },
                        rows: {
                            style: {
                                minHeight: '72px' // Ensure each row has a minimum height for better readability
                            }
                        }
                    }}
                />
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Reminder Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedReminder && (
                        <>
                            <p>
                                <strong>Title:</strong> {selectedReminder.title}
                            </p>
                            <p>
                                <strong>Description: </strong>
                                <span dangerouslySetInnerHTML={{ __html: selectedReminder.description }} />
                            </p>
                            <p>
                                <strong>Frequency:</strong> {selectedReminder.frequency}
                            </p>
                            <p>
                                <strong>Date:</strong> {selectedReminder.date}
                            </p>
                            <p>
                                <strong>Time:</strong> {selectedReminder.time}
                            </p>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default LinkReminders;
