import React from 'react';
import eyeIcon from '../../user-dashboard/asstes/dash-icons/eyeIcon.svg';
import editIcon from '../assets/icons/edit-icon.svg';
import deleteIcon from '../../user-dashboard/asstes/dash-icons/deleteIcon.svg';
import DataTable, { Alignment, Direction } from 'react-data-table-component';
import { formatDate } from '../../user-dashboard/utils/helper';

const RecentEarning = ({ earningData }) => {
    const user = JSON.parse(localStorage.getItem('user'));

    const columns = [
        {
            name: 'Source',
            selector: (row) => row.source,
            sortable: true,
            reorder: true
        },
        {
            name: 'Client Name',
            selector: (row) => row.title, // Assuming 'title' represents the client name
            sortable: true,
            reorder: true
        },
        {
            name: 'Date',
            selector: (row) => formatDate(row.date) || 'N/A',
            sortable: true,
            reorder: true
        },
        {
            name: 'Amount',
            selector: (row) => `$${parseFloat(row.affiliate_commission).toFixed(2)}`, // Formats as currency
            sortable: true,
            reorder: true
        }
        // {
        //     name: "Status",
        //     selector: (row) => row.status,
        //     sortable: true,
        //     reorder: true,
        // },
        // {
        //     name: "Action",
        //     cell: (row) => (
        //         <div className='actions'>
        //             <span><img src={eyeIcon} alt="View" /></span>
        //             <span><img src={editIcon} alt="Edit" /></span>
        //             <span><img src={deleteIcon} alt="Delete" /></span>
        //         </div>
        //     ),
        //     ignoreRowClick: true,
        //     allowOverflow: true,
        //     button: true,
        // }
    ];

    return (
        <div className="recentFilesTable recentEarning">
            <DataTable columns={columns} data={earningData} defaultSortFieldId={2} selectableRows={false} pagination={true} highlightOnHover={true} striped={false} persistTableHead={true} responsive={true} direction={Direction.AUTO} />
        </div>
    );
};

export default RecentEarning;
