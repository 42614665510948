import React, { useMemo } from 'react';

const ExpensesTotal = ({ purchases, services, projects }) => {
    const allPurchases = useMemo(() => purchases?.map((p) => ({ ...p?.meta, ...p })), [purchases]);
    const totalAmountPurchases = allPurchases?.reduce((total, purchase) => {
        // Convert the amount to a number
        const amount = parseFloat(purchase.amount);

        // Check if it's a valid number
        if (!isNaN(amount)) {
            total += amount;
        }

        return total;
    }, 0);
    const allServices = useMemo(() => services?.map((p) => p?.meta), [services]);
    const totalAmountServices = allServices?.reduce((total, purchase) => {
        // Convert the amount to a number
        const amount = parseFloat(purchase.amount);

        // Check if it's a valid number
        if (!isNaN(amount)) {
            total += amount;
        }

        return total;
    }, 0);
    const allProjects = useMemo(() => projects?.map((p) => ({ ...p?.meta, ...p })), [projects]);

    const totalAmountProjects = allProjects?.reduce((total, purchase) => {
        // Convert the amount to a number
        const amount = parseFloat(purchase.cost);

        // Check if it's a valid number
        if (!isNaN(amount)) {
            total += amount;
        }

        return total;
    }, 0);
    return (
        <div className=" col-md-12 mb-3" id="expense-total">
            <label className="reportSectiontitile">Expense Totals</label>
            <div className="expenseTable">
                {/* <div className="expensItem">
                    <div className="expensTitle">Home Essentials</div>
                    <div className="expensAmount">$4,985</div>
                </div> */}
                <div className="expensItem">
                    <div className="expensTitle">Projects</div>
                    <div className="expensAmount">${totalAmountProjects?.toFixed(2)}</div>
                </div>
                <div className="expensItem">
                    <div className="expensTitle">Purchases</div>
                    <div className="expensAmount">${totalAmountPurchases?.toFixed(2)}</div>
                </div>

                <div className="expensItem">
                    <div className="expensTitle">Services</div>
                    <div className="expensAmount">${totalAmountServices?.toFixed(2)}</div>
                </div>

                <div className="expensItem TotalLast">
                    <div className="expensTitle">Total</div>
                    <div className="expensAmount">${(totalAmountPurchases + totalAmountServices + totalAmountProjects)?.toFixed(2)}</div>
                </div>
            </div>
        </div>
    );
};

export default ExpensesTotal;
