import '../../asstes/styles/purchaseServiceView.css';
import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Button, Card, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';

import pdfIcon from '../../asstes/dash-icons/pdfIcon.svg';
import eyeIcon from '../../asstes/dash-icons/eyeIcon.svg';
import downloadIcon from '../../asstes/dash-icons/downloadIcon.svg';
import BroomIcon from '../../asstes/dash-icons/sidebar/Broom.svg';
import { deletePurchaseItemAPI, deleteServiceItemAPI, getPurchaseItem, getReminderById, getServiceItem } from '../../../axiosCalls/userdashboard';
import LinkedReminders from './linkedReminders';
import Loader from '../loader/Loader';
import DataTable, { Alignment, Direction } from 'react-data-table-component';
import OffCanvasBootstrap from '../../../off_canvas/OffCanvasBootstrap';
import PurchasesServicesNavAdd from './purchasesServicesNavAdd';
import { confirmDelete, formatDate } from '../../utils/helper';
import { toast } from 'react-toastify';
import types from '../../../redux/actions/actionTypes';
import { useDispatch } from 'react-redux';

const PurchasesServiceSideView = ({ selectedId, itemType, handleClose }) => {
    const dispatch = useDispatch();
    const [showEditPurchaseService, setShowEditPurchaseService] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [purchaseData, setPurchaseData] = useState(null); // State for storing the fetched purchase data
    const [serviceData, setServiceData] = useState(null); // State for storing the service data
    const [showFileModal, setShowFileModal] = useState(false);
    const [fileToView, setFileToView] = useState(null);
    const [itemTpe, setItemType] = useState(itemType); // Store the selected ID
    const [reminders, setReminders] = useState([]);

    useEffect(() => {
        setItemType(itemType);
    }, [itemType]);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            if (itemType === 'purchase') {
                const res = await getPurchaseItem(selectedId);
                setPurchaseData(res.data); // Set the purchase data
                setServiceData(null); // Clear service data
            } else if (itemType === 'service') {
                const resservice = await getServiceItem(selectedId);
                setServiceData(resservice.data); // Set the service data
                setPurchaseData(null); // Clear purchase data
            }
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, [selectedId, itemType]);

    // Function to handle file viewing
    const handleViewFile = (fileUrl) => {
        setFileToView(fileUrl);
        setShowFileModal(true);
    };

    // Function to handle file download
    const handleDownloadFile = (fileUrl) => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', fileUrl.split('/').pop()); // Set the download attribute with the file name
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link); // Remove the link after download
    };

    const columns = [
        {
            name: 'Product Name',
            selector: (row) => row.name,
            sortable: false,
            reorder: true
        },
        {
            name: 'Make',
            selector: (row) => row.make || 'N/A',
            sortable: false,
            reorder: true
        },
        {
            name: 'Model Number',
            selector: (row) => row.modelNumber || 'N/A',
            sortable: false,
            reorder: true
        },
        {
            name: 'Price',
            selector: (row) => `$${row.price}`,
            sortable: false,
            reorder: true
        }
    ];

    const [lineItems, setLineItems] = useState([]);

    const getLineItems = () => {
        const parseLineItems = (lineItems) => {
            try {
                return lineItems && lineItems !== 'undefined' && lineItems.trim().length > 0 ? JSON.parse(lineItems) : [];
            } catch (error) {
                return [];
            }
        };

        if (itemTpe === 'purchase' && purchaseData?.line_items) {
            return parseLineItems(purchaseData.line_items).map((item, index) => ({
                id: index + 1,
                name: item.name,
                make: item.make,
                modelNumber: item.modelNumber,
                price: item.price
            }));
        }

        if (itemTpe === 'service' && serviceData?.line_items) {
            return parseLineItems(serviceData.line_items).map((item, index) => ({
                id: index + 1,
                name: item.name,
                make: item.make,
                modelNumber: item.modelNumber,
                price: item.price
            }));
        }

        return [];
    };

    useEffect(() => {
        setLineItems(getLineItems());
    }, [purchaseData, serviceData, itemTpe]);

    useEffect(() => {
        if (itemTpe === 'service') {
            setReminders(serviceData?.reminders || []);
        }
        if (itemTpe === 'purchase') {
            setReminders(purchaseData?.reminders || []);
        }
    }, [serviceData, purchaseData]);

    function formatString(input) {
        // Replace hyphen with underscore
        const updatedString = input.replace(/-/g, ' ');

        // Capitalize the first letter
        return updatedString.charAt(0).toUpperCase() + updatedString.slice(1);
    }

    // Rendering logic based on itemType (purchase or service)

    const warranty = serviceData?.warranty || purchaseData?.warranty;
    const project_name = serviceData?.project_name || purchaseData?.project_name;
    return (
        <div className="side-nav">
            <div className="side-header">
                <h3>{itemType === 'purchase' ? 'Purchase Details' : 'Service Details'}</h3>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <div className="side-body homeEssentialSideNav">
                    <Form>
                        <Row style={{ padding: '0px' }}>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <div className="link-table-main">
                                    <div className="d-flex purchase-view-name">
                                        <Form.Label>{itemType === 'purchase' ? 'Product Name' : 'Service Name'}</Form.Label>
                                        <span className={itemType === 'purchase' ? 'services-badge type-green' : 'services-badge type-camel'}>
                                            <img src={BroomIcon} alt="icon" />
                                            {itemType === 'purchase' ? 'Purchase' : 'Service'}
                                        </span>
                                    </div>
                                    <p className="text-paragraph">{itemType === 'purchase' ? purchaseData?.product_name : serviceData?.vendor}</p>
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <div className="link-table-main">
                                    <Form.Label>Notes</Form.Label>
                                    <p className="text-paragraph">{itemType === 'purchase' ? purchaseData?.notes || 'N/A' : serviceData?.notes || 'N/A'}</p>
                                </div>
                            </Form.Group>

                            {/* {itemType === 'purchase' && (
                                <>
                                    <Form.Group as={Col} md="4" className="mb-3">
                                        <div className="link-table-main">
                                            <Form.Label>Make</Form.Label>
                                            <p className="text-paragraph">{purchaseData?.make}</p>
                                        </div>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" className="mb-3">
                                        <div className="link-table-main">
                                            <Form.Label>Model Number</Form.Label>
                                            <p className="text-paragraph">{purchaseData?.model_number}</p>
                                        </div>
                                    </Form.Group>
                                </>
                            )} */}

                            <Form.Group as={Col} md="4" className="mb-3">
                                <div className="link-table-main">
                                    <Form.Label>Date</Form.Label>
                                    <p className="text-paragraph">{itemType === 'purchase' ? formatDate(purchaseData?.purchase_date) : formatDate(serviceData?.service_date)}</p>
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} md="4" className="mb-3">
                                <div className="link-table-main">
                                    <Form.Label>Amount</Form.Label>
                                    <p className="text-paragraph">${itemType === 'purchase' ? purchaseData?.amount : serviceData?.amount}</p>
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <div className="link-table-main">
                                    <Form.Label>Project Name</Form.Label>
                                    <p className="text-paragraph">{project_name ? formatString(project_name) : 'N/A'}</p>
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <div className="link-table-main">
                                    <Form.Label>Items</Form.Label>

                                    <DataTable
                                        columns={columns}
                                        data={lineItems || []}
                                        defaultSortFieldId={0}
                                        // onRowClicked={handleRowSideNavOpen} // Attach click event handler
                                        pagination={false}
                                        highlightOnHover={true}
                                        pointerOnHover={true}
                                        persistTableHead={true}
                                        // fixedHeaderScrollHeight={"300px"}
                                        // progressPending={loading} // Show loader while data is being fetched
                                        noTableHead={false}
                                        noContextMenu={false}
                                        direction={Direction.AUTO}
                                        subHeader={false}
                                        subHeaderAlign={Alignment.LEFT}
                                        subHeaderWrap={true}
                                        responsive={true}
                                    />
                                </div>
                            </Form.Group>

                            {/* <Container className="my-4" style={{ maxWidth: '100%' }}>
                                <Card className="shadow-sm">
                                    <Card.Header className="bg- text-center">
                                        <h5>Reminders</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                                            <Table bordered hover className="text-center align-middle" style={{ minWidth: '600px' }}>
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Title</th>
                                                        <th>Description</th>
                                                        <th>Frequency</th>
                                                        <th>Date</th>
                                                        <th>Time</th>
                                                    </tr>
                                                </thead>
                                                {reminders?.length > 0 ? (
                                                    <tbody>
                                                        {reminders.map((reminder) => (
                                                            <tr key={reminder.id}>
                                                                <td>{reminder.id}</td>
                                                                <td>{reminder.title}</td>
                                                                <td>{reminder.description}</td>
                                                                <td>
                                                                    <Badge bg="info" className="text-uppercase">
                                                                        {reminder.frequency}
                                                                    </Badge>
                                                                </td>
                                                                <td>{formatDate(reminder.date)}</td>
                                                                <td>{reminder.time || 'Not Specified'}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                ) : (
                                                    <div className="my-3 text-center">No Reminder Found</div>
                                                )}
                                            </Table>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Container> */}

                            {serviceData?.receipt_url?.length > 0 && (
                                <Form.Group md="12" className="mb-3">
                                    <div className="link-table-main">
                                        <Form.Label>Receipt Files</Form.Label>
                                        <div className="listofFiles">
                                            {serviceData.receipt_url.map((file, index) => (
                                                <div className="warrantyFile" key={index}>
                                                    <div className="text">
                                                        <img src={pdfIcon} alt="pdfIcon" />
                                                        {file.split('/').pop()}
                                                    </div>
                                                    <div className="actions">
                                                        <span
                                                            onClick={() => handleViewFile(file)}
                                                            style={{
                                                                cursor: 'pointer'
                                                            }}
                                                        >
                                                            <img src={eyeIcon} alt="View" />
                                                        </span>
                                                        <span
                                                            onClick={() => handleDownloadFile(file)}
                                                            style={{
                                                                cursor: 'pointer'
                                                            }}
                                                        >
                                                            <img src={downloadIcon} alt="Download" />
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Form.Group>
                            )}
                            {serviceData?.uploaded_files?.length > 0 && (
                                <Form.Group md="12" className="mb-3">
                                    <div className="link-table-main">
                                        <Form.Label>Files</Form.Label>
                                        <div className="listofFiles">
                                            {serviceData.uploaded_files.map((file, index) => (
                                                <div className="warrantyFile" key={index}>
                                                    <div className="text">
                                                        <img src={pdfIcon} alt="pdfIcon" />
                                                        {file.split('/').pop()}
                                                    </div>
                                                    <div className="actions">
                                                        <span
                                                            onClick={() => handleViewFile(file)}
                                                            style={{
                                                                cursor: 'pointer'
                                                            }}
                                                        >
                                                            <img src={eyeIcon} alt="View" />
                                                        </span>
                                                        <span
                                                            onClick={() => handleDownloadFile(file)}
                                                            style={{
                                                                cursor: 'pointer'
                                                            }}
                                                        >
                                                            <img src={downloadIcon} alt="Download" />
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Form.Group>
                            )}

                            {purchaseData?.receipt_url?.length > 0 && (
                                <Form.Group md="12" className="mb-3">
                                    <div className="link-table-main">
                                        <Form.Label>Receipt Files</Form.Label>
                                        <div className="listofFiles">
                                            {purchaseData.receipt_url.map((file, index) => (
                                                <div className="warrantyFile" key={index}>
                                                    <div className="text">
                                                        <img src={pdfIcon} alt="pdfIcon" />
                                                        {file.split('/').pop()}
                                                    </div>
                                                    <div className="actions">
                                                        <span
                                                            onClick={() => handleViewFile(file)}
                                                            style={{
                                                                cursor: 'pointer'
                                                            }}
                                                        >
                                                            <img src={eyeIcon} alt="View" />
                                                        </span>
                                                        <span
                                                            onClick={() => handleDownloadFile(file)}
                                                            style={{
                                                                cursor: 'pointer'
                                                            }}
                                                        >
                                                            <img src={downloadIcon} alt="Download" />
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Form.Group>
                            )}
                            {purchaseData?.uploaded_files?.length > 0 && (
                                <Form.Group md="12" className="mb-3">
                                    <div className="link-table-main">
                                        <Form.Label>Files</Form.Label>
                                        <div className="listofFiles">
                                            {purchaseData.uploaded_files.map((file, index) => (
                                                <div className="warrantyFile" key={index}>
                                                    <div className="text">
                                                        <img src={pdfIcon} alt="pdfIcon" />
                                                        {file.split('/').pop()}
                                                    </div>
                                                    <div className="actions">
                                                        <span
                                                            onClick={() => handleViewFile(file)}
                                                            style={{
                                                                cursor: 'pointer'
                                                            }}
                                                        >
                                                            <img src={eyeIcon} alt="View" />
                                                        </span>
                                                        <span
                                                            onClick={() => handleDownloadFile(file)}
                                                            style={{
                                                                cursor: 'pointer'
                                                            }}
                                                        >
                                                            <img src={downloadIcon} alt="Download" />
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Form.Group>
                            )}
                            {warranty ? (
                                <Form.Group as={Col} md="12" className="mb-3">
                                    <div className="link-table-main">
                                        <Form.Label>Warranty</Form.Label>
                                        <div className="warranty-section">
                                            <span>
                                                <b>Name: </b>
                                                {itemType === 'purchase' ? purchaseData?.warranty_data?.[0]?.name || 'N/A' : serviceData?.warranty_data?.[0]?.name || 'N/A'}
                                            </span>
                                            <span>
                                                <b>Number: </b>
                                                {itemType === 'purchase' ? purchaseData?.warranty_data?.[1]?.number || 'N/A' : serviceData?.warranty_data?.[1]?.number || 'N/A'}
                                            </span>
                                            <span>
                                                <b>Dates: </b>
                                                {itemType === 'purchase'
                                                    ? `${purchaseData?.warranty_data?.[2]?.start_date || 'N/A'} - ${purchaseData?.warranty_data?.[3]?.end_date || 'N/A'}`
                                                    : `${serviceData?.warranty_data?.[2]?.start_date || 'N/A'} - ${serviceData?.warranty_data?.[3]?.end_date || 'N/A'}`}
                                            </span>
                                            <span>
                                                <b>Notes: </b>
                                                {itemType === 'purchase' ? purchaseData?.warranty_data?.[4]?.notes || 'N/A' : serviceData?.warranty_data?.[4]?.notes || 'N/A'}
                                            </span>

                                            {/* Warranty Files */}
                                            {serviceData?.warranty_data?.files?.length > 0 && (
                                                <Form.Group md="12" className="mb-3">
                                                    <div className="link-table-main">
                                                        <Form.Label>Warranty Files</Form.Label>
                                                        <div className="listofFiles">
                                                            {serviceData.warranty_data.files.map((file, index) => (
                                                                <div className="warrantyFile" key={index}>
                                                                    <div className="text">
                                                                        <img src={pdfIcon} alt="pdfIcon" />
                                                                        {file.split('/').pop()}
                                                                    </div>
                                                                    <div className="actions">
                                                                        <span
                                                                            onClick={() => handleViewFile(file)}
                                                                            style={{
                                                                                cursor: 'pointer'
                                                                            }}
                                                                        >
                                                                            <img src={eyeIcon} alt="View" />
                                                                        </span>
                                                                        <span
                                                                            onClick={() => handleDownloadFile(file)}
                                                                            style={{
                                                                                cursor: 'pointer'
                                                                            }}
                                                                        >
                                                                            <img src={downloadIcon} alt="Download" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            )}
                                            {/* Warranty Files */}
                                            {purchaseData?.warranty_data?.files?.length > 0 && (
                                                <Form.Group md="12" className="mb-3">
                                                    <div className="link-table-main">
                                                        <Form.Label>Warranty Files</Form.Label>
                                                        <div className="listofFiles">
                                                            {purchaseData.warranty_data.files.map((file, index) => (
                                                                <div className="warrantyFile" key={index}>
                                                                    <div className="text">
                                                                        <img src={pdfIcon} alt="pdfIcon" />
                                                                        {file.split('/').pop()}
                                                                    </div>
                                                                    <div className="actions">
                                                                        <span
                                                                            onClick={() => handleViewFile(file)}
                                                                            style={{
                                                                                cursor: 'pointer'
                                                                            }}
                                                                        >
                                                                            <img src={eyeIcon} alt="View" />
                                                                        </span>
                                                                        <span
                                                                            onClick={() => handleDownloadFile(file)}
                                                                            style={{
                                                                                cursor: 'pointer'
                                                                            }}
                                                                        >
                                                                            <img src={downloadIcon} alt="Download" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            )}
                                        </div>
                                    </div>
                                </Form.Group>
                            ) : (
                                <div>No Warranty Data</div>
                            )}
                        </Row>
                    </Form>

                    <div className="off-canvas-buttons-view form-actions d-flex justify-content-between gap-2">
                        <Button className="button-cancel" onClick={handleClose}>
                            Cancel
                        </Button>
                        <div className="d-flex gap-2">
                            <Button
                                className="delete-btn"
                                onClick={() => {
                                    confirmDelete(async (result) => {
                                        if (result.isConfirmed) {
                                            // if purchase then call delete purchase api other services

                                            const response = itemTpe === 'purchase' ? await deletePurchaseItemAPI(purchaseData.id) : await deleteServiceItemAPI(serviceData.id);
                                            if (response.status === 200 || response?.status === 201) {
                                                setPurchaseData(null);
                                                setServiceData(null);
                                                toast.success(itemTpe === 'purchase' ? 'Purchase Deleted Successfully' : 'Service Deleted Successfully');
                                                dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetAllPurchaseServices', data: true } });
                                                dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetSearchResults', data: true } });

                                                handleClose();
                                            }
                                        }
                                    });
                                }}
                            >
                                Delete
                            </Button>
                            <Button
                                onClick={() => {
                                    setShowEditPurchaseService(true);
                                }}
                                className="button-primary"
                            >
                                Edit
                            </Button>
                        </div>
                    </div>

                    {/* Modal to View File */}
                    <Modal show={showFileModal} onHide={() => setShowFileModal(false)} size="lg" centered>
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>{fileToView && fileToView.endsWith('.pdf') ? <iframe src={fileToView} style={{ width: '100%', height: '500px' }} frameBorder="0" title="PDF Viewer" /> : <img src={fileToView} alt="File" style={{ width: '100%' }} />}</Modal.Body>
                        <Modal.Footer></Modal.Footer>
                    </Modal>
                </div>
            )}
            <OffCanvasBootstrap
                placement="end"
                name="end"
                show={showEditPurchaseService}
                handleClose={() => {
                    setShowEditPurchaseService(false);
                }}
            >
                <PurchasesServicesNavAdd
                    handleClose={() => {
                        setShowEditPurchaseService(false);
                    }}
                    fetchPurchasesAndServicesData={fetchData}
                    itemType={itemTpe}
                    initialData={itemTpe === 'purchase' ? purchaseData : serviceData}
                />
            </OffCanvasBootstrap>
        </div>
    );
};

export default PurchasesServiceSideView;
