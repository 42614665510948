import React, { useState, useEffect } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { listOfFrequencies, fetchHomeEssentials } from '../../../axiosCalls/userdashboard';
import { Select } from '../inc/Select';

const CustomReminderForm = ({ onChange }) => {
    const [selectedReminder, setSelectedReminder] = useState({
        date: new Date().toISOString().split('T')[0],
        time: new Date().toTimeString().slice(0, 5)
    });
    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        const userData = storedUser ? JSON.parse(storedUser) : null;
        const meta_data = userData ? userData.meta_data : null;
        const default_reminder_time = meta_data?.default_reminder_time || null;
        setSelectedReminder((prev) => ({
            ...prev,
            time: default_reminder_time || '19:00'
        }));
    }, []);
    const [frequencies, setFrequencies] = useState([]);
    const [homeEssentials, setHomeEssentials] = useState([]);

    useEffect(() => {
        const fetchFrequencies = async () => {
            try {
                const response = await listOfFrequencies();
                setFrequencies(response.data);
            } catch (error) {
                console.error('Error fetching frequencies:', error);
            }
        };
        fetchFrequencies();

        const fetchEssentials = async () => {
            try {
                const response = await fetchHomeEssentials();
                const optionsData = response.data.items.map((item) => ({
                    label: `${item.type} - ${item.model_number}`,
                    value: item.id
                }));
                setHomeEssentials(optionsData);
            } catch (error) {
                console.error('Error fetching home essentials:', error);
            }
        };
        fetchEssentials();
    }, []);

    useEffect(() => {
        if (onChange) {
            onChange(selectedReminder); // Send updated reminder data to parent
        }
    }, [selectedReminder, onChange]);

    const handleFieldChange = (field, value) => {
        setSelectedReminder((prev) => ({
            ...prev,
            [field]: value
        }));
    };

    return (
        <Form>
            <Row>
                <Form.Group as={Col} md="12" className="mb-3">
                    <Form.Label>
                        Title <span className="requiredStar">*</span>
                    </Form.Label>
                    <Form.Control type="text" value={selectedReminder.title || ''} onChange={(e) => handleFieldChange('title', e.target.value)} placeholder="Add Title" required />
                </Form.Group>

                <Form.Group as={Col} md="12" className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" rows={3} value={selectedReminder.description || ''} onChange={(e) => handleFieldChange('description', e.target.value)} placeholder="Add Description" />
                </Form.Group>

                <Form.Group as={Col} md="12" className="mb-3">
                    <Form.Label>
                        Frequency <span className="requiredStar">*</span>
                    </Form.Label>
                    <Form.Control as="select" onChange={(e) => handleFieldChange('frequency', e.target.value)} required>
                        <option value="">Select frequency</option>
                        {frequencies.map((freq) => (
                            <option key={freq.id} value={freq.name}>
                                {freq.name}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>

                <Form.Group as={Col} md="12" className="mb-3">
                    <Form.Label>
                        Reminder Start Date <span className="requiredStar">*</span>
                    </Form.Label>
                    <Form.Control type="date" value={selectedReminder.date} onChange={(e) => handleFieldChange('date', e.target.value)} required />
                </Form.Group>

                <Form.Group as={Col} md="12" className="mb-3">
                    <Form.Label>Time</Form.Label>
                    <Form.Control type="time" value={selectedReminder.time} onChange={(e) => handleFieldChange('time', e.target.value)} />
                </Form.Group>

                <Form.Group as={Col} md="12" className="mb-3">
                    <Form.Label>Linked Home Essential</Form.Label>
                    <Select multiple options={homeEssentials} value={selectedReminder.linked_home_essentials || []} onChange={(selected) => handleFieldChange('linked_home_essentials', selected)} />
                </Form.Group>
            </Row>
        </Form>
    );
};

export default CustomReminderForm;
