import React, { useMemo } from 'react';
import { formatDate } from '../../utils/helper';
import lampIcon from '../../asstes/dash-icons/sidebar/Lamp.svg';
import DataTable from 'react-data-table-component';

const Purchases = ({ purchases, all, pagination }) => {
    const allPurchases = useMemo(() => purchases?.map((p) => ({ ...p?.meta, ...p })), [purchases]);
    const totalAmount = allPurchases?.reduce((total, purchase) => {
        // Convert the amount to a number
        const amount = parseFloat(purchase.amount);

        // Check if it's a valid number
        if (!isNaN(amount)) {
            total += amount;
        }

        return total;
    }, 0);
    const columns = [
        {
            name: 'Type',
            selector: (row) => row.type,
            sortable: true,
            reorder: true
        },
        {
            name: 'Name',
            selector: (row) => <div dangerouslySetInnerHTML={{ __html: row.title }} />,
            sortable: true,
            reorder: true
        },
        // {
        //     name: 'Make',
        //     selector: (row) => row.make,
        //     sortable: true,
        //     reorder: true
        // },
        // {
        //     name: 'Model #',
        //     selector: (row) => row.model,
        //     sortable: true,
        //     reorder: true
        // },
        {
            name: 'Project',
            selector: (row) => <div dangerouslySetInnerHTML={{ __html: row.product_name }} />,
            sortable: true,
            reorder: true
        },
        {
            name: 'Date',
            selector: (row) => row.date,
            sortable: true,
            reorder: true
        },
        {
            name: 'Cost',
            selector: (row) => row.cost,
            sortable: true,
            reorder: true
        }
    ];
    function formatString(input) {
        if (!input) return '';
        // Replace hyphen with underscore
        const updatedString = input.replace(/-/g, ' ');

        // Capitalize the first letter
        return updatedString.charAt(0).toUpperCase() + updatedString.slice(1);
    }
    const filteredPurchaseData = allPurchases?.map((item) => ({
        id: item.id,
        itemType: 'purchase',
        type: (
            <span className="services-badge type-green">
                <img src={lampIcon} alt="Purchase Icon" /> Purchase
            </span>
        ),
        title: formatString(item.title),
        name: item.product_name,
        make: item.make && item.make !== 'undefined' ? item.make : '',
        model: item.model_number && item.model_number !== 'undefined' ? item.model_number : '',
        project: item.property_id,
        project_name: formatString(item.project_name),
        date: formatDate(item.purchase_date),
        cost: `$${item.amount}`
    }));
    return (
        <>
            {!all && (
                <div className=" col-md-12 mb-3" id="expense-total">
                    <label className="reportSectiontitile">Expense Totals</label>
                    <div className="expenseTable">
                        <div className="expensItem">
                            <div className="expensTitle">Purchases</div>
                            <div className="expensAmount">${totalAmount?.toFixed(2)}</div>
                        </div>
                    </div>
                </div>
            )}

            <div className="col-md-12 mb-3" id="project-cost">
                <label className="reportSectiontitile">Purchases</label>
                <DataTable
                    columns={columns}
                    data={filteredPurchaseData} // Use the mapped data here
                    selectableRows={false}
                    pagination={pagination}
                    highlightOnHover={true}
                />
            </div>
        </>
    );
};

export default Purchases;
