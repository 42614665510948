import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import OffCanvasBootstrap from '../../off_canvas/OffCanvasBootstrap';
import PurchasesServicesNavAdd from '../../user-dashboard/components/inc/purchasesServicesNavAdd';

const VerifyScrapedData = ({ closeModal, scrapedData, setShowCreatePurchaseService, setImageData }) => {
    const [vendorName, setVendorName] = useState(scrapedData.vendorName);
    const [date, setDate] = useState(scrapedData.date);
    const [products, setProducts] = useState(scrapedData.products);

    useEffect(() => {
        if (scrapedData) {
            setVendorName(scrapedData.vendorName);
            setDate(scrapedData.date);
            setProducts(scrapedData.products);
        }
    }, [scrapedData]);

    const handleProductChange = (index, key, value) => {
        const updatedProducts = [...products];
        updatedProducts[index][key] = value;
        setProducts(updatedProducts);
    };

    const handleProductDelete = (index) => {
        const updatedProducts = products.filter((_, i) => i !== index);
        setProducts(updatedProducts);
    };

    const handleConfirm = () => {
        setImageData({
            vendorName: vendorName,
            date: date,
            products: products.map((product) => ({
                name: product.name,
                price: product.price
            })),
            amount: scrapedData?.totalBill
        });

        // Close modal
        closeModal();

        // Open off-canvas independently
        setTimeout(() => {
            setShowCreatePurchaseService(true);
        }, 100); // Optional delay for smoother transition
    };
    return (
        <>
            <h3 className="my-3">Verify Data</h3>

            <Row className="w-100 m-0">
                <Col md={6}>
                    <Form.Group className="mb-2">
                        <Form.Label>Vendor Name: </Form.Label>
                        <Form.Control type="text" value={vendorName} onChange={(e) => setVendorName(e.target.value)} />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-2">
                        <Form.Label>Date: </Form.Label>
                        <Form.Control type="date" value={date} onChange={(e) => setDate(e.target.value)} />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="w-100 m-0">
                <Col md={12}>
                    <Table striped bordered hover variant="light" border="1" style={{ width: '100%', marginBottom: '20px' }}>
                        <thead>
                            <tr>
                                <th style={{ padding: '10px' }}>#</th>
                                <th style={{ padding: '10px' }}>Product Name</th>
                                <th style={{ padding: '10px' }}>Price</th>
                                {/* <th style={{ padding: '10px' }}>Actions</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {products.map((product, index) => (
                                <tr key={index}>
                                    <td style={{ padding: '10px', textAlign: 'center' }}>{index + 1}</td>
                                    <td style={{ padding: '10px' }}>
                                        <Form.Control type="text" value={product.name} onChange={(e) => handleProductChange(index, 'name', e.target.value)} style={{ width: '90%' }} />
                                    </td>
                                    <td style={{ padding: '10px', textAlign: 'center' }}>
                                        <Form.Control disabled type="number" step="0.01" value={product.price} onChange={(e) => handleProductChange(index, 'price', parseFloat(e.target.value))} style={{ width: '90%' }} />
                                    </td>
                                    {/* <td style={{ padding: '10px', textAlign: 'center' }}>
                                        <Button variant="outline-danger" size="sm" onClick={() => handleProductDelete(index)}>
                                            Delete
                                        </Button>
                                    </td> */}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div className="my-3">
                        Total
                        <Form.Control disabled type="string" step="0.01" value={scrapedData?.totalBill || null} />
                    </div>
                    <Button className="action-button" onClick={handleConfirm}>
                        Confirm
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default VerifyScrapedData;
