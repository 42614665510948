import React, { useEffect, useState } from 'react';
import RecentEarning from '../inc/recent-earning';
import { getEarningList } from '../../axiosCalls/seller';

const REmindSellerProgram = () => {
    const [earningData, setEarningData] = useState([]);
    const [filteredEarningData, setFilteredEarningData] = useState([]);
    const [filter, setFilter] = useState('Alphabet');

    useEffect(() => {
        const fetchEarningData = async () => {
            const response = await getEarningList();
            setEarningData(response.data);
            setFilteredEarningData(response.data);
        };
        fetchEarningData();
    }, []);

    useEffect(() => {
        if (filter === 'Alphabet') {
            setFilteredEarningData([...earningData].sort((a, b) => a.title.localeCompare(b.title)));
        } else if (filter === 'Date') {
            setFilteredEarningData([...earningData].sort((a, b) => new Date(b.date) - new Date(a.date)));
        }
    }, [filter, earningData]);

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    return (
        <div className="content-wrapper">
            {/* <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div>
                        <div className="paddingRight0">
                            <div className="mt-3">
                                <div className="row mb-3">
                                    <div className="col-md-6 width40">
                                        <h2 className="card-heading">Earnings</h2>
                                    </div>
                                    <div className="col-md-6 card-right-action width60">
                                        <div className="recent-earning-sortable">
                                            <label>Sort by</label>
                                            <select value={filter} onChange={handleFilterChange} className="form-select">
                                                <option value="Alphabet">Alphabet</option>
                                                <option value="Date">Date</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <RecentEarning earningData={filteredEarningData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* Affiliate Program Details */}
            <div className="row mt-5">
                <div className="col-xl-12">
                    <h5 className="card-heading">Affiliate Program</h5>

                    <h5>Program Summary</h5>
                    <p>
                        Remind’s affiliate program is crafted to foster stronger relationships between professionals and homeowners while rewarding those who promote and share our service. Although the program is tailored for industry experts like realtors, it is open to anyone
                        passionate about advancing our mission.
                    </p>

                    <h5>Program Benefits</h5>
                    <ul>
                        <li>
                            <strong>Discounted Pricing:</strong> To expand our reach and serve as many homeowners as possible, remind provides discounted pricing to Affiliates who Gift or Refer our service, ensuring broad accessibility to a wide audience.
                        </li>
                        <li>
                            <strong>Flexibility:</strong> Affiliates can choose to either Gift or Refer remind to clients or individuals within their network, offering a flexible and personalized approach. Gifting remind is the perfect way to make a lasting impression with your
                            client at the closing table!
                        </li>
                        <li>
                            <strong>Royalty Payments:</strong> Affiliates are rewarded for their network-building efforts with ongoing royalties. For every Gift or Referral that renews, remind offers a percentage-based royalty, creating a valuable opportunity to generate a
                            sustainable, recurring income stream over time.
                        </li>
                        <li>
                            <strong>Data Analysis:</strong> Leverage remind to analyze homeowner data, empowering both you and your clients to make informed decisions—especially when it’s time to sell. By evaluating investment dollars spent on purchases, services, and projects,
                            you gain valuable insights into the financial standing of the home.
                        </li>
                        <li>
                            <strong>Credibility & Client Support:</strong> Introducing remind demonstrates your commitment to going above and beyond for your clients. It empowers them to take full control of their homeownership journey with confidence and ease.
                        </li>
                    </ul>

                    <h5>Gifting remind (to clients or anyone in network)</h5>
                    <ul>
                        <li>$30 paid by the Affiliate. Unlocks 12 months of service at no charge for the homeowner.</li>
                        <li>Affiliate recoups $30 if Homeowner renews at the 12-month mark (Year 2).</li>
                        <li>Affiliate receives 15% Commission Rate on all Gifted Subscription Renewals. Commission is perpetual for as long as the user continues to renew service.</li>
                    </ul>

                    <h5>Referring remind (to clients or anyone in network)</h5>
                    <ul>
                        <li>No cost to the Affiliate. Affiliate shares or sends a valid Referral link for Homeowners to sign up and use the service.</li>
                        <li>Homeowners receive 6 months of service at no charge.</li>
                        <li>Affiliate receives 5% Commission Rate on all Referred Subscription Renewals. Commission is perpetual for as long as the user continues to renew service.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default REmindSellerProgram;
