import React, { useEffect, useState } from 'react';
import { Accordion, Col, Form, Row } from 'react-bootstrap';
import { driver } from 'driver.js';
import { propertyReportOnboard } from './driver/constant';
import logo from '../asstes/images/logo.png';
import infoIcon from '../asstes/dash-icons/info.svg';
import ExpensesTotal from './propertyReport/ExpensesTotal';
import ExpensesDetail from './propertyReport/ExpensesDetail';
import Tasks from './propertyReport/Tasks';
import PropertyWarranties from './propertyReport/PropertyWarranties';
import Projects from './propertyReport/Projects';
import Files from './propertyReport/Files';
import Purchases from './propertyReport/Purchases';
import Services from './propertyReport/Services';
import HomeEssentials from './propertyReport/HomeEssentials';
import { getPropertyReportData } from '../../axiosCalls/userdashboard';
import { buttons, getProperty } from '../utils/helper';
import Loader from '../components/loader/Loader';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import 'jspdf-autotable';
import { downloadAllPDF, downloadFilesPDF, downloadHomeEssentialsPDF, downloadProjectsPDF, downloadPropertyWarrantiesPDF, downloadPurchasesPDF, downloadServicesPDF, downloadTasksPDF } from './pdf-functions';
import moment from 'moment';

// Array of buttons

const PropertyReports = () => {
    const contentRef = useRef(null);
    const reactToPrintFn = useReactToPrint({
        contentRef,
        layout: 'landscape'
    });
    const [purchases, setPurchases] = useState([]);
    const [services, setServices] = useState([]);
    const [homeEssentials, setHomeEssentials] = useState([]);
    const [completedTasks, setCompletedTasks] = useState([]);
    const [recentFiles, setRecentFiles] = useState([]);
    const [projects, setProjects] = useState([]);
    const [propertyWarranties, setPropertyWarranties] = useState([]);
    const [query, setQuery] = useState({
        filters: {
            report_contents: ['All']
        }
    });

    const propertyDrivers = driver(propertyReportOnboard);
    const [activeButton, setActiveButton] = useState(0); // Assuming the default active button is the first one
    const [activeTab, setActiveTab] = useState('');
    // Function to handle button click
    const handleButtonClick = (index, label) => {
        setActiveButton(index);
        setActiveTab(label === 'All' ? '' : label);
        const report_contents = query?.filters?.report_contents || [];
        report_contents.push(label);
        setQuery((prevQuery) => ({
            ...prevQuery,
            filters: {
                report_contents: [label]
            }
        }));
        // You can perform additional actions here when a button is clicked
    };

    const [isLoading, setIsLoading] = useState(false);

    const fetchPropertyReportData = async () => {
        try {
            setIsLoading(true);
            const selectedProperty = getProperty();
            const propertyId = selectedProperty.id;
            const resp = await getPropertyReportData({ ...query, property_id: propertyId });
            setPurchases(resp?.purchases || []);
            setServices(resp?.services || []);
            setHomeEssentials(resp?.home_essential || []);
            setCompletedTasks(resp?.task_overview || []);
            setRecentFiles(resp?.files || []);
            setProjects(resp?.projects || []);
            setPropertyWarranties(resp?.property_warranties || []);
        } catch (error) {
            // handle error
        } finally {
            setIsLoading(false);
        }
    };
    const [pagination, setPagination] = useState(false);

    useEffect(() => {
        fetchPropertyReportData();
    }, [query]);
    //
    function formatData(data) {
        if (!data || data.length === 0) {
            return;
        }
        return data.map((item) => {
            return { ...item, ...item.meta };
        });
    }

    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div className="row">
                        <div className="col-lg-5 col-md-12 col-sm-12 d-flex align-items-center">
                            <h5 className="mb-2 text-titlecase mb-4">Property Report</h5>
                            <img onClick={() => propertyDrivers.drive()} src={infoIcon} width={'14px'} className="mb-4 pb-2 ms-2 pointer" />
                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-12 d-flex text-top-subtext">
                            <div className=" top-text-head">
                                <div id="export-print" className="reminder-top-button">
                                    <button
                                        onClick={() => {
                                            // Export data in sheet

                                            const exportData = {
                                                purchases: formatData(
                                                    purchases.map((p) => ({
                                                        ...p,
                                                        report_type: 'Purchases Report'
                                                    }))
                                                ),
                                                services: formatData(services.map((s) => ({ ...s, report_type: 'Services Report' }))),
                                                homeEssentials: formatData(homeEssentials.map((h) => ({ ...h, report_type: 'Home Essentials Report' }))),
                                                tasks: formatData(completedTasks.map((t) => ({ ...t, report_type: 'Completed Tasks Report' }))),
                                                files: formatData(recentFiles.map((f) => ({ ...f, report_type: 'Files Report' }))),
                                                projects: formatData(projects.map((p) => ({ ...p, report_type: 'Projects Report' }))),
                                                property_warranties: formatData(propertyWarranties.map((p) => ({ ...p, report_type: 'Property Warranties Report' }))),
                                                start_date: query.start_date,
                                                end_date: query.end_date
                                            };
                                            downloadAllPDF(exportData);
                                        }}
                                        className="outline-yellow-button ExportBtn"
                                    >
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M13.5 6.99991V12.9999C13.5 13.2651 13.3946 13.5195 13.2071 13.707C13.0196 13.8945 12.7652 13.9999 12.5 13.9999H3.5C3.23478 13.9999 2.98043 13.8945 2.79289 13.707C2.60536 13.5195 2.5 13.2651 2.5 12.9999V6.99991C2.5 6.73469 2.60536 6.48033 2.79289 6.2928C2.98043 6.10526 3.23478 5.99991 3.5 5.99991H5C5.13261 5.99991 5.25979 6.05258 5.35355 6.14635C5.44732 6.24012 5.5 6.3673 5.5 6.49991C5.5 6.63251 5.44732 6.75969 5.35355 6.85346C5.25979 6.94723 5.13261 6.99991 5 6.99991H3.5V12.9999H12.5V6.99991H11C10.8674 6.99991 10.7402 6.94723 10.6464 6.85346C10.5527 6.75969 10.5 6.63251 10.5 6.49991C10.5 6.3673 10.5527 6.24012 10.6464 6.14635C10.7402 6.05258 10.8674 5.99991 11 5.99991H12.5C12.7652 5.99991 13.0196 6.10526 13.2071 6.2928C13.3946 6.48033 13.5 6.73469 13.5 6.99991ZM5.85375 4.35366L7.5 2.70678V8.49991C7.5 8.63251 7.55268 8.75969 7.64645 8.85346C7.74021 8.94723 7.86739 8.9999 8 8.9999C8.13261 8.9999 8.25979 8.94723 8.35355 8.85346C8.44732 8.75969 8.5 8.63251 8.5 8.49991V2.70678L10.1462 4.35366C10.2401 4.44748 10.3673 4.50018 10.5 4.50018C10.6327 4.50018 10.7599 4.44748 10.8538 4.35366C10.9476 4.25984 11.0003 4.13259 11.0003 3.99991C11.0003 3.86722 10.9476 3.73998 10.8538 3.64616L8.35375 1.14616C8.30731 1.09967 8.25217 1.06279 8.19147 1.03763C8.13077 1.01246 8.06571 0.999512 8 0.999512C7.93429 0.999512 7.86923 1.01246 7.80853 1.03763C7.74783 1.06279 7.69269 1.09967 7.64625 1.14616L5.14625 3.64616C5.05243 3.73998 4.99972 3.86722 4.99972 3.99991C4.99972 4.13259 5.05243 4.25983 5.14625 4.35365C5.24007 4.44748 5.36732 4.50018 5.5 4.50018C5.63268 4.50018 5.75993 4.44748 5.85375 4.35366Z"
                                                fill="#93703F"
                                            />
                                        </svg>
                                        Export
                                    </button>

                                    {/* <button
                                        onClick={() => {
                                            // set class report-header display to flex and after print again set to none
                                            // already have class d-flex
                                            const reportHeader = document.querySelector('.report-header');
                                            reportHeader.style.display = 'flex';
                                            // and remove the class from the parent div
                                            reportHeader.classList.remove('d-none');
                                            reactToPrintFn();
                                            reportHeader.style.display = 'none';
                                        }}
                                        className="filled-btn"
                                    >
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M13.4169 4.5H12.5V2.5C12.5 2.36739 12.4473 2.24021 12.3536 2.14645C12.2598 2.05268 12.1326 2 12 2H4C3.86739 2 3.74021 2.05268 3.64645 2.14645C3.55268 2.24021 3.5 2.36739 3.5 2.5V4.5H2.58313C1.71 4.5 1 5.17313 1 6V11C1 11.1326 1.05268 11.2598 1.14645 11.3536C1.24021 11.4473 1.36739 11.5 1.5 11.5H3.5V13.5C3.5 13.6326 3.55268 13.7598 3.64645 13.8536C3.74021 13.9473 3.86739 14 4 14H12C12.1326 14 12.2598 13.9473 12.3536 13.8536C12.4473 13.7598 12.5 13.6326 12.5 13.5V11.5H14.5C14.6326 11.5 14.7598 11.4473 14.8536 11.3536C14.9473 11.2598 15 11.1326 15 11V6C15 5.17313 14.29 4.5 13.4169 4.5ZM4.5 3H11.5V4.5H4.5V3ZM11.5 13H4.5V10H11.5V13ZM14 10.5H12.5V9.5C12.5 9.36739 12.4473 9.24021 12.3536 9.14645C12.2598 9.05268 12.1326 9 12 9H4C3.86739 9 3.74021 9.05268 3.64645 9.14645C3.55268 9.24021 3.5 9.36739 3.5 9.5V10.5H2V6C2 5.72438 2.26188 5.5 2.58313 5.5H13.4169C13.7381 5.5 14 5.72438 14 6V10.5ZM12.5 7.25C12.5 7.39834 12.456 7.54334 12.3736 7.66668C12.2912 7.79001 12.1741 7.88614 12.037 7.94291C11.9 7.99968 11.7492 8.01453 11.6037 7.98559C11.4582 7.95665 11.3246 7.88522 11.2197 7.78033C11.1148 7.67544 11.0434 7.5418 11.0144 7.39632C10.9855 7.25083 11.0003 7.10003 11.0571 6.96299C11.1139 6.82594 11.21 6.70881 11.3333 6.6264C11.4567 6.54399 11.6017 6.5 11.75 6.5C11.9489 6.5 12.1397 6.57902 12.2803 6.71967C12.421 6.86032 12.5 7.05109 12.5 7.25Z"
                                                fill="white"
                                            />
                                        </svg>
                                        Print
                                    </button> */}
                                </div>
                            </div>
                        </div>
                        <div ref={contentRef} className="row paddingRight0 reports-filter-page">
                            <div className="report-header d-none gap-2 align-items-center my-3">
                                <img height="25px" width="25px" src={logo} alt="logo" className="logo" />
                                <h6 className="m-0">Remind Home (Complete Property Report)</h6>
                                {/* today date */}
                                <div className="date-today d-flex ">
                                    <p className="m-0">Today</p>
                                    <p className="m-0">{moment().format('DD MMMM, YYYY')}</p>
                                </div>
                            </div>
                            {/* filters */}
                            <Accordion defaultActiveKey="0" className="filterAccordion">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Filters</Accordion.Header>
                                    <Accordion.Body>
                                        <Form id="property-filter">
                                            <Row className="mb-3">
                                                <label className="filter-heading">Report Contents</label>
                                                <div className="filter_buttons NavHeaderbuttons">
                                                    {buttons.map((button, index) => (
                                                        <button type="button" key={button.id} className={`purchase-btn ${activeButton === index ? 'activeFilterButton' : ''}`} onClick={() => handleButtonClick(index, button.label)}>
                                                            {button.icon} {/* Assuming you are rendering SVG icons */}
                                                            {button.label}
                                                        </button>
                                                    ))}
                                                </div>
                                            </Row>
                                            <Row>
                                                <Form.Group as={Col} md="6" className="mb-3">
                                                    <Form.Label>Start Date </Form.Label>
                                                    <Form.Control
                                                        onChange={(e) => {
                                                            setQuery({
                                                                ...query,
                                                                start_date: e.target.value
                                                            });
                                                        }}
                                                        type="date"
                                                        placeholder="Name"
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="6" className="mb-3">
                                                    <Form.Label>End Date</Form.Label>
                                                    <Form.Control
                                                        onChange={(e) => {
                                                            setQuery({ ...query, end_date: e.target.value });
                                                        }}
                                                        type="date"
                                                        placeholder="Name"
                                                    />
                                                </Form.Group>
                                            </Row>

                                            <div className="filter-footer-buttons reminder-top-button mb-3">
                                                <button
                                                    onClick={() => {
                                                        // set label same just start and end date to be empty
                                                        const tab = query.filters?.report_contents[0];
                                                        setQuery({
                                                            ...query,
                                                            filters: {
                                                                report_contents: [tab]
                                                            },
                                                            start_date: '',
                                                            end_date: ''
                                                        });
                                                    }}
                                                    className="clear-btn"
                                                >
                                                    Clear
                                                </button>
                                                {/* <button className="filled-btn">Apply Filters</button> */}
                                            </div>
                                        </Form>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            {!activeTab && !isLoading && (
                                <div className="row reportsPagesTable">
                                    <ExpensesTotal
                                        pagination={pagination}
                                        all={!activeTab}
                                        purchases={purchases}
                                        services={services}
                                        homeEssentials={homeEssentials}
                                        completedTasks={completedTasks}
                                        projects={projects}
                                        propertyWarranties={propertyWarranties}
                                        recentFiles={recentFiles}
                                    />
                                    <Purchases pagination={pagination} all={!activeTab} purchases={purchases} />
                                    <Services pagination={pagination} all={!activeTab} services={services} />
                                    <HomeEssentials pagination={pagination} all={!activeTab} homeEssentials={homeEssentials} />
                                    <Tasks pagination={pagination} all={!activeTab} completedTasks={completedTasks} />
                                    <Projects pagination={pagination} all={!activeTab} projects={projects} />
                                    <PropertyWarranties pagination={pagination} all={!activeTab} propertyWarranties={propertyWarranties} />
                                    <Files pagination={pagination} all={!activeTab} recentFiles={recentFiles} />
                                </div>
                            )}
                            <div className="row reportsPagesTable">
                                {isLoading && (
                                    <div className="my-3">
                                        <Loader />
                                    </div>
                                )}
                                {activeTab === 'Purchases' && !isLoading && <Purchases pagination={pagination} purchases={purchases} />}
                                {activeTab === 'Services' && !isLoading && <Services pagination={pagination} services={services} />}
                                {activeTab === 'Home Essentials' && !isLoading && <HomeEssentials pagination={pagination} homeEssentials={homeEssentials} />}
                                {activeTab === 'Completed Tasks' && !isLoading && <Tasks pagination={pagination} completedTasks={completedTasks} />}
                                {activeTab === 'Files' && !isLoading && <Files pagination={pagination} recentFiles={recentFiles} />}
                                {activeTab === 'Projects' && !isLoading && <Projects pagination={pagination} projects={projects} />}
                                {activeTab === 'Property Warranties' && !isLoading && <PropertyWarranties pagination={pagination} propertyWarranties={propertyWarranties} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PropertyReports;
