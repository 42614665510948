import '../../asstes/styles/addHomeEssential.css';
import React, { useEffect, useState } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from 'yup';
import pdfIcon from '../../asstes/dash-icons/pdfIcon.svg';
import LinkReminders from './linkReminders';
import { addHomeEssential, addNotificationData, get_home_essential_types, updateHomeEssential } from '../../../axiosCalls/userdashboard';
import ScrollToError from '../input/ScrollToError';
import { toast } from 'react-toastify';
import Loader from '../loader/Loader';
import { useDispatch } from 'react-redux';
import types from '../../../redux/actions/actionTypes';
import NotificationsList from './NotificationsList';

const HomeEssentialsSideNav = ({ fetchHomeEssential, fetchHomeEssentials, formData, handlerCancelSideNav }) => {
    const { name, make, model_number, purchase_date, age_of_home_essential, notes, warranty, type_id, warranty_data, files, images, linked_reminders, customType } = formData;
    const dispatch = useDispatch();
    const [homeEssentialTypes, setHomeEssentialTypes] = useState([]);

    // Fetch home essential types
    const fetch_home_essential_types = async () => {
        try {
            const response = await get_home_essential_types();
            const types = response.data;
            types.sort((a, b) => {
                if (a.name === 'Other') return 1;
                if (b.name === 'Other') return -1;
                return a.name.localeCompare(b.name);
            });
            setHomeEssentialTypes(types);
        } catch (error) {
            console.error('Error fetching home essential types:', error);
        }
    };

    useEffect(() => {
        fetch_home_essential_types();
    }, []); // Fetch home essential types once on mount

    const [initialValues, setInitialValues] = useState({
        notification_ids: formData?.notification_ids || [],
        homeEssentialName: name || '',
        make: make || '',
        modelNumber: model_number || '',
        purchaseDate: purchase_date || '',
        ageofEssential: age_of_home_essential || '',
        notes: notes || '',
        warranty: warranty || false,
        selectedType: type_id || '',
        customType: customType || '',
        warrantyData: warranty_data
            ? {
                  name: warranty_data[0]?.name,
                  number: warranty_data[1]?.number,
                  startDate: warranty_data[2]?.start_date,
                  endDate: warranty_data[3]?.end_date,
                  notes: warranty_data[4]?.notes
              }
            : {
                  name: '',
                  number: '',
                  startDate: '',
                  endDate: '',
                  notes: ''
              },
        warrantyFiles: warranty_data?.files || [],
        files: files || [],
        images: images || [],
        linkedReminders: linked_reminders || []
    });

    useEffect(() => {
        setInitialValues({
            notification_ids: formData?.notification_ids || [],
            homeEssentialName: name || '',
            make: make || '',
            modelNumber: model_number || '',
            purchaseDate: purchase_date || '',
            ageofEssential: age_of_home_essential || '',
            notes: notes || '',
            warranty: warranty || false,
            selectedType: type_id || '',
            customType: customType || '',
            warrantyData: warranty_data
                ? {
                      name: warranty_data[0]?.name,
                      number: warranty_data[1]?.number,
                      startDate: warranty_data[2]?.start_date,
                      endDate: warranty_data[3]?.end_date,
                      notes: warranty_data[4]?.notes
                  }
                : {
                      name: '',
                      number: '',
                      startDate: '',
                      endDate: '',
                      notes: ''
                  },
            warrantyFiles: warranty_data?.files || [],
            files: files || [],
            images: images || [],
            linkedReminders: linked_reminders || []
        });
    }, [formData]);

    const validationSchema = Yup.object({
        homeEssentialName: Yup.string().required('Home Essential Name is required'),
        purchaseDate: Yup.date().required('Purchase Date is required'),
        selectedType: Yup.string().required('Type is required')
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        // Submit the form if no errors
        try {
            const { homeEssentialName, make, modelNumber, purchaseDate, ageofEssential, linkedReminders, notes, selectedType, warranty, warrantyData, files, images, warrantyFiles } = values;
            setSubmitting(true);
            const data = new FormData();
            data.append('type', selectedType);
            if (homeEssentialName) data.append('name', homeEssentialName);
            if (make) data.append('make', make);
            if (modelNumber) data.append('model_number', modelNumber);
            if (ageofEssential) data.append('age_of_home_essential', ageofEssential);
            data.append('purchase_date', purchaseDate);
            const storedUser = localStorage.getItem('user');
            const userData = storedUser ? JSON.parse(storedUser) : null;
            const meta_data = userData ? userData.meta_data : null;
            const default_reminder_time = meta_data?.default_reminder_time || null;
            if (!formData?.id || (formData?.id && formData?.addReminder)) {
                linkedReminders?.forEach((remind) => {
                    const reminderData = JSON.stringify({
                        id: remind.id,
                        date: remind.start_date,
                        frequency_id: remind.frequency_id,
                        frequency: remind.frequency,
                        reminder_frequency: remind.reminder_frequency,
                        time: remind.time
                    });
                    data.append('linked_reminders[]', reminderData);
                });
            }

            if ((linkedReminders?.length > 0 && !formData?.id) || (formData?.id && formData?.addReminder)) {
                const responses = await Promise.all(linkedReminders.map((reminder) => addNotificationData({ ...reminder, date: reminder.start_date, ...(reminder?.time ? { time: reminder?.time } : { time: default_reminder_time || '19:00' }) }, reminder.id)));
                const notification_ids = responses?.map((r) => r.data?.event_id) || [];
                notification_ids?.forEach((notification_id) => {
                    data.append('notification_ids[]', notification_id);
                });
            }

            if (notes) data.append('notes', notes);
            data.append('warranty', warranty === 'checked' ? 'true' : 'false');

            if (warranty) {
                if (warrantyData.name) data.append('warranty_data[][name]', warrantyData.name);
                if (warrantyData.number) data.append('warranty_data[][number]', warrantyData.number);
                if (warrantyData.startDate) data.append('warranty_data[][start_date]', warrantyData.startDate);
                if (warrantyData.endDate) data.append('warranty_data[][end_date]', warrantyData.endDate);
                if (warrantyData.notes) data.append('warranty_data[][notes]', warrantyData.notes);
                if (warrantyFiles?.length) warrantyFiles?.forEach((file) => data.append('warranty_data_files[]', file));
            }
            if (files?.length) files?.forEach((file) => data.append('files[]', file));
            if (images?.length) images?.forEach((image) => data.append('images[]', image));

            const response = formData?.id ? await updateHomeEssential(data, formData?.id) : await addHomeEssential(data);
            if (response.data.post_id) {
                if (fetchHomeEssentials) fetchHomeEssentials();
                if (fetchHomeEssential) fetchHomeEssential();
                dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetAllReminderEvents', data: true } });
                dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetSearchResults', data: true } });
                toast.success(response.data.message);
                handlerCancelSideNav();
            }
        } catch (error) {
            console.error('Error submitting home essential:', error);
        } finally {
            setSubmitting(false);
        }
    };

    const resetForm = () => {
        setInitialValues({
            homeEssentialName: '',
            make: '',
            modelNumber: '',
            purchaseDate: '',
            ageofEssential: '',
            notes: '',
            warranty: false,
            selectedType: '',
            warrantyData: {
                name: '',
                number: '',
                startDate: '',
                endDate: '',
                notes: ''
            },
            warrantyFiles: [],
            files: [],
            images: [],
            linkedReminders: []
        });
        handlerCancelSideNav();
    };

    return (
        <div className="side-nav">
            <div className="side-header">
                <h3>{formData?.id ? 'Edit Home Essential' : 'Add Home Essential'}</h3>
            </div>
            <div className="side-body">
                <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({ isSubmitting, errors, values, setFieldValue, handleChange, touched }) => (
                        <FormikForm>
                            <ScrollToError />
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" className="mb-3">
                                    <Form.Label>
                                        Home Essential Name <span className="requiredStar">*</span>
                                    </Form.Label>
                                    <Form.Control type="text" placeholder="Name" name="homeEssentialName" value={values.homeEssentialName} onChange={handleChange} isInvalid={touched.homeEssentialName && errors.homeEssentialName} />
                                    <Form.Control.Feedback type="invalid">{errors.homeEssentialName}</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="6">
                                    <Form.Label>
                                        Type <span className="requiredStar">*</span>
                                    </Form.Label>
                                    <Form.Select name="selectedType" value={values.selectedType} onChange={handleChange} isInvalid={touched.selectedType && errors.selectedType}>
                                        <option value="">Select Type</option>
                                        {homeEssentialTypes.map((type) => (
                                            <option key={type.id} value={type.id}>
                                                {type.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="6" className="mb-3">
                                    <Form.Label>Make</Form.Label>
                                    <Form.Control type="text" placeholder="e.g. 2012" name="make" value={values.make} onChange={handleChange} />
                                </Form.Group>

                                <Form.Group as={Col} md="12" className="mb-3">
                                    <Form.Label>Model Number</Form.Label>
                                    <Form.Control type="text" placeholder="e.g. 4689-65498-4646" name="modelNumber" value={values.modelNumber} onChange={handleChange} />
                                </Form.Group>

                                <Form.Group as={Col} md="6" className="mb-3">
                                    <Form.Label>
                                        Purchase Date <span className="requiredStar">*</span>
                                    </Form.Label>
                                    <Form.Control type="date" name="purchaseDate" value={values.purchaseDate} onChange={handleChange} isInvalid={touched.purchaseDate && errors.purchaseDate} />
                                    <Form.Control.Feedback type="invalid">{errors.purchaseDate}</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="6" className="mb-3">
                                    <Form.Label>Age of Home Essential</Form.Label>
                                    <Form.Control type="date" name="ageofEssential" value={values.ageofEssential} onChange={handleChange} />
                                </Form.Group>
                            </Row>
                            {formData?.id && (
                                <Row className="mb-3">
                                    <Form.Group>
                                        <Form.Check
                                            className="d-flex align-items-center gap-2"
                                            style={{ padding: 0 }}
                                            type="checkbox"
                                            label="Add Reminder"
                                            name="warranty"
                                            checked={values.addReminder}
                                            onChange={(e) => {
                                                setFieldValue('addReminder', e.target.checked);
                                            }}
                                        />
                                    </Form.Group>
                                </Row>
                            )}

                            {formData?.id && values.addReminder && (
                                <Row>
                                    <Form.Group md="12" className="mb-3">
                                        <div className="link-table-main">
                                            <Form.Label>Linked Reminders</Form.Label>
                                            <LinkReminders
                                                selectedType={values.selectedType}
                                                type="homeEssential"
                                                onRemindersUpdate={(reminders) => {
                                                    setFieldValue('linkedReminders', reminders);
                                                }}
                                            />
                                        </div>
                                    </Form.Group>
                                </Row>
                            )}

                            {values?.selectedType && !formData.id && (
                                <Row>
                                    <Form.Group md="12" className="mb-3">
                                        <div className="link-table-main">
                                            <Form.Label>Linked Reminders</Form.Label>
                                            <LinkReminders
                                                selectedType={values.selectedType}
                                                type="homeEssential"
                                                onRemindersUpdate={(reminders) => {
                                                    setFieldValue('linkedReminders', reminders);
                                                }}
                                            />
                                        </div>
                                    </Form.Group>
                                </Row>
                            )}

                            {formData?.id && (
                                <Row>
                                    <Form.Group as={Col} md="12" className="mb-3">
                                        <div className="link-table-main">
                                            <Form.Label>Notifications</Form.Label>
                                            <NotificationsList notifications={formData?.notification_lists || []} />
                                        </div>
                                    </Form.Group>
                                </Row>
                            )}

                            <Row>
                                <Form.Group md="12" className="mb-3">
                                    <div className="files-choosen">
                                        <div className="title">Files</div>
                                        <div className="button">
                                            <div className="outline-yellow-button">
                                                Add file
                                                <input
                                                    type="file"
                                                    className="chooeseFile"
                                                    accept=".pdf"
                                                    multiple
                                                    onChange={(e) => {
                                                        const selectedFiles = [...e.target.files];
                                                        const updatedList = [...values.files, ...selectedFiles];
                                                        setFieldValue('files', updatedList);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="listOfImages">
                                        {values?.files?.map((file, index) => (
                                            <>
                                                {typeof file === 'string' ? (
                                                    <div className="image-choosed d-flex align-items-center gap-2" key={index}>
                                                        {file.endsWith('pdf') ? (
                                                            <>
                                                                <img src={pdfIcon} alt="pdf" />
                                                                <span>{file}</span>
                                                            </>
                                                        ) : (
                                                            <img src={file} alt="File" />
                                                        )}
                                                        <Button
                                                            size="sm"
                                                            variant="danger"
                                                            onClick={() => {
                                                                const updatedList = values.files.filter((_, i) => i !== index);
                                                                setFieldValue('files', updatedList);
                                                            }}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    <div className="image-choosed d-flex align-items-center gap-2" key={index}>
                                                        {file.type === 'application/pdf' ? (
                                                            <>
                                                                <img src={pdfIcon} alt="pdf" />
                                                                <span>{file.name}</span>
                                                            </>
                                                        ) : (
                                                            <img src={URL.createObjectURL(file)} alt="File" />
                                                        )}
                                                        <Button
                                                            size="sm"
                                                            variant="danger"
                                                            onClick={() => {
                                                                const updatedList = values.files.filter((_, i) => i !== index);
                                                                setFieldValue('files', updatedList);
                                                            }}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </div>
                                                )}
                                            </>
                                        ))}
                                    </div>
                                </Form.Group>

                                <Form.Group md="12" className="mb-3">
                                    <div className="files-choosen">
                                        <div className="title">Images</div>
                                        <div className="button">
                                            <div className="outline-yellow-button">
                                                Add image
                                                <input
                                                    type="file"
                                                    className="chooeseFile"
                                                    accept="image/*"
                                                    multiple
                                                    onChange={(e) => {
                                                        const selectedImages = [...e.target.files];
                                                        const updatedList = [...values.images, ...selectedImages];
                                                        setFieldValue('images', updatedList);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="listOfImages">
                                        {values?.images?.map((image, index) => (
                                            <>
                                                {typeof image === 'string' ? (
                                                    <div className="image-choosed d-flex align-items-center gap-2" key={index}>
                                                        <img src={image} alt="File" />
                                                        <Button
                                                            size="sm"
                                                            variant="danger"
                                                            onClick={() => {
                                                                const updatedList = values.images.filter((_, i) => i !== index);
                                                                setFieldValue('images', updatedList);
                                                            }}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    <div className="image-choosed d-flex align-items-center gap-2" key={index}>
                                                        <img src={URL.createObjectURL(image)} alt="File" />
                                                        <Button
                                                            size="sm"
                                                            variant="danger"
                                                            onClick={() => {
                                                                const updatedList = values.images.filter((_, i) => i !== index);
                                                                setFieldValue('images', updatedList);
                                                            }}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </div>
                                                )}
                                            </>
                                        ))}
                                    </div>
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group as={Col} md="12" className="mb-3">
                                    <Form.Label>Additional Notes</Form.Label>
                                    <Form.Control as="textarea" name="notes" value={values.notes} onChange={handleChange} />
                                </Form.Group>
                            </Row>

                            <Row className="mb-5">
                                <Col md={12}>
                                    <Form.Group md="12" className="mb-3 ">
                                        <Form.Check
                                            className="d-flex align-items-center gap-2"
                                            style={{ padding: 0 }}
                                            type="checkbox"
                                            label="Add warranty details"
                                            name="warranty"
                                            checked={values.warranty}
                                            onChange={(e) => {
                                                setFieldValue('warranty', e.target.checked);
                                            }}
                                        />
                                    </Form.Group>
                                </Col>

                                {values.warranty && (
                                    <div className="mb-5">
                                        <Row>
                                            <Form.Group as={Col} md="6" className="mb-3">
                                                <Form.Label>Warranty Name</Form.Label>
                                                <Form.Control type="text" name="warrantyData.name" value={values.warrantyData.name} onChange={handleChange} />
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" className="mb-3">
                                                <Form.Label>Warranty Number</Form.Label>
                                                <Form.Control type="text" name="warrantyData.number" value={values.warrantyData.number} onChange={handleChange} />
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" className="mb-3">
                                                <Form.Label>Warranty Start Date</Form.Label>
                                                <Form.Control type="date" name="warrantyData.startDate" value={values.warrantyData.startDate} onChange={handleChange} />
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" className="mb-3">
                                                <Form.Label>Warranty End Date</Form.Label>
                                                <Form.Control type="date" name="warrantyData.endDate" value={values.warrantyData.endDate} onChange={handleChange} />
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group md="12" className="mb-3">
                                                <div className="files-choosen">
                                                    <div className="title">Warranty Files</div>
                                                    <div className="button">
                                                        <div className="outline-yellow-button">
                                                            Add file
                                                            <input
                                                                type="file"
                                                                className="chooeseFile"
                                                                accept=".pdf"
                                                                multiple
                                                                onChange={(e) => {
                                                                    const selectedFiles = [...e.target.files];
                                                                    const updatedList = [...values.warrantyFiles, ...selectedFiles];
                                                                    setFieldValue('warrantyFiles', updatedList);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="listOfImages">
                                                    {values?.warrantyFiles?.map((file, index) => (
                                                        <>
                                                            {typeof file === 'string' ? (
                                                                <div className="image-choosed d-flex align-items-center gap-2" key={index}>
                                                                    {file.endsWith('pdf') ? (
                                                                        <>
                                                                            <img src={pdfIcon} alt="pdf" />
                                                                            <span>{file}</span>
                                                                        </>
                                                                    ) : (
                                                                        <img src={file} alt="File" />
                                                                    )}
                                                                    <Button
                                                                        size="sm"
                                                                        variant="danger"
                                                                        onClick={() => {
                                                                            const updatedList = values.warrantyFiles.filter((_, i) => i !== index);
                                                                            setFieldValue('warrantyFiles', updatedList);
                                                                        }}
                                                                    >
                                                                        Remove
                                                                    </Button>
                                                                </div>
                                                            ) : (
                                                                <div className="image-choosed d-flex align-items-center gap-2" key={index}>
                                                                    {file.type === 'application/pdf' ? (
                                                                        <>
                                                                            <img src={pdfIcon} alt="pdf" />
                                                                            <span>{file.name}</span>
                                                                        </>
                                                                    ) : (
                                                                        <img src={URL.createObjectURL(file)} alt="File" />
                                                                    )}
                                                                    <Button
                                                                        size="sm"
                                                                        variant="danger"
                                                                        onClick={() => {
                                                                            const updatedList = values.warrantyFiles.filter((_, i) => i !== index);
                                                                            setFieldValue('warrantyFiles', updatedList);
                                                                        }}
                                                                    >
                                                                        Remove
                                                                    </Button>
                                                                </div>
                                                            )}
                                                        </>
                                                    ))}
                                                </div>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} md="12" className="mb-3">
                                                <Form.Label>Warranty Notes</Form.Label>
                                                <Form.Control as="textarea" name="warrantyData.notes" value={values.warrantyData.notes} onChange={handleChange} />
                                            </Form.Group>
                                        </Row>
                                    </div>
                                )}
                            </Row>

                            <div className="off-canvas-buttons form-actions d-flex justify-content-between gap-2">
                                <Button className="button-cancel" onClick={resetForm}>
                                    Cancel
                                </Button>
                                <Button disabled={isSubmitting} className="button-primary" type="submit">
                                    {isSubmitting ? <Loader size="sm" /> : formData?.id ? 'Update' : 'Save'}
                                </Button>
                            </div>
                        </FormikForm>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default HomeEssentialsSideNav;
