import React, { useState } from 'react';
import { Table, Button, Container } from 'react-bootstrap';
import OffCanvasBootstrap from '../../../off_canvas/OffCanvasBootstrap';
import ReminderSideNav from './reminderSideNav';

const NotificationsList = ({ notifications, view }) => {
    const [showReminderEdit, setShowReminderEdit] = useState(false);
    const [selectedReminder, setSelectedReminder] = useState(null);
    return (
        <Container className="mt-4">
            <Table responsive bordered hover>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Date</th>
                        {!view && <th>Action</th>}
                    </tr>
                </thead>
                <tbody>
                    {notifications.map((notification, index) => (
                        <tr key={notification.ID}>
                            <td>
                                {/* render title html */}
                                <div dangerouslySetInnerHTML={{ __html: notification.title }} />
                            </td>
                            <td>{notification.date}</td>
                            {!view && (
                                <td>
                                    <Button
                                        size="sm"
                                        variant="primary"
                                        onClick={() => {
                                            setSelectedReminder(notification);
                                            setShowReminderEdit(true);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </Table>

            <OffCanvasBootstrap
                placement="end"
                name="end"
                closeButton
                show={showReminderEdit}
                handleClose={() => {
                    setShowReminderEdit(false);
                }}
            >
                <ReminderSideNav reminder={selectedReminder} />
            </OffCanvasBootstrap>
        </Container>
    );
};

export default NotificationsList;
