import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import plusIcon from '../../asstes/dash-icons/plusIcon.svg';
import { addFileApis, addNotificationData, getCustomReminders, updateFileApis } from '../../../axiosCalls/userdashboard';
import checkedIcon from '../../asstes/dash-icons/checkedIcon.svg'; // Add a checked icon for selection
import pdfIcon from '../../asstes/dash-icons/pdfIcon.svg';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import AddRemindertModal from '../modals/AddReminderModal';
import './style.css';
import Loader from '../loader/Loader';
import ScrollToError from '../input/ScrollToError';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import types from '../../../redux/actions/actionTypes';
import ReminderSelector from '../reminders_selector/ReminderSelector';
import { formatISO, parseISO, isValid, parse } from 'date-fns';
const getDate = (d) => {
    // If `d` is a valid ISO string, format it correctly
    if (typeof d === 'string') {
        // Try to parse as ISO first
        const parsedDate = parseISO(d);
        if (isValid(parsedDate)) {
            return formatISO(parsedDate, { representation: 'date' });
        }

        // If it's not a valid ISO string, try parsing it as MM-DD-YYYY
        const parsedDateMMDDYYYY = parse(d, 'MM-dd-yyyy', new Date());
        if (isValid(parsedDateMMDDYYYY)) {
            return formatISO(parsedDateMMDDYYYY, { representation: 'date' });
        }
    } else if (d instanceof Date && isValid(d)) {
        return formatISO(d, { representation: 'date' });
    }

    // Return a default value or handle the invalid date case
    console.error('Invalid date value provided:', d);
    return ''; // Return an empty string for the input if invalid
};

const FilesSideNavAdd = forwardRef(({ handleClose, fileData, fetchFileList }, ref) => {
    //  = ({ handlerCustomReminder, isSubmittedFiles }) => {
    const dispatch = useDispatch();

    const [initialState, setInitialState] = useState({
        fileName: '',
        uploadDate: formatISO(new Date(), { representation: 'date' }), // Ensure UTC format
        files: [],
        reminders: []
    });

    const handleReminderClick = (reminder) => {
        setInitialState((prevState) => {
            const reminders = prevState.reminders || []; // Ensure reminders array exists
            const updatedReminderIds = reminders.some((r) => r.id === reminder.id)
                ? reminders.filter((r) => r.id !== reminder.id) // Remove reminder if it exists
                : [...reminders, reminder]; // Add reminder if it doesn't exist

            return {
                ...prevState,
                reminders: updatedReminderIds
            };
        });
    };

    const resetForm = () => {
        setInitialState({
            fileName: '',
            uploadDate: getDate(fileData?.upload_date), // Convert to UTC format
            files: [],
            reminderIds: []
        });
    };

    useImperativeHandle(ref, () => ({
        resetForm
    }));

    useEffect(() => {
        if (fileData) {
            setInitialState({
                ...fileData,
                fileName: fileData?.file_name,
                uploadDate: getDate(fileData?.upload_date), // Convert to UTC format
                files: fileData?.files, // State for uploaded files
                reminders: fileData?.reminders
            });
        }
    }, [fileData]);

    const validationSchema = Yup.object().shape({
        fileName: Yup.string().required('File Name is required'),
        uploadDate: Yup.date().required('Upload Date is required'),
        files: Yup.array().min(1, 'Please select at least one file')
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            setSubmitting(true);
            const data = new FormData();
            data.append('file_name', values.fileName);
            data.append('upload_date', getDate(values.uploadDate)); // Ensure consistent date format

            //  data.append('related_to', '3');
            if (values.files) {
                values?.files?.forEach((file) => data.append('files[]', file));
            }

            if (values?.reminders && values?.reminders?.length > 0) {
                values?.reminders?.forEach((reminder) => {
                    data.append('linked_reminders[]', reminder.id);
                });
                const storedUser = localStorage.getItem('user');
                const userData = storedUser ? JSON.parse(storedUser) : null;
                const meta_data = userData ? userData.meta_data : null;
                const default_reminder_time = meta_data?.default_reminder_time || null;
                const responses = await Promise.all(values?.reminders?.map((reminder) => addNotificationData({ ...reminder, ...(reminder?.time ? { time: reminder?.time } : { time: default_reminder_time }) }, reminder.id)));
                const notification_ids = responses?.map((r) => r.data?.event_id) || [];
                notification_ids?.forEach((notification_id) => {
                    data.append('notification_ids[]', notification_id);
                });
            }

            // Use Promise.all to handle all API calls concurrently
            const res = values?.post_id ? await updateFileApis(data, values?.post_id) : await addFileApis(data);
            if (res && res.status) {
                dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetRecentFiles', data: true } });
                dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetSearchResults', data: true } });
                dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetAllReminderEvents', data: true } });
                if (fetchFileList) fetchFileList();
                toast.success(res.data.message);
                handleClose();
            }
        } catch (error) {
            // handle error
        } finally {
            setSubmitting(false);
        }
    };

    // Function to get the appropriate icon for files
    const getFileIcon = (file) => {
        if (typeof file === 'string' && file.endsWith('pdf')) {
            return pdfIcon;
        } else if (typeof file === 'string') {
            return file;
        }
        if (file.type === 'application/pdf') {
            return pdfIcon;
        } else if (file instanceof File) {
            return URL.createObjectURL(file);
        }
    };

    return (
        <div className="side-nav">
            <div className="side-header">
                <h3>{initialState?.post_id ? 'Edit File' : 'Add File'}</h3>
            </div>
            <div className="side-body">
                <Formik enableReinitialize initialValues={initialState} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({ isSubmitting, errors, values, touched, handleChange, setFieldValue }) => (
                        <FormikForm>
                            <ScrollToError />
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" className="mb-3">
                                    <Form.Label>
                                        File Name <span className="requiredStar">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="File name"
                                        name="fileName" // Use name attribute to differentiate fields
                                        value={values.fileName}
                                        onChange={(e) => {
                                            setInitialState((pre) => ({
                                                ...pre,
                                                fileName: e.target.value
                                            }));
                                        }} // Update state on input change
                                        isInvalid={touched?.fileName && errors.fileName}
                                    />
                                    <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" className="mb-3">
                                    <Form.Label>
                                        Upload Date <span className="requiredStar">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="uploadDate" // Use name attribute to differentiate fields
                                        value={values.uploadDate}
                                        onChange={(e) => {
                                            setInitialState((pre) => ({
                                                ...pre,
                                                uploadDate: e.target.value
                                            }));
                                        }} // Update state on date change
                                        isInvalid={touched?.uploadDate && errors.uploadDate}
                                    />
                                    <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group md="12" className="mb-3">
                                    <div className={`files-choosen uploadFile  ${touched?.files && !values?.files?.length ? 'invalid-error' : ''}`}>
                                        <div className="title">You can attach different formats: png, pdf, jpg</div>
                                        <div style={{ color: '#dc3545', fontSize: '.875em' }} className="button">
                                            <div className="outline-button-upload">
                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M12 7.50015V11.0001C12 11.2654 11.8946 11.5197 11.7071 11.7073C11.5196 11.8948 11.2652 12.0001 11 12.0001H1C0.734784 12.0001 0.48043 11.8948 0.292893 11.7073C0.105357 11.5197 0 11.2654 0 11.0001V7.50015C0 7.36754 0.0526785 7.24036 0.146447 7.1466C0.240215 7.05283 0.367392 7.00015 0.5 7.00015C0.632608 7.00015 0.759785 7.05283 0.853553 7.1466C0.947321 7.24036 1 7.36754 1 7.50015V11.0001H11V7.50015C11 7.36754 11.0527 7.24036 11.1464 7.1466C11.2402 7.05283 11.3674 7.00015 11.5 7.00015C11.6326 7.00015 11.7598 7.05283 11.8536 7.1466C11.9473 7.24036 12 7.36754 12 7.50015ZM3.85375 3.3539L5.5 1.70702V7.50015C5.5 7.63276 5.55268 7.75993 5.64645 7.8537C5.74021 7.94747 5.86739 8.00015 6 8.00015C6.13261 8.00015 6.25979 7.94747 6.35355 7.8537C6.44732 7.75993 6.5 7.63276 6.5 7.50015V1.70702L8.14625 3.3539C8.24007 3.44772 8.36732 3.50043 8.5 3.50043C8.63268 3.50043 8.75993 3.44772 8.85375 3.3539C8.94757 3.26008 9.00028 3.13283 9.00028 3.00015C9.00028 2.86747 8.94757 2.74022 8.85375 2.6464L6.35375 0.146399C6.30731 0.0999109 6.25217 0.0630315 6.19147 0.0378693C6.13077 0.0127072 6.06571 -0.000244141 6 -0.000244141C5.93429 -0.000244141 5.86923 0.0127072 5.80853 0.0378693C5.74783 0.0630315 5.69269 0.0999109 5.64625 0.146399L3.14625 2.6464C3.05243 2.74022 2.99972 2.86747 2.99972 3.00015C2.99972 3.13283 3.05243 3.26008 3.14625 3.3539C3.24007 3.44772 3.36732 3.50043 3.5 3.50043C3.63268 3.50043 3.75993 3.44772 3.85375 3.3539Z"
                                                        fill="#00487C"
                                                    />
                                                </svg>
                                                Upload file
                                                <input
                                                    type="file"
                                                    className="chooeseFile"
                                                    multiple
                                                    accept=".png, .pdf, .jpg"
                                                    onChange={(e) => {
                                                        const copyFiles = values.files || [];
                                                        const newFiles = e.target.files;
                                                        const updatedFiles = [...copyFiles, ...newFiles];
                                                        // set InitialValues
                                                        setInitialState((pre) => ({
                                                            ...pre,
                                                            files: updatedFiles
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {touched?.files && !values?.files?.length && <span style={{ color: '#dc3545', fontSize: '.875em' }}>Required</span>}
                                    <div className="listOfFiles">
                                        {values?.files?.map((file, index) => (
                                            <div className="image-choosed" key={index}>
                                                <a href={typeof file === 'string' ? file : URL.createObjectURL(file)} target="_blank" rel="noopener noreferrer">
                                                    <img src={getFileIcon(file)} alt="File preview" />
                                                </a>
                                                <button
                                                    onClick={() => {
                                                        const copyFiles = values.files || [];
                                                        const updatedFiles = copyFiles.filter((_, i) => i !== index);
                                                        setFieldValue('files', updatedFiles);
                                                    }}
                                                    type="button"
                                                    className="image-choosed-crossed"
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </Form.Group>
                            </Row>

                            <ReminderSelector handleReminderClick={handleReminderClick} selectedReminders={(values?.reminders && values?.reminders?.length > 0 && values?.reminders?.map((r) => r.id)) || []} />

                            <div className="off-canvas-buttons form-actions d-flex justify-content-between gap-2">
                                <Button className="button-cancel" onClick={handleClose} disabled={isSubmitting}>
                                    Cancel
                                </Button>
                                <Button type="submit" className="button-primary" disabled={isSubmitting}>
                                    {isSubmitting ? <Loader size="sm" /> : values?.post_id ? 'Update' : 'Save'}
                                </Button>
                            </div>
                        </FormikForm>
                    )}
                </Formik>
            </div>
        </div>
    );
});

export default FilesSideNavAdd;
