import React from 'react';
import eyeIcon from '../../user-dashboard/asstes/dash-icons/eyeIcon.svg';
import editIcon from '../assets/icons/edit-icon.svg';
import deleteIcon from '../../user-dashboard/asstes/dash-icons/deleteIcon.svg';
import DataTable, { Alignment, Direction } from 'react-data-table-component';

const RecentlyGiftedSubscriptions = ({ Giftdata }) => {
    const columns = [
        {
            name: 'Homeowner First Name',
            selector: (row) => row.first_name,
            sortable: false,
            reorder: true
        },
        {
            name: 'Homeowner Last Name',
            selector: (row) => row.last_name,
            sortable: false,
            reorder: true
        },
        {
            name: 'Email',
            selector: (row) => row.email,
            sortable: false,
            reorder: true
        },
        {
            name: 'Account Sign Up Date',
            selector: (row) => row.ReferralDate,
            sortable: false,
            reorder: true
        },
        {
            name: 'Renewal Date',
            selector: (row) => row.RenewalDate,
            sortable: false,
            reorder: true
        },
        {
            name: 'Remaining Time to Claim Your Royalty',
            selector: (row) => row.RoyaltyClaimTimeLeft,
            sortable: false,
            reorder: true
        }
        // {
        //     name: "Action",
        //     selector: (row) => row.Action,
        //     sortable: false,
        //     reorder: true,
        // }
    ];
    // Map users to the new structure
    const data = Giftdata?.map((user, index) => {
        // Calculate the time left for royalty claim (example: difference between today and renewal date)
        const renewalDate = user.renewal_date ? new Date(user.renewal_date) : null;

        const today = new Date();
        let timeLeft = 'N/A'; // Default value

        if (renewalDate) {
            const diffTime = Math.max(renewalDate - today, 0); // Ensure no negative time
            const diffMonths = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 30));
            const diffDays = Math.floor((diffTime % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));
            timeLeft = `${diffMonths} Months ${diffDays} Days Remaining`;
        }

        return {
            id: 1,
            user_id: user.ID,
            first_name: <div className="fileName">{user.first_name}</div>,
            last_name: <div className="fileName">{user.last_name}</div>,
            email: <div className="fileName">{user.email}</div>,
            ReferralDate: <div className="fileName">{user.sign_up_date ? new Date(user.sign_up_date).toLocaleDateString() : 'N/A'}</div>,
            RenewalDate: <div className="fileName">{renewalDate ? renewalDate.toLocaleDateString() : 'N/A'}</div>,
            RoyaltyClaimTimeLeft: <div className="fileName">{timeLeft}</div>,
            Action: (
                <div className="actions">
                    <span>
                        <img src={eyeIcon} alt="View" />
                    </span>
                    <span></span>
                    <span>
                        <img src={deleteIcon} alt="Delete" />
                    </span>
                </div>
            )
        };
    });

    return (
        <div className="recentFilesTable recentEarning">
            <DataTable
                columns={columns}
                data={data}
                defaultSortFieldId={2}
                selectableRows={false}
                selectableRowsNoSelectAll={false}
                selectableRowsVisibleOnly={false}
                selectableRowsHighlight={false}
                selectableRowsSingle={false}
                expandableRows={false}
                expandOnRowClicked={false}
                expandOnRowDoubleClicked={false}
                expandableRowsHideExpander={false}
                pagination={true}
                highlightOnHover={true}
                striped={false}
                pointerOnHover={false}
                dense={false}
                persistTableHead={true}
                noHeader={false}
                fixedHeader={false}
                fixedHeaderScrollHeight={'300px'}
                progressPending={false}
                noTableHead={false}
                noContextMenu={false}
                direction={Direction.AUTO}
                subHeader={false}
                subHeaderAlign={Alignment.RIGHT}
                subHeaderWrap={true}
                responsive={true}
                disabled={false}
            />
        </div>
    );
};

export default RecentlyGiftedSubscriptions;
