import React from 'react';
import DataTable, { Alignment, Direction } from 'react-data-table-component';
import pdfIcon from '../../asstes/dash-icons/pdfIcon.svg';
import eyeIcon from '../../asstes/dash-icons/eyeIcon.svg';
import downloadIcon from '../../asstes/dash-icons/downloadIcon.svg';
import deleteIcon from '../../asstes/dash-icons/deleteIcon.svg';
import { formatDate } from '../../utils/helper';
import dummyImg from '../../asstes/images/dummy-img.jpg';

const Files = ({ recentFiles, pagination }) => {
    const getFileIcon = (file) => {
        if (!file) {
            return dummyImg;
        }
        if (file instanceof File) {
            if (file.type === 'application/pdf') {
                return pdfIcon;
            } else {
                return URL.createObjectURL(file); // Get object URL for image files
            }
        } else {
            const fileExtension = file.split('.').pop().toLowerCase();
            return fileExtension === 'pdf' ? pdfIcon : file;
        }
    };
    // Function to determine the type of the file (PDF or image)
    const getFileType = (fileUrl) => {
        const fileExtension = fileUrl.split('.').pop().toLowerCase();

        if (fileExtension === 'pdf') {
            return 'pdf';
        } else if (['png', 'jpg', 'jpeg', 'gif'].includes(fileExtension)) {
            return 'image';
        }
        // Add more cases as needed
        return 'unknown'; // Default to unknown type
    };
    const dataFiles = recentFiles?.map((item, itemIndex) => {
        if (!item.file_url || item.file_url.length === 0) {
            // If no files, return a placeholder object
            return [
                {
                    ...item,
                    id: `${itemIndex + 1}-0`,
                    filename: (
                        <div className="fileName">
                            <img src={getFileIcon('')} alt="file icon" className="fileItemImage" />
                            {`${item.file_name || 'N/A'}`} {/* Display file_name: actual filename */}
                        </div>
                    ),
                    fileUrl: null,

                    Type: null,
                    date: item.upload_date ? formatDate(item.upload_date) : null
                }
            ];
        }

        // If files are present, process each file
        return item.file_url.map((fileUrl, fileIndex) => {
            const fileName = item?.file_name; // Get the file name from the URL
            const fileType = getFileType(fileUrl); // Get file type (pdf, image, etc.)

            return {
                ...item,
                fileUrl,
                id: `${itemIndex + 1}-${fileIndex + 1}`, // Unique ID combining outer and inner loop indices
                filename: (
                    <div className="fileName">
                        <img src={getFileIcon(fileUrl)} alt="file icon" className="fileItemImage" />
                        {`${fileName}`} {/* Display file_name: actual filename */}
                    </div>
                ),

                Type: <span className="services-badge type-green">{fileType}</span>,
                date: formatDate(item.upload_date) // Use the upload date from the current item
            };
        });
    });
    const columnsFiles = [
        {
            name: 'File name',
            selector: (row) => row.filename || 'N/A',
            sortable: false,
            reorder: true
        },
        {
            name: 'Type',
            selector: (row) => row.Type,
            sortable: false,
            reorder: true
        },
        // {
        //     name: 'Notes',
        //     selector: (row) => row.Notes,
        //     sortable: false,
        //     reorder: true
        // },
        {
            name: 'Date',
            selector: (row) => row.date,
            sortable: false,
            reorder: true
        }

        // {
        //     name: 'View',
        //     selector: (row) => row.action,
        //     sortable: false,
        //     reorder: true
        // }
    ];

    return (
        <div className="col-md-12 mb-3" id="property-files">
            <label className="reportSectiontitile">Property Files</label>
            <div className="recentFilesTable ">
                <DataTable
                    columns={columnsFiles}
                    data={dataFiles
                        ?.map((i) => i[0])
                        ?.map((f) => ({
                            ...f,
                            action: (
                                <div className="actionIcons">
                                    {f.fileUrl && (
                                        <img
                                            onClick={() => {
                                                if (getFileType(f.fileUrl) === 'pdf') {
                                                    window.open(f.fileUrl, '_blank');
                                                } else {
                                                    const link = document.createElement('a');
                                                    link.href = f.fileUrl;
                                                    link.target = '_blank';
                                                    link.click();
                                                }
                                            }}
                                            src={eyeIcon}
                                            alt="eye icon"
                                            className="actionIcon"
                                        />
                                    )}
                                </div>
                            )
                        }))}
                    defaultSortFieldId={2}
                    selectableRows={false}
                    selectableRowsNoSelectAll={false}
                    selectableRowsVisibleOnly={false}
                    selectableRowsHighlight={false}
                    selectableRowsSingle={false}
                    expandableRows={false}
                    expandOnRowClicked={false}
                    expandOnRowDoubleClicked={false}
                    expandableRowsHideExpander={false}
                    pagination={pagination}
                    paginationPerPage={5}
                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    highlightOnHover={true}
                    striped={false}
                    pointerOnHover={false}
                    dense={false}
                    persistTableHead={true}
                    noHeader={false}
                    fixedHeader={false}
                    fixedHeaderScrollHeight={'300px'}
                    progressPending={false}
                    noTableHead={false}
                    noContextMenu={false}
                    direction={Direction.AUTO}
                    subHeader={false}
                    subHeaderAlign={Alignment.RIGHT}
                    subHeaderWrap={true}
                    responsive={true}
                    disabled={false}
                    className="proteryReport"
                />
            </div>
        </div>
    );
};

export default Files;
