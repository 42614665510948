import { useState } from 'react';
import { updateProfile } from '../../../axiosCalls/auth';

const reminderOnbarod = {
    showProgress: 'true',
    steps: [
        {
            element: '#reminders-search',
            popover: {
                title: 'Search',
                description: 'You can search reminders here'
            }
        },
        {
            element: '#google-calendar',
            popover: {
                title: 'Google Calendar',
                description: 'You can connect google calendar '
            }
        },
        {
            element: '#add-reminder-calendar',
            popover: {
                title: 'Add Reminder',
                description: 'By click this button, you can add reminders'
            }
        }
    ]
};
const servicesOnboard = {
    showProgress: 'true',
    steps: [
        {
            element: '#services-search',
            popover: {
                title: 'Search',
                description: 'You can search services here'
            }
        },
        {
            element: '#expense-detail',
            popover: {
                title: 'Expense',
                description: 'You can see expense detail by click this button'
            }
        },
        {
            element: '#add-purchase-service',
            popover: {
                title: 'Add Purchase & Service',
                description: 'You can add purchases and services here'
            }
        }
    ]
};
const projectOnboard = {
    showProgress: 'true',
    steps: [
        {
            element: '#add-project',
            popover: {
                title: 'Add Project',
                description: 'You can add project by complete the form'
            }
        }
    ]
};
const fileOnboard = {
    showProgress: 'true',
    steps: [
        {
            element: '#search-file',
            popover: {
                title: 'Search',
                description: 'You can search file through'
            }
        },
        {
            element: '#filter-file',
            popover: {
                title: 'Filter',
                description: 'Filter out data by selecting filters'
            }
        },
        {
            element: '#add-file',
            popover: {
                title: 'Add File',
                description: 'You can add a file by filling out the form details'
            }
        }
    ]
};
const propertyReportOnboard = {
    showProgress: 'true',
    steps: [
        {
            element: '#export-print',
            popover: {
                title: 'Export & Print',
                description: 'You can export & print data'
            }
        },
        {
            element: '#property-filter',
            popover: {
                title: 'Property Filter',
                description: 'You can apply all those filters'
            }
        },
        {
            element: '#expense-total',
            popover: {
                title: 'Expense Total',
                description: 'Here is the expense total section which is shown all total expenses'
            }
        },
        {
            element: '#expense-detail',
            popover: {
                title: 'Expense Detail',
                description: 'Here is the expense detail section which is shown all expense detail'
            }
        },
        {
            element: '#task-detail-overview',
            popover: {
                title: 'Task Detail & Overview',
                description: 'Here is the section for task detail & overview'
            }
        },
        {
            element: '#property-warranty',
            popover: {
                title: 'Property Warranty',
                description: 'Shown Property Warranties'
            }
        },
        {
            element: '#project-cost',
            popover: {
                title: 'Project',
                description: 'Shown Project Cost'
            }
        },
        {
            element: '#property-files',
            popover: {
                title: 'Property Files',
                description: 'Shown all property files'
            }
        }
    ]
};
const entireDriver = {
    onPopoverRender: async (popover, { config, state }) => {
        const totalSteps = config.steps.length - 1;
        const activeStep = state.activeIndex;

        const storedUser = localStorage.getItem('user');
        const userData = JSON.parse(storedUser);

        const updatedUser = {
            ...userData,
            meta_data: {
                ...userData.meta_data,
                mainTour: true
            }
        };
        try {
            const response = await updateProfile({
                ...updatedUser
            });
            if (response && response.data) {
                localStorage.setItem('user', JSON.stringify(response.data));
            }
        } catch (error) {}
    },
    showProgress: 'true',
    steps: [
        {
            element: '#welcome',
            popover: {
                title: 'Welcome!',
                description: 'Welcome to Remind! Get ready to open the door to the limitless features available at your fingertips. Let’s explore the system together.'
            }
        },
        {
            element: '#add-property',
            popover: {
                title: 'Add Property',
                description: 'Click the <strong>Add Property</strong> button to create your first property. If you have multiple properties, leverage the Remind dashboard to see them at a glance before making individual selections.'
            }
        },
        // {
        //     // element: '#add-property-google-search-box',
        //     element: '#add-property',
        //     popover: {
        //         title: 'Add Property',
        //         description: 'Search for your property address in the search bar or create a custom entry if your property does not appear.'
        //     }
        // },
        {
            element: '#home-essentials',
            popover: {
                title: 'Home Essential',
                description: 'Add Home Essentials to your property. These are key appliances, systems and assets that keep your home running smoothly.',
                side: 'right',
                align: 'start'
            }
        },
        {
            element: '#reminders',
            popover: {
                title: 'Reminders',
                description: 'Keep upcoming tasks and reminders top of mind by viewing them on the Remind calendar.'
            }
        },
        {
            element: '#purchases-services',
            popover: {
                title: 'Purchase and Services',
                description: 'Track all purchases and services to stay aligned with financial goals and easily access important details when needed.',
                side: 'right',
                align: 'start'
            }
        },
        {
            element: '#projects',
            popover: {
                title: 'Projects',
                description: 'Making home improvement updates? Add home projects as they occur to consolidate financial data, documentation and project history.',
                side: 'right',
                align: 'start'
            }
        },
        {
            element: '#files',
            popover: {
                title: 'Files',
                description: 'Upload files, such as quotes, insurance policies, warranties and more to your dashboard for easy access and organization. You can also set custom reminders for important dates or deadlines.',
                side: 'right',
                align: 'start'
            }
        },
        {
            element: '#notification',
            popover: {
                title: 'Notifications',
                description: 'Stay informed with real-time alerts for overdue tasks and upcoming reminders.',
                side: 'right',
                align: 'start'
            }
        },
        {
            element: '#property-report',
            popover: {
                title: 'Property Report',
                description: 'Dive deep into your property details, by accessing and generating reports with all historical data.',
                side: 'right',
                align: 'start'
            }
        },
        {
            element: '#user-settings',
            popover: {
                title: 'User Settings',
                description: 'Adjust your preferences and account settings.',
                side: 'right',
                align: 'start'
            }
        },
        {
            element: '#user-settings',
            popover: {
                title: 'User Settings',
                description: 'Download the Remind mobile app for easy access and on-the-go convenience.',
                side: 'right',
                align: 'start'
            }
        },
        {
            element: '#congratulations',
            popover: {
                title: 'Congratulations',
                description: 'We’re handing over the keys, now unlock the door to your successful homeownership journey!',
                side: 'right',
                align: 'start'
            }
        }
        // {
        //     element: '#seller-switch',
        //     popover: {
        //         title: 'Switch to different role',
        //         description: 'Change your role based on access',
        //         side: 'right',
        //         align: 'start'
        //     }
        // }
    ]
};

const affilateOverview = {
    onPopoverRender: async (popover, { config, state }) => {
        const storedUser = localStorage.getItem('user');
        const userData = JSON.parse(storedUser);

        const updatedUser = {
            ...userData,
            meta_data: {
                ...userData.meta_data,
                affilateTour: true
            }
        };
        try {
            const response = await updateProfile({
                ...updatedUser
            });
            if (response && response.data) {
                localStorage.setItem('user', JSON.stringify(response.data));
            }
        } catch (error) {}
    },
    showProgress: 'true',
    steps: [
        {
            // id: "account-balance",
            element: '#welcome',
            popover: {
                title: 'Welcome!',
                description:
                    'Thank you for being a valued Remind affiliate! Your partnership plays a monumental role in helping empower homeowners with the tools they need to achieve long-term investment success. Explore the system to get familiar with key features and helpful tools.'
            }
        },
        {
            // id: "account-balance",
            element: '#account-balance',
            popover: {
                title: 'Account Balance',
                description: 'Check your account balance anytime to see the available funds in your Remind wallet.'
                // side: "top",
                // align: "center",
            }
        },
        {
            // id: "life-time-earnings",
            element: '#life-time-earnings',
            popover: {
                title: 'Lifetime Earnings',
                description: 'Track your real-time lifetime earnings.'
                // side: "right",
                // align: "start",
            }
        },
        {
            id: 'ytd-earnings',
            element: '#ytd-earnings',
            popover: {
                title: 'YTD Earnings',
                description: 'Track your real-time YTD earnings.',
                side: 'right',
                align: 'start'
            }
        },
        {
            id: 'giftings-remind',
            element: '#giftings-remind',
            popover: {
                title: 'Gift Earnings',
                description: 'Track your real-time gifted earnings.',
                side: 'right',
                align: 'start'
            }
        },
        {
            id: 'referal-code',
            element: '#referal-code',
            popover: {
                title: 'Referral Earnings',
                description: 'Track your real-time referral earnings.',
                side: 'right',
                align: 'start'
            }
        },
        {
            id: 'num-of-code-redeemed',
            element: '#num-of-code-redeemed',
            popover: {
                title: 'Number of Codes Redeemed',
                description: 'Monitor how many referral codes have been redeemed from your service referrals.',
                side: 'right',
                align: 'start'
            }
        },
        {
            id: 'num-of-subscriptions-gifted',
            element: '#num-of-subscriptions-gifted',
            popover: {
                title: 'Number of Subscriptions Gifted',
                description: 'Track the number of subscriptions you’ve gifted, both lifetime and year-to-date.',
                side: 'right',
                align: 'start'
            }
        },
        {
            id: 'gift-refer-reminders',
            element: '#gift-refer-reminders',
            popover: {
                title: 'Gift Remind!',
                description: 'Introduce Remind to your clients or network members by gifting them the service.',
                side: 'right',
                align: 'start'
            }
        },
        {
            id: 'gift-refer-reminders',
            element: '#gift-refer-reminders',
            popover: {
                title: 'Refer Remind!',
                description: 'Loving the Remind platform? Effortlessly refer it to your clients or network members.',
                side: 'right',
                align: 'start'
            }
        },
        {
            id: 'my-network',
            element: '#my-network',
            popover: {
                title: 'My Network',
                description: 'Stay connected and up-to-date by viewing your network and its details in real time.',
                side: 'right',
                align: 'start'
            }
        },
        {
            id: 'user-settings',
            element: '#user-settings',
            popover: {
                title: 'User Settings',
                description: 'Adjust your preferences and account settings in User Settings.',
                side: 'right',
                align: 'start'
            }
        },
        {
            id: 'payments',
            element: '#payments',
            popover: {
                title: 'Set-up Remind Wallet',
                description: 'As an Affiliate, you’ll need to set up your wallet. Use it to gift our service and collect commission payments for sharing the product.',
                side: 'right',
                align: 'start'
            }
        },
        {
            id: 'congratulations',
            element: '#congratulations',
            popover: {
                title: 'Congratulations',
                description: 'We’re handing over the keys, now get ready to enjoy the benefits of being part of the Remind affiliate community!',
                side: 'right',
                align: 'start'
            }
        }
        // {
        //     id: 'payments',
        //     element: '#payments',
        //     popover: {
        //         title: 'Payments',
        //         description: 'Set up your wallet and bank information to manage transactions. Affiliates need to configure their Stripe wallet to make their first gift or referral, which can be done during onboarding or before gifting or referring a subscription.',
        //         side: 'right',
        //         align: 'start'
        //     }
        // }
    ]
};

export { reminderOnbarod, servicesOnboard, projectOnboard, entireDriver, fileOnboard, propertyReportOnboard, affilateOverview };
