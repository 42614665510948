import React from 'react';
import { Outlet } from 'react-router-dom';
import RoleSelectionAndTerms from './user-dashboard/pages/roles-terms';
import { Container } from 'react-bootstrap';

const RoleSelectionGuard = () => {
    // Check user and verification status
    const user = JSON.parse(localStorage.getItem('user')); // Adjust the key as per your setup
    const isNotVerified = user?.meta_data['terms-status'] === 'not-verified';

    if (isNotVerified) {
        return (
            <>
                <Container style={{ height: '100vh', width: '100vw' }}>
                    <div className=" d-flex justify-content-center align-items-center h-100">
                        <RoleSelectionAndTerms />
                    </div>
                </Container>
            </>
        );
    }

    return <Outlet />; // Render child routes if verified
};

export default RoleSelectionGuard;
