import './App.css';
import CreateRoutes from './routes';
// boostrap
import 'bootstrap/dist/css/bootstrap.min.css';
import './user-dashboard/asstes/styles/responsiveness.css';
import './seller/assets/seller-responsiveness.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import 'driver.js/dist/driver.css';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getToken, messaging } from './firebase';
import { save_device_token } from './axiosCalls/userdashboard';
// Load Stripe instance
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBKEY);

function App() {
    const location = useLocation();
    const navigate = useNavigate();

    const storedUser = localStorage.getItem('user');
    const user = storedUser ? JSON.parse(storedUser) : null;

    const [notificationPromptShown, setNotificationPromptShown] = useState(false);

    const requestNotificationPermission = async () => {
        const permission = Notification.permission;

        if (permission === 'granted') {
            // No need to prompt again
            const deviceToken = await getToken(messaging, {
                vapidKey: process.env.REACT_APP_FIREBASE_VAP_ID_KEY
            });

            if (deviceToken) {
                const _resp = await save_device_token({ device_token: deviceToken });
            }
        }

        if (permission === 'denied') {
            // Notifications are blocked; explain how to enable them manually
            const userAccepted = window.confirm('You have blocked notifications. To enable them, please update your browser settings. Would you like help with that?');

            if (userAccepted) {
                // Guide the user on enabling notifications in settings
                alert('Go to your browser settings and enable notifications for this site.');
            }
            return false;
        }

        if (permission === 'default') {
            // The user has not made a choice yet
            const userAccepted = window.confirm('We’d like to send you notifications to keep you updated. Please allow notifications.');

            if (userAccepted) {
                try {
                    const result = await Notification.requestPermission();
                    if (result === 'granted') {
                        const deviceToken = await getToken(messaging, {
                            vapidKey: process.env.REACT_APP_FIREBASE_VAP_ID_KEY
                        });

                        if (deviceToken) {
                            const _resp = await save_device_token({ device_token: deviceToken });
                        }
                    } else {
                        return false;
                    }
                } catch (error) {
                    return false;
                }
            } else {
                return false;
            }
        }
    };

    useEffect(() => {
        if (!notificationPromptShown && user && user.meta_data?.remind_push_notifications === 'enabled') {
            requestNotificationPermission().then(() => {
                // Set state to true to prevent showing the prompt again
                setNotificationPromptShown(true);
            });
        }
    }, [user, notificationPromptShown]);

    useEffect(() => {
        // Extract token from URL parameters if available
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');

        if (token) {
            try {
                // get user

                // Decode the token using jwt-decode
                const decodedPayload = JSON.parse(atob(token)); // Decode Base64

                // Store the token in localStorage
                localStorage.setItem('user', JSON.stringify(decodedPayload.data));
                localStorage.setItem('token', decodedPayload.data.token);
                navigate('/dashboard');
            } catch (error) {}
        }
    }, [location]);
    return (
        <div className="App">
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

            <ToastContainer />
            <Elements stripe={stripePromise}>
                <CreateRoutes />
            </Elements>
        </div>
    );
}

export default App;
