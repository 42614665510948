import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { plansList } from '../../../axiosCalls/auth';
import { create_subscription, get_user_next_plan, get_user_Profile } from '../../../axiosCalls/userdashboard';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { isPlanActive } from '../../utils/helper';

const PlanEffectModal = ({ showModal, closeModal, setsideNavWidthOverlay, setsideNavWidth, setPropertyCheckMain }) => {
    // get all plans
    const isPlan = isPlanActive();
    const [nextPlan, setNextPlans] = React.useState(null);
    const fetchPlan = async () => {
        try {
            const response = await get_user_next_plan();
            setNextPlans(response?.data || {});
        } catch (error) {
            console.error('Error fetching plans:', error);
        } finally {
        }
    };
    useEffect(() => {
        if (isPlan) fetchPlan();
    }, [isPlan]);

    const stripe = useStripe();
    const storedUser = localStorage.getItem('user');
    const elements = useElements();
    const user = JSON.parse(storedUser);
    const [isLoading, setIsLoading] = useState(false);

    const upgradePlanFuncIntent = async () => {
        if (!stripe || !elements || !elements.getElement(CardElement)) {
            return;
        }

        const userEmail = user.email;

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: { email: userEmail }
        });

        if (error) {
            toast.error(error.message);
            return;
        }
        setIsLoading(true);
        const body = JSON.stringify({
            payment_method: paymentMethod.id,
            email: userEmail,
            price_id: nextPlan.stripe_plan_id,
            plan_id: nextPlan.id
        });
        const res = await create_subscription(body);
        if (res && res.status === 200) {
            toast.success('Payment successful!');
            await get_user_Profile();
            closeModal();
        } else {
            toast.error(res.data.message);
        }

        setIsLoading(false);
    };

    return (
        <>
            {/* // this may effect you plan modal, use react-bootstrap modal */}
            <Modal centered show={showModal} onHide={closeModal}>
                <Modal.Header className="p-3">
                    <Modal.Title>Plan Effect</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-3">
                    <p>This will effect the plan immediately. Are you sure you want to effect this</p>
                    {nextPlan && (
                        <div className="form-group mt-3">
                            <label>Next Plan Details:</label>
                            <table className="plan-table table">
                                <tbody>
                                    <tr>
                                        <th scope="row">Title</th>
                                        <td>{nextPlan.title}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Properties Allowed</th>
                                        <td>{nextPlan.properties_allowed}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Price</th>
                                        <td>${nextPlan.price}/year</td>
                                    </tr>
                                </tbody>
                            </table>
                            <CardElement
                                options={{
                                    style: {
                                        base: {
                                            marginTop: '10px',
                                            fontSize: '16px',
                                            color: 'black',
                                            '::placeholder': {
                                                color: '#808080'
                                            }
                                        },
                                        invalid: {
                                            color: '#9e2146'
                                        }
                                    }
                                }}
                            />
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer className="p-3">
                    <button onClick={upgradePlanFuncIntent} className="btn btn-primary">
                        {isLoading ? 'Loading...' : 'Yes'}
                    </button>
                    <button onClick={closeModal} className="btn btn-danger">
                        No
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PlanEffectModal;
