import React, { useEffect, useState } from 'react';
import DataTable, { Alignment, Direction } from 'react-data-table-component';
import lampIcon from '../../asstes/dash-icons/sidebar/Lamp.svg';
import ReceiptIcon from '../../asstes/dash-icons/sidebar/Receipt.svg';
import BroomIcon from '../../asstes/dash-icons/sidebar/Broom.svg';
import PurchasesServiceSideView from './purchasesServiceSideView';
import { deletePurchaseItemAPI, deleteServiceItemAPI, updatePurchaseItem, updateServiceItem } from '../../../axiosCalls/userdashboard';
import { toast } from 'react-toastify';
import Loader from '../loader/Loader';
import AddLineItem from './addlineItem';
import OffCanvasBootstrap from '../../../off_canvas/OffCanvasBootstrap';
import { formatDate } from '../../utils/helper';

const columns = [
    {
        name: 'Type',
        selector: (row) => row.type,
        sortable: true,
        reorder: true
    },
    {
        name: 'Name',
        selector: (row) => row.name,
        sortable: true,
        reorder: true
    },
    // {
    //     name: 'Make',
    //     selector: (row) => row.make,
    //     sortable: true,
    //     reorder: true
    // },
    // {
    //     name: 'Model #',
    //     selector: (row) => row.model,
    //     sortable: true,
    //     reorder: true
    // },
    {
        name: 'Project',
        selector: (row) => row.project_name || 'N/A',
        sortable: true,
        reorder: true
    },
    {
        name: 'Date',
        selector: (row) => row.date,
        sortable: true,
        reorder: true
    },
    {
        name: 'Cost',
        selector: (row) => row.cost,
        sortable: true,
        reorder: true
    }
];

const PurchasesServicesTable = ({ searchQuery, purchaseData, serviceData, editLoading, setEditLoading, deleteLoading, setDeleteLoading, fetchPurchasesAndServicesData }) => {
    const [sideNavWidth, setSideNavWidth] = useState('0px');
    const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState('0%');
    const [continueChange, setContinueChange] = useState(false);

    const [selectedID, setSelectedID] = useState(); // State for storing the service data
    const [itemType, setItemType] = useState(''); // State for storing the service data
    const [purchaseDataU, setPurchaseDataU] = useState({});
    const [serviceDataU, setServiceDataU] = useState({});
    const [prodLineItem, setProductLineItems] = useState([]);
    const [updateProdItem, setUpdateProItem] = useState(null);
    const [selectedTab, setSelectedTab] = useState('purchase');

    const handleRowSideNavOpen = (e) => {
        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        setContinueChange('viewStep');
        document.body.classList.add('no-scroll');
        setSelectedID(e.id);
        setItemType(e.itemType);
    };

    const handlerCancelSideNav = () => {
        setSideNavWidth('sidenavPopuWidthHide');
        setsideNavWidthOverlay('0%');
        setContinueChange('viewStep');
        document.body.classList.remove('no-scroll');
        setProductLineItems([]);
    };
    const handlerUpdatePurchase = async () => {
        setEditLoading(true);
        const { amount, files, project, linked_homeessential, linked_reminders, make, model_number, notes, product_name, purchase_date, warranty, receipt_url, uploaded_files, warranty_data } = purchaseDataU;
        const data = new FormData();
        if (product_name) data.append('product_name', product_name);
        if (make) data.append('make', make);
        if (model_number) data.append('model_number', model_number);
        if (purchase_date) data.append('purchase_date', purchase_date);
        if (amount) data.append('amount', amount);
        data.append('line_items', JSON.stringify(prodLineItem));
        if (linked_reminders)
            linked_reminders.forEach((remind) => {
                const reminderData = JSON.stringify({
                    id: remind.id,
                    date: remind.start_date,
                    frequency_id: remind.frequency_id,
                    frequency: remind.frequency,
                    reminder_frequency: remind.reminder_frequency,
                    time: remind.time
                });
                data.append('linked_reminders[]', reminderData);
            });
        if (linked_homeessential) data.append('linked_homeessential', JSON.stringify(linked_homeessential));
        if (notes) data.append('notes', notes);
        if (project) data.append('project', project);
        if (warranty) data.append('warranty', warranty);
        if (warranty) {
            data.append('warranty_data[][name]', warranty_data[0].name);
            data.append('warranty_data[][number]', warranty_data[1].number);
            data.append('warranty_data[][start_date]', warranty_data[2].start_date);
            data.append('warranty_data[][end_date]', warranty_data[3].end_date);
            data.append('warranty_data[][notes]', warranty_data[4].notes);

            if (files && files.warrantyFiles) {
                files.warrantyFiles.forEach((file) => data.append('warranty_data_files[]', file));
            } else {
                warranty_data.files.forEach((file) => data.append('warranty_data_files[]', file));
            }
        }

        if (files && files.receipt) {
            files?.receipt?.forEach((file) => data.append('receipt[]', file));
        } else {
            receipt_url?.forEach((file) => data.append('receipt[]', file));
        }
        if (files && files.files) {
            files.files?.forEach((file) => data.append('files[]', file));
        } else {
            uploaded_files?.forEach((file) => data.append('files[]', file));
        }

        try {
            const res = await updatePurchaseItem(selectedID, data);
            if (res.status == 200) {
                handlerCancelSideNav();
                await fetchPurchasesAndServicesData();
                toast.success(res.data.message);
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setEditLoading(false);
        }
    };
    const handlerUpdateService = async () => {
        setEditLoading(true);
        const { service_name, amount, files, project, linked_homeessential, linked_reminders, notes, service_date, vendor, receipt_url, uploaded_files, warranty, warranty_data } = serviceDataU;

        const data = new FormData();
        data.append('service_name', service_name);
        data.append('vendor', vendor);
        data.append('service_date', service_date);
        data.append('amount', amount);
        data.append('line_items', JSON.stringify(prodLineItem));
        linked_reminders.forEach((remind) => {
            const reminderData = JSON.stringify({
                id: remind.id,
                date: remind.start_date,
                frequency_id: remind.frequency_id,
                frequency: remind.frequency,
                reminder_frequency: remind.reminder_frequency,
                time: remind.time
            });
            data.append('linked_reminders[]', reminderData);
        });
        data.append('project', project);
        data.append('notes', notes);
        data.append('linked_homeessential', JSON.stringify(linked_homeessential));
        data.append('warranty', warranty);
        if (warranty) {
            data.append('warranty_data[][name]', warranty_data[0].name);
            data.append('warranty_data[][number]', warranty_data[1].number);
            data.append('warranty_data[][start_date]', warranty_data[2].start_date);
            data.append('warranty_data[][end_date]', warranty_data[3].end_date);
            data.append('warranty_data[][notes]', warranty_data[4].notes);
            if (files && files.warrantyFiles) {
                files.warrantyFiles.forEach((file) => data.append('warranty_data_files[]', file));
            } else {
                warranty_data.files.forEach((file) => data.append('warranty_data_files[]', file));
            }
        }

        if (files && files.receipt) {
            files.receipt.forEach((file) => data.append('receipt[]', file));
        } else {
            receipt_url.forEach((file) => data.append('receipt[]', file));
        }
        if (files && files.files) {
            files.files.forEach((file) => data.append('files[]', file));
        } else {
            uploaded_files.forEach((file) => data.append('files[]', file));
        }

        try {
            const res = await updateServiceItem(selectedID, data);
            if (res.status == 200) {
                handlerCancelSideNav();
                await fetchPurchasesAndServicesData();
                toast.success(res.data.message);
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setEditLoading(false);
        }
    };
    function formatString(input) {
        if (!input) return '';
        // Replace hyphen with underscore
        const updatedString = input.replace(/-/g, ' ');

        // Capitalize the first letter
        return updatedString.charAt(0).toUpperCase() + updatedString.slice(1);
    }

    // Map purchase and service data safely
    // Helper to map service data safely
    const mapServiceData = (data) =>
        (data || []).map((item) => ({
            id: item.id,
            itemType: 'service',
            type: (
                <span className="services-badge type-camel">
                    <img src={BroomIcon} alt="Service Icon" /> Service
                </span>
            ),
            name: item.vendor,
            make: item.make || '',
            model: '', // No model number for services
            project: item.project,
            project_name: formatString(item.project_name),
            date: formatDate(item.service_date),
            cost: `$${item.amount}`
        }));

    // Filter function to apply search query
    const filterBySearchQuery = (data, fields, searchQuery) => (data || []).filter((item) => fields.some((field) => (item[field]?.toString().toLowerCase() || '').includes(searchQuery.toLowerCase())));

    // Filter and map purchase data
    const filteredPurchaseData = filterBySearchQuery(purchaseData, ['product_name', 'make', 'model_number', 'project_name'], searchQuery).map((item) => ({
        id: item.id,
        itemType: 'purchase',
        type: (
            <span className="services-badge type-green">
                <img src={lampIcon} alt="Purchase Icon" /> Purchase
            </span>
        ),
        name: item.product_name,
        make: item.make && item.make !== 'undefined' ? item.make : '',
        model: item.model_number && item.model_number !== 'undefined' ? item.model_number : '',
        project: item.property_id,
        project_name: formatString(item.project_name),
        date: formatDate(item.purchase_date),
        cost: `$${item.amount}`
    }));

    // Filter and map service data
    const filteredServiceData = filterBySearchQuery(serviceData, ['vendor', 'make', 'project_name'], searchQuery).map((item) => ({
        id: item.id,
        itemType: 'service',
        type: (
            <span className="services-badge type-camel">
                <img src={BroomIcon} alt="Service Icon" /> Service
            </span>
        ),
        name: item.vendor,
        make: item.make || '',
        model: '', // No model number for services
        project: item.project,
        project_name: formatString(item.project_name),
        date: formatDate(item.service_date),
        cost: `$${item.amount}`
    }));

    // Combine mapped data
    const mappedData = [...filteredPurchaseData, ...filteredServiceData];

    return (
        <div className="PurcheseAndServicesTable">
            <DataTable
                columns={columns}
                data={mappedData} // Use the mapped data here
                defaultSortFieldId={6}
                selectableRows={false}
                onRowClicked={handleRowSideNavOpen} // Attach click event handler
                pagination={true}
                highlightOnHover={true}
            />

            {continueChange === 'viewStep' && (
                <OffCanvasBootstrap
                    placement="end"
                    name="end"
                    show={continueChange === 'viewStep'}
                    handleClose={() => {
                        setContinueChange(false);
                    }}
                >
                    <PurchasesServiceSideView
                        handleClose={() => {
                            setContinueChange(false);
                        }}
                        itemType={itemType}
                        selectedId={selectedID}
                    />
                </OffCanvasBootstrap>
            )}
        </div>
    );
};

export default PurchasesServicesTable;
