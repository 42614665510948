import React, { useMemo } from 'react';
import DataTable, { Alignment, Direction } from 'react-data-table-component';
import { formatDate } from '../../utils/helper';

const Projects = ({ projects, all, pagination }) => {
    const allProjects = useMemo(() => projects?.map((p) => ({ ...p?.meta, ...p })), [projects]);

    const totalAmount = allProjects?.reduce((total, purchase) => {
        // Convert the amount to a number
        const amount = parseFloat(purchase.cost);

        // Check if it's a valid number
        if (!isNaN(amount)) {
            total += amount;
        }

        return total;
    }, 0);

    const renderTags = (tags, tagType) => {
        try {
            const parsedTags = JSON.parse(tags || '[]');
            return (
                <div className="project-tags">
                    {parsedTags?.map((tag, index) => (
                        <span className={`project-tag ${tagType}`} key={index} title={tag.label}>
                            {tag.label}
                        </span>
                    ))}
                </div>
            );
        } catch (error) {
            return <span className="project-tag tag-error">Invalid Data</span>;
        }
    };

    const columnsExpens = [
        {
            name: 'Name',
            selector: (row) => <div dangerouslySetInnerHTML={{ __html: row.title }} />,
            sortable: true,
            reorder: true
        },
        {
            name: 'Property Name',
            selector: (row) => <div dangerouslySetInnerHTML={{ __html: row.project_name }} />,
            sortable: true,
            reorder: true
        },
        {
            name: 'Date',
            selector: (row) => formatDate(row.start_date),
            sortable: true,
            reorder: true
        },
        {
            name: 'Amount',
            selector: (row) => `$${row.cost}`,
            sortable: true,
            reorder: true
        },
        {
            name: 'Linked Home Essentials',
            selector: (row) => renderTags(row.linked_home_essentials, 'tag-red'),
            sortable: false,
            reorder: true
        },
        {
            name: 'Linked Services',
            selector: (row) => renderTags(row.linked_services, 'tag-yellow'),
            sortable: false,
            reorder: true
        },
        {
            name: 'Linked Purchases',
            selector: (row) => renderTags(row.linked_purchases, 'tag-green'),
            sortable: false,
            reorder: true
        }
    ];

    return (
        <div className="col-md-12 mb-3" id="project-cost">
            <label className="reportSectiontitile">Projects</label>
            <div className="recentPurcheseTable projectTableReports">
                <DataTable
                    columns={columnsExpens}
                    data={allProjects || []}
                    defaultSortFieldId={2}
                    selectableRows={false}
                    selectableRowsNoSelectAll={false}
                    selectableRowsVisibleOnly={false}
                    selectableRowsHighlight={false}
                    selectableRowsSingle={false}
                    expandableRows={false}
                    expandOnRowClicked={false}
                    expandOnRowDoubleClicked={false}
                    expandableRowsHideExpander={false}
                    pagination={pagination}
                    highlightOnHover={true}
                    striped={false}
                    pointerOnHover={false}
                    dense={false}
                    persistTableHead={true}
                    noHeader={false}
                    fixedHeader={false}
                    fixedHeaderScrollHeight={'300px'}
                    progressPending={false}
                    noTableHead={false}
                    noContextMenu={false}
                    direction={Direction.AUTO}
                    subHeader={false}
                    subHeaderAlign={Alignment.RIGHT}
                    subHeaderWrap={true}
                    responsive={true}
                    disabled={false}
                    className="proteryReport"
                />
                {!all && <div className="project-table-footer-cost">Total cost: ${totalAmount?.toFixed(2)}</div>}
            </div>
        </div>
    );
};

export default Projects;
