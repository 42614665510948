import React, { useEffect, useState } from 'react';
import { Badge, Card, Container, Table } from 'react-bootstrap';
import { confirmDelete, formatDate } from '../../utils/helper';
import deleteIcon from '../../asstes/dash-icons/deleteIcon.svg';
import { deleteCustomReminder, getCustomReminders } from '../../../axiosCalls/userdashboard';
import Loader from '../loader/Loader';
import AddRemindertModal from '../modals/AddReminderModal';
import { toast } from 'react-toastify';

const ReminderSelector = ({ handleReminderClick, selectedReminders }) => {
    const [customReminders, setCustomReminders] = useState([]);

    const [showForm, setShowForm] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const handleClick = () => {
        setShowForm(!showForm);
    };

    const fetchReminders = async () => {
        try {
            setIsLoading(true);
            const reminders = await getCustomReminders();
            setCustomReminders(reminders?.data);
        } catch (error) {
            console.error('Error fetching reminders:', error);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchReminders();
    }, []);
    return (
        <>
            <div className="add-reminder-container mb-3" onClick={handleClick}>
                <h5>Reminders</h5>
                <h1 className="add-custom-reminder">Add Custom Reminder</h1>
            </div>
            {showForm && <AddRemindertModal handleReminderClick={handleReminderClick} showModal={showForm} handleClose={handleClick} fetchReminders={fetchReminders} />}
            {isLoading ? (
                <h6>
                    <Loader />
                </h6>
            ) : !customReminders.length > 0 ? (
                <h6 className="no-data-found">No Reminders Found</h6>
            ) : (
                <>
                    <Container className="my-4" style={{ maxWidth: '100%' }}>
                        <Card className="shadow-sm">
                            <Card.Header className="bg- text-center">
                                <h5>Reminders</h5>
                            </Card.Header>
                            <Card.Body>
                                <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                                    <Table bordered hover className="text-center align-middle" style={{ minWidth: '600px' }}>
                                        <thead className="table-light">
                                            <tr>
                                                <th>Select</th>
                                                <th>Title</th>
                                                <th>Description</th>
                                                <th>Frequency</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        {customReminders?.length > 0 ? (
                                            <tbody>
                                                {customReminders
                                                    .filter((r) => r.frequency)
                                                    .map((reminder) => (
                                                        <tr key={reminder.id}>
                                                            <td onClick={() => handleReminderClick(reminder)}>
                                                                <input type="checkbox" id={`reminder-${reminder.id}`} checked={selectedReminders.includes(reminder.id)} />
                                                                <label htmlFor={`reminder-${reminder.id}`}></label>
                                                            </td>
                                                            <td>{reminder.title}</td>
                                                            <td>{reminder.description}</td>
                                                            <td>
                                                                <Badge bg="info" className="text-uppercase">
                                                                    {reminder.frequency}
                                                                </Badge>
                                                            </td>
                                                            <td>{formatDate(reminder.date)}</td>
                                                            <td>{reminder.time || 'Not Specified'}</td>
                                                            <td>
                                                                <img
                                                                    onClick={async () => {
                                                                        confirmDelete(async (result) => {
                                                                            if (result.isConfirmed) {
                                                                                try {
                                                                                    const resp = await deleteCustomReminder(reminder.id);
                                                                                    if (resp.status === 200) {
                                                                                        toast.success('Reminder Deleted Successfully');
                                                                                        fetchReminders();
                                                                                    }
                                                                                } catch (error) {
                                                                                    toast.error('Error deleting reminder');
                                                                                }
                                                                            }
                                                                        });
                                                                    }}
                                                                    src={deleteIcon}
                                                                    alt="delete icon"
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        ) : (
                                            <div className="my-3 text-center">No Reminder Found</div>
                                        )}
                                    </Table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Container>
                </>
            )}
        </>
    );
};

export default ReminderSelector;
