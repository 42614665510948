import React, { useEffect, useState } from 'react';
import '../../asstes/styles/notificationPopup.css';
import dubleCheckMark from '../../asstes/dash-icons/dubleCheckMark.svg';
import { deleteNotificationsById, get_all_event_lists, get_all_event_lists_by_property_id, get_current_week_event_lists_by_property_id, get_event_lists, mark_all_as_read_by_property, update_event_by_id, updateNotificationsStatusById } from '../../../axiosCalls/userdashboard'; // Import API
import { Badge, Button, Dropdown, DropdownButton, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { confirmDelete, formatFrequencyString } from '../../utils/helper';
import { toast } from 'react-toastify';
import Loader from '../loader/Loader';
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import * as types from '../../../redux/actions/actionTypes';
import { startOfDay, isSameDay, addDays, format } from 'date-fns';

const NotificationPopup = ({ handlerColseNotification, selectedPropertyForEvents, fetchAllPropertiesNotifications }) => {
    const dispatch = useDispatch();
    const [notifications, setNotifications] = useState([]);
    const [notificationHide, setNotificationHide] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [filterOption, setFilterOption] = useState('currentWeek');
    const propertyName = selectedPropertyForEvents?.property;
    const propertyId = selectedPropertyForEvents?.property_id;

    const fetchEvents = async () => {
        try {
            setIsLoading(true);
            let res;

            switch (filterOption) {
                case 'all':
                    res = await get_all_event_lists_by_property_id(propertyId);
                    break;
                case 'currentWeek':
                    res = await get_current_week_event_lists_by_property_id(propertyId);
                    break;
                case 'pending':
                    res = await get_all_event_lists_by_property_id(propertyId); // Assuming pending API
                    break;
                default:
                    throw new Error('Invalid filter option');
            }

            const eventData = res.data;

            const transformedNotifications = eventData
                .map((event) => ({
                    ...event,
                    date: new Date(event.date), // Ensure date is an object
                    read: event.read || false // Initialize read state
                }))
                .sort((a, b) => b.date - a.date); // Sort by date (descending)

            setNotifications(filterOption === 'all' ? transformedNotifications.filter((n) => n.status_task !== 'completed') : transformedNotifications.filter((n) => !n.status_task));
        } catch (error) {
            console.error('Failed to fetch events:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (propertyId) fetchEvents();
    }, [filterOption, propertyId]);

    // Function to mark a single notification as read
    const handleNotificationClick = async (id) => {
        try {
            setNotifications((prevNotifications) => prevNotifications.map((notification) => (notification.ID === id ? { ...notification, read: true } : notification)));

            const updateData = { read: true };
            const res = await update_event_by_id(id, updateData);
            if (res.status !== 200) {
                console.error('Failed to update notification status');
            }
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };

    // Function to mark all notifications as read
    const handleMarkAllAsRead = async () => {
        try {
            const resp = await mark_all_as_read_by_property(propertyId);
            if (resp.status === 200) {
                toast.success(resp.data.message);
                dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetAllReminderEvents', data: true } });
                fetchEvents();
                if (fetchAllPropertiesNotifications) fetchAllPropertiesNotifications();
            } else {
                toast.error('Something went wrong');
            }
        } catch (error) {
            console.error('Failed to mark all notifications as read:', error);
        }
    };

    // Function to format the date
    const formatDate = (date) => {
        // Normalize today's date to start of day
        const today = startOfDay(new Date());
        const yesterday = startOfDay(addDays(today, -1));
        const tomorrow = startOfDay(addDays(today, 1));
        const inputDate = startOfDay(date); // Normalize the input date to start of day

        // Compare the input date with today, yesterday, and tomorrow
        if (isSameDay(inputDate, today)) return 'Today';
        if (isSameDay(inputDate, yesterday)) return 'Yesterday';
        if (isSameDay(inputDate, tomorrow)) return 'Tomorrow';

        // Fallback to a formatted string for other dates
        return format(inputDate, 'EEEE, MMMM d, yyyy'); // Example: "Wednesday, January 2, 2025"
    };

    // Group notifications by formatted date
    const groupedNotifications = notifications.reduce((acc, notification) => {
        const formattedDate = formatDate(notification.date);
        if (!acc[formattedDate]) {
            acc[formattedDate] = [];
        }
        acc[formattedDate].push(notification);
        return acc;
    }, {});

    const handleNotificationClose = () => {
        setNotificationHide(true);
        handlerColseNotification(false);
    };

    // Utility function to truncate the description to a specific word count
    const truncateDescription = (text, wordLimit = 15) => {
        const words = text.split(' ');
        if (words.length <= wordLimit) return text;

        return words.slice(0, wordLimit).join(' ') + '...';
    };
    const [deleteLoading, setDeleteLoading] = useState(false);

    const handleSelect = async (notification, option) => {
        try {
            if (option === 'delete') {
                confirmDelete(async (result) => {
                    if (result.isConfirmed) {
                        const resp = await deleteNotificationsById(notification?.ID);
                        if (resp.status === 200) {
                            toast.success(resp.data.message);
                            dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetAllReminderEvents', data: true } });

                            fetchEvents();
                            if (fetchAllPropertiesNotifications) fetchAllPropertiesNotifications();
                        } else {
                            toast.error('Something went wrong');
                        }
                    }
                });
            } else {
                const resp = await updateNotificationsStatusById(notification.ID, {
                    status_task: option
                });
                if (resp.status === 200) {
                    toast.success(resp.data.message);
                    fetchEvents();
                    if (fetchAllPropertiesNotifications) fetchAllPropertiesNotifications();
                    dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetAllReminderEvents', data: true } });
                } else {
                    toast.error('Something went wrong');
                }
            }
        } catch (error) {
            toast.error('Something went wrong');
        }
    };

    const badgeColors = {
        completed: 'success',
        snooze: 'info',
        dismissed: 'danger'
    };

    return (
        <>
            {!notificationHide && (
                <>
                    <div className="sidenav-overlay" onClick={handleNotificationClose} style={{ left: '0px' }}></div>

                    <div className="notification-popup">
                        <div className="notification-close" onClick={handleNotificationClose}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M21.0612 18.9387C21.343 19.2205 21.5013 19.6027 21.5013 20.0012C21.5013 20.3997 21.343 20.7819 21.0612 21.0637C20.7794 21.3455 20.3972 21.5038 19.9987 21.5038C19.6002 21.5038 19.218 21.3455 18.9362 21.0637L10.9999 13.125L3.0612 21.0612C2.7794 21.343 2.39721 21.5013 1.9987 21.5013C1.60018 21.5013 1.21799 21.343 0.936196 21.0612C0.654403 20.7794 0.496094 20.3972 0.496094 19.9987C0.496094 19.6002 0.654403 19.218 0.936196 18.9362L8.87495 11L0.938695 3.06122C0.656903 2.77943 0.498594 2.39724 0.498594 1.99872C0.498594 1.60021 0.656903 1.21802 0.938695 0.936225C1.22049 0.654432 1.60268 0.496123 2.0012 0.496123C2.39971 0.496123 2.7819 0.654432 3.0637 0.936225L10.9999 8.87498L18.9387 0.934975C19.2205 0.653182 19.6027 0.494873 20.0012 0.494873C20.3997 0.494873 20.7819 0.653182 21.0637 0.934975C21.3455 1.21677 21.5038 1.59896 21.5038 1.99747C21.5038 2.39599 21.3455 2.77818 21.0637 3.05998L13.1249 11L21.0612 18.9387Z"
                                    fill="#00487C"
                                />
                            </svg>
                        </div>
                        <div className="notification-header">
                            <div className="notification-title">Notifications</div>
                            {/* <div className="notification-button" onClick={handleMarkAllAsRead}>
                                <img src={dubleCheckMark} alt="Mark all as read" />
                                Mark all as read
                            </div> */}
                            {/* delete all notification */}
                            {/* <div
                                className="notification-button"
                                onClick={async () => {
                                    try {
                                        if (deleteLoading) {
                                            return;
                                        }
                                        confirmDelete(async (result) => {
                                            if (result.isConfirmed) {
                                                setDeleteLoading(true);
                                                const resp = await deleteAllNotificationsByProperty();
                                                if (resp?.property_id) {
                                                    // toast
                                                    toast.success(resp?.message);
                                                    await fetchEvents();
                                                }
                                            }
                                        });
                                    } catch (error) {
                                        toast.error('Failed to delete all notifications');
                                    } finally {
                                        setDeleteLoading(false);
                                    }
                                }}
                            >
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={(props) => (
                                        <Tooltip id="button-tooltip" {...props}>
                                            Delete all notifications for current active property
                                        </Tooltip>
                                    )}
                                >
                                    {deleteLoading ? <div>Loading...</div> : <div>Delete All</div>}
                                </OverlayTrigger>
                            </div> */}
                        </div>
                        <div>
                            <strong>{propertyName}</strong>
                            <Badge className="ms-2" bg="danger">
                                {isLoading ? 'Loading...' : notifications?.length}
                            </Badge>
                        </div>
                        <div>
                            {/* add radio button for current week events and all events */}
                            <div className="d-flex my-3 gap-3">
                                <Form.Group>
                                    <input className="form-check-input" type="radio" name="filterOptions" id="all" value="all" checked={filterOption === 'all'} onChange={() => setFilterOption('all')} />
                                    <label className="form-check-label ms-2" htmlFor="all">
                                        All
                                    </label>
                                </Form.Group>
                                <Form.Group>
                                    <input className="form-check-input" type="radio" name="filterOptions" id="currentWeek" value="currentWeek" checked={filterOption === 'currentWeek'} onChange={() => setFilterOption('currentWeek')} />
                                    <label className="form-check-label ms-2" htmlFor="currentWeek">
                                        Current Week
                                    </label>
                                </Form.Group>
                                <Form.Group>
                                    <input className="form-check-input" type="radio" name="filterOptions" id="pending" value="pending" checked={filterOption === 'pending'} onChange={() => setFilterOption('pending')} />
                                    <label className="form-check-label ms-2" htmlFor="pending">
                                        Past Due
                                    </label>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="notification-list mt-3">
                            {isLoading ? (
                                <Loader />
                            ) : Object.entries(groupedNotifications)?.length > 0 ? (
                                Object.entries(groupedNotifications).map(([date, notifications]) => (
                                    <div key={date}>
                                        <div className="notifyItem-date">{date}</div>
                                        {notifications.map((notification) => (
                                            <div className=" d-flex justify-content-between">
                                                <div className={`notification-item ${notification.read ? 'read' : 'unread'}`} key={notification.ID} onClick={() => handleNotificationClick(notification.ID)}>
                                                    <div className="notifyItem-title">
                                                        <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="2.5" cy="3" r="2.5" fill={notification.read ? '#4CAF50' : '#BE6060'} />
                                                        </svg>
                                                        {/* render html for title */}
                                                        <div dangerouslySetInnerHTML={{ __html: notification.title }} />
                                                        {notification?.status_task && notification?.status_task !== 'single-delete' ? <Badge bg={badgeColors[notification?.status_task]}>{notification?.status_task}</Badge> : <Badge>in-progress</Badge>}
                                                    </div>
                                                    {/* show reminder_frequency */}
                                                    {/* <div className="notifyItem-content">
                                                        <Badge bg="warning">{formatFrequencyString(notification.reminder_frequency)}</Badge>
                                                    </div> */}
                                                    <div className="notifyItem-content">
                                                        {/* // render html for description */}
                                                        <div dangerouslySetInnerHTML={{ __html: truncateDescription(notification.description) }} />
                                                    </div>
                                                </div>

                                                <div>
                                                    <Dropdown>
                                                        <Dropdown.Toggle as="button" className="p-0 border-0 bg-transparent custom-dropdown-toggle">
                                                            <ThreeDotsVertical size={20} />
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            {!notification?.status_task && (
                                                                <>
                                                                    <Dropdown.Item onClick={() => handleSelect(notification, 'completed')}>Complete</Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => handleSelect(notification, 'snooze')}>Snooze</Dropdown.Item>
                                                                </>
                                                            )}

                                                            {notification?.status_task === 'snooze' && <Dropdown.Item onClick={() => handleSelect(notification, 'completed')}>Complete</Dropdown.Item>}
                                                            <Dropdown.Item onClick={() => handleSelect(notification, 'single-delete')}>Delete</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => handleSelect(notification, 'delete')}>Delete Series</Dropdown.Item>
                                                            {/* <Dropdown.Item onClick={() => handleSelect(notification, 'dismissed')}>Dismiss</Dropdown.Item> */}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))
                            ) : (
                                <div className="text-center">No Data Found</div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default NotificationPopup;
