import React, { useState, useEffect } from 'react';
import '../../asstes/styles/reminderSideNav.css';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Accordion, Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import plusIcon from '../../asstes/dash-icons/plusIcon.svg';
import MagnifyingGlass from '../../asstes/dash-icons/sidebar/MagnifyingGlass.svg';
import { addCustomReminder, addNotificationData, fetchHomeEssentials, getCustomReminders, getSuggestedReminders, listOfFrequencies, update_event_by_id, updateCustomReminder } from '../../../axiosCalls/userdashboard';
import Loader from '../loader/Loader';
import { Select } from './Select';
import { toast } from 'react-toastify';
import ScrollToError from '../input/ScrollToError';
import { useDispatch } from 'react-redux';
import * as types from '../../../redux/actions/actionTypes';

const ReminderSideNav = ({ reminder }) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [suggestedReminders, setSuggestedReminders] = useState([]);
    const [customReminders, setCustomReminders] = useState([]);
    const [frequencies, setFrequencies] = useState([]);
    const [homeEssentials, setHomeEssentials] = useState([]);

    const [selectedReminder, setSelectedReminder] = useState(null);
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [user, setUser] = useState(null);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);

    const fetchReminders = async () => {
        try {
            setLoader(true);
            const reminders = await getSuggestedReminders();
            const customReminderRes = await getCustomReminders();
            setSuggestedReminders(reminders.data);
            setCustomReminders(customReminderRes.data);
        } catch (error) {
            console.error('Error fetching reminders:', error);
        } finally {
            setLoader(false);
        }
    };

    const fetchFrequencies = async () => {
        try {
            setLoader(true);
            const response = await listOfFrequencies();
            setFrequencies(response.data);
        } catch (error) {
            console.error('Error fetching frequencies:', error);
        } finally {
            setLoader(false);
        }
    };
    const nullableValues = [null, 'null', undefined, 'undefined'];
    const fetchEssentials = async () => {
        try {
            setLoader(true);
            const response = await fetchHomeEssentials();
            const optionsData =
                response?.data?.items?.map((item) => ({
                    label: `${item.type} - ${item.model_number}`,
                    value: item.id
                })) || [];
            setHomeEssentials(optionsData?.filter((o) => !nullableValues.includes(o.label) && !nullableValues.includes(o.value)));
        } catch (error) {
            console.error('Error fetching home essentials:', error);
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        fetchEssentials();
        fetchReminders();
        fetchFrequencies();
    }, []);

    const defaultTime = user?.meta_data?.default_reminder_time;
    const handleReminderClick = (reminder) => {
        setSelectedReminder({
            ...reminder,
            frequency: reminder?.frequency_id,
            time: defaultTime || reminder.time
        });
        setOpen(true);
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const selectFrequency = frequencies.find((f) => Number(f.id) === Number(values.frequency));
            const reminderData = {
                title: values.title,
                description: values.description,
                frequency: selectFrequency?.name,
                reminder_frequency: selectFrequency?.reminder_frequency,
                date: values.date,
                time: values.time,
                linked_home_essentials: values.linkedHomeEssentials || []
            };
            const responseCustomReminder = reminder ? await update_event_by_id(values?.ID, { ...reminderData }) : await addCustomReminder(reminderData);
            const response = reminder ? null : await addNotificationData(reminderData, responseCustomReminder.data.post_id);
            if (response && response.data) {
                if (response.data.status === 400) {
                    toast.error(response.message);
                } else {
                    fetchReminders();
                    dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetAllReminderEvents', data: true } });
                    dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetSearchResults', data: true } });
                    toast.success(response.data.message);
                    setOpen(false);
                }
            }
            if (reminder) {
                if (responseCustomReminder && responseCustomReminder.data) {
                    if (responseCustomReminder.data.status === 400) {
                        toast.error(responseCustomReminder.message);
                    } else {
                        fetchReminders();
                        dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetAllReminderEvents', data: true } });
                        toast.success(responseCustomReminder.data.message);
                        setOpen(false);
                    }
                }
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        if (reminder) {
            setOpen(true);
            const selectFrequency = frequencies.find((f) => f.name === reminder.frequency);
            setSelectedReminder({ ...reminder, reminder_frequency: selectFrequency?.reminder_frequency, frequency: selectFrequency?.id, date: reminder.date, time: reminder.time, linkedHomeEssentials: reminder.linked_home_essentials });
        } else {
            setOpen(false);
            setSelectedReminder(null);
        }
    }, [reminder, frequencies]);

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        // description: Yup.string().required('Description is required'),
        frequency: Yup.string().required('Frequency is required'),
        date: Yup.date().required('Start date is required')
        // time: Yup.string().required('Time is required')
    });

    const filteredCustomReminders = customReminders?.filter((reminder) => reminder.title.toLowerCase().includes(searchTerm.toLowerCase())).slice(0, 5);

    const filteredSuggestedReminders = suggestedReminders?.filter((reminder) => reminder.title.toLowerCase().includes(searchTerm.toLowerCase()));
    if (loader) {
        return <Loader />;
    }
    return (
        <>
            <div className="side-header">
                <h3>{reminder ? 'Edit Reminder' : 'Add Reminders'}</h3>
                <h5>Search for a Reminder or select one or more reminders to add to your calendar</h5>
                <Form>
                    <div className="create-reminder-parent">
                        {!open && (
                            <>
                                <InputGroup className="search-field">
                                    <InputGroup.Text id="basic-addon1">
                                        <img src={MagnifyingGlass} alt="Search" />
                                    </InputGroup.Text>
                                    <Form.Control type="text" placeholder="Search Reminder" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                                </InputGroup>
                                <div
                                    className="button uploadFile"
                                    onClick={() => {
                                        setOpen(true);
                                        setSelectedReminder(null);
                                    }}
                                >
                                    <div className="outline-button-upload createReminders">
                                        <img src={plusIcon} alt="Add Custom Reminder" />
                                        Custom reminder
                                    </div>
                                </div>
                            </>
                        )}

                        {!open && (
                            <Accordion className="mt-4" color="success" flush defaultActiveKey="0">
                                <Accordion.Item className="my-2" eventKey="0">
                                    <Accordion.Header>
                                        <strong className="ms-2">Suggested Reminders</strong>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row className="w-100 m-0">
                                            <Col md={12}>
                                                {loader ? (
                                                    <Loader size="md" />
                                                ) : (
                                                    <>
                                                        {filteredSuggestedReminders?.map((item) => (
                                                            <div className="suggetes-item" key={item.id} onClick={() => handleReminderClick(item)}>
                                                                <div className="titleSuggestItem">{item.title}</div>
                                                                <div>
                                                                    <img src={plusIcon} alt="Add" />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                )}
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" className="my-2">
                                    <Accordion.Header>
                                        <strong className="ms-2">Custom Reminders</strong>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row className="w-100 m-0">
                                            <Col md={12}>
                                                {loader && <Loader size="md" />}
                                                {!open &&
                                                    !loader &&
                                                    filteredCustomReminders?.map((item) => (
                                                        <div className="suggetes-item" key={item.id} onClick={() => handleReminderClick(item)}>
                                                            <div className="titleSuggestItem">{item.title}</div>
                                                            <div>
                                                                <img src={plusIcon} alt="Add" />
                                                            </div>
                                                        </div>
                                                    ))}
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        )}
                    </div>
                </Form>
            </div>

            {open && !loader && (
                <Formik
                    initialValues={{
                        ...selectedReminder,
                        title: selectedReminder?.title || '',
                        description: selectedReminder?.description || '',
                        frequency: selectedReminder?.frequency || '',
                        date: selectedReminder?.date || '',
                        time: selectedReminder?.time || defaultTime || '19:00',
                        linkedHomeEssentials: selectedReminder?.linked_home_essentials || []
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({ isSubmitting, errors, values, touched, handleChange, setFieldValue }) => (
                        <FormikForm>
                            <ScrollToError />
                            <div className="side-body">
                                <h1 className="side-nav-main-heading">{reminder ? 'Edit' : 'Add'} Reminder Details</h1>
                                <div className="inner-body">
                                    <Form.Group controlId="formTitle">
                                        <Form.Label>
                                            Title
                                            <span className="requiredStar">*</span>
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="Name" name="title" value={values.title} onChange={handleChange} isInvalid={touched.title && errors.title} />
                                        <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group controlId="formDescription">
                                        <Form.Label>Description</Form.Label>
                                        <Field className="form-control" name="description" as="textarea" rows={3} placeholder="Enter Description" />
                                    </Form.Group>

                                    <Form.Group controlId="formFrequency">
                                        <Form.Label>
                                            Frequency<span className="requiredStar">*</span>
                                        </Form.Label>

                                        <Form.Select name="frequency" value={values.frequency} onChange={handleChange} isInvalid={touched.frequency && errors.frequency}>
                                            <option value="">Select Frequency</option>
                                            {frequencies?.map((type) => (
                                                <option key={type.id} value={type.id}>
                                                    {type.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group controlId="formDate">
                                        <Form.Label>
                                            Reminder Start Date <span className="requiredStar">*</span>
                                        </Form.Label>
                                        <Form.Control type="date" name="date" value={values.date} onChange={handleChange} isInvalid={touched.date && errors.date} />
                                        <Form.Control.Feedback type="invalid">{errors.date}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="formTime">
                                        <Form.Label>Time</Form.Label>
                                        <Field className="form-control" name="time" type="time" />
                                        <ErrorMessage name="time" component="div" className="error" />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Linked Home Essentials</Form.Label>
                                        <Select multiple options={homeEssentials} value={values.linkedHomeEssentials} onChange={(selected) => setFieldValue('linkedHomeEssentials', selected)} />
                                    </Form.Group>
                                </div>
                                <div className="off-canvas-buttons form-actions d-flex justify-content-between gap-2">
                                    <div className="d-flex gap-2">
                                        <Button
                                            className="button-cancel"
                                            onClick={() => {
                                                setSelectedReminder(null);
                                                setOpen(false);
                                            }}
                                            disabled={isSubmitting}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            onClick={() => {
                                                setOpen(false);
                                                setSelectedReminder(null);
                                            }}
                                        >
                                            Back
                                        </Button>
                                    </div>
                                    <Button type="submit" className="button-primary" disabled={isSubmitting}>
                                        {isSubmitting ? <Loader size="sm" /> : reminder ? 'Update' : 'Save'}
                                    </Button>
                                </div>
                            </div>
                        </FormikForm>
                    )}
                </Formik>
            )}
        </>
    );
};

export default ReminderSideNav;
