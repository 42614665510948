import React, { useEffect, useState } from 'react';
import { get_user_Profile, verify_role_terms } from '../../axiosCalls/userdashboard';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const RoleSelectionAndTerms = () => {
    const navigate = useNavigate();
    const [selectedRole, setSelectedRole] = useState(null);
    const [privacyChecked, setPrivacyChecked] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = async () => {
        try {
            if (!selectedRole) {
                setError('Please select a role.');
                return;
            }
            if (selectedRole === 'homeowner_affiliate' && (!privacyChecked || !termsChecked)) {
                setError('Please agree to the privacy policy and terms.');
                return;
            }
            if (selectedRole === 'homeowner' && !privacyChecked) {
                setError('Please agree to the privacy policy.');
                return;
            }
            if (selectedRole === 'affiliate' && !termsChecked) {
                setError('Please agree to the terms.');
                return;
            }
            if (selectedRole === 'homeowner_affiliate' && !privacyChecked && !termsChecked) {
                setError('Please agree to the privacy policy and terms.');
                return;
            }
            if (selectedRole === 'homeowner_affiliate' && privacyChecked && termsChecked) {
                setError(null);
            }
            if (selectedRole === 'homeowner' && privacyChecked) {
                setError(null);
            } else if (selectedRole === 'affiliate' && termsChecked) {
                setError(null);
            }
            // Proceed with the next step
            setError(null);
            setIsLoading(true);
            const resp = await verify_role_terms({
                role: selectedRole,
                terms_status: 'verified'
            });
            if (resp.status === 200) {
                const _resp = await get_user_Profile();
                window.location.reload();
                const user = _resp.data;
                let redirectUrl = '/dashboard'; // Default redirect URL
                if (user.role === 'homeowner') {
                    redirectUrl = '/dashboard';
                } else if (user.role === 'affiliate') {
                    redirectUrl = '/seller/overview';
                } else if (user.role === 'homeowner_affiliate') {
                    redirectUrl = '/dashboard';
                }
                navigate(redirectUrl);
            }
        } catch (error) {
            toast.error('Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };
    // setting error null if all states ok
    useEffect(() => {
        // handle selectedRole error if error for selected role and its selected then setting to null
        if (selectedRole && error && error.includes('role')) {
            setError(null);
        }
        // handle privacyChecked error if error for privacyChecked and its selected then setting to null
        if (privacyChecked && error && error.includes('privacy')) {
            setError(null);
        }
        if (selectedRole && privacyChecked && termsChecked) {
            setError(null);
        }
        // handle termsChecked error if error for termsChecked and its selected then setting to null
        if (termsChecked && error && error.includes('terms')) {
            setError(null);
        }
        if (selectedRole === 'homeowner_affiliate' && privacyChecked && termsChecked) {
            setError(null);
        }
        if (selectedRole === 'homeowner' && privacyChecked) {
            setError(null);
        } else if (selectedRole === 'affiliate' && termsChecked) {
            setError(null);
        }
    }, [selectedRole, privacyChecked, termsChecked]);

    return (
        <div className="auth-form-light text-left py5 px-4">
            <div className="brand-logo">
                <h2 className="auth-title">Role Selection</h2>
            </div>

            <div className="role-selection">
                <label className={selectedRole === 'homeowner' ? 'select-role-section-active select-role-section' : 'select-role-section'} htmlFor="Homeowner">
                    <div className="text-role-selection">Homeowner</div>
                    <div className="radio-role-selection">
                        <input type="radio" id="Homeowner" value="homeowner" onChange={(e) => setSelectedRole(e.target.value)} className="role-radio-btn" name="role_choose" />
                    </div>
                </label>

                <label className={selectedRole === 'affiliate' ? 'select-role-section-active select-role-section' : 'select-role-section'} htmlFor="Affiliate">
                    <div className="text-role-selection">Affiliate</div>
                    <div className="radio-role-selection">
                        <input type="radio" id="Affiliate" value="affiliate" onChange={(e) => setSelectedRole(e.target.value)} className="role-radio-btn" name="role_choose" />
                    </div>
                </label>

                <label className={selectedRole === 'homeowner_affiliate' ? 'select-role-section-active select-role-section' : 'select-role-section'} htmlFor="AffiliateHomeowner">
                    <div className="text-role-selection">Homeowner + Affiliate</div>
                    <div className="radio-role-selection">
                        <input type="radio" id="AffiliateHomeowner" value="homeowner_affiliate" onChange={(e) => setSelectedRole(e.target.value)} className="role-radio-btn" name="role_choose" />
                    </div>
                </label>

                <div className="checkbox-section">
                    {(selectedRole === 'homeowner_affiliate' || selectedRole === 'homeowner') && (
                        <div className="col-12">
                            <div className="form-check check-me">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="agreeTerms"
                                    name="agreeTerms"
                                    checked={privacyChecked}
                                    onChange={(e) => {
                                        setPrivacyChecked(e.target.checked);
                                    }}
                                    required
                                />
                                <label className="form-check-label" htmlFor="agreeTerms">
                                    I have read and agree to remind Home Solutions'{' '}
                                    <Link target="_blank" to={`${process.env.REACT_APP_SITE_URL}/terms-and-conditions`}>
                                        Terms and Conditions
                                    </Link>{' '}
                                    and acknowledge the{' '}
                                    <Link target="_blank" to={`${process.env.REACT_APP_SITE_URL}/privacy-policy`}>
                                        Privacy Policy
                                    </Link>
                                    .
                                </label>
                            </div>
                        </div>
                    )}

                    {(selectedRole === 'homeowner_affiliate' || selectedRole === 'affiliate') && (
                        <div className="col-12">
                            <div className="form-check check-me">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="agreeReferral"
                                    name="agreeReferral"
                                    checked={termsChecked}
                                    onChange={(e) => {
                                        setTermsChecked(e.target.checked);
                                    }}
                                    required
                                />
                                <label className="form-check-label" htmlFor="agreeReferral">
                                    I have read and agree to the{' '}
                                    <Link target="_blank" to={`${process.env.REACT_APP_SITE_URL}/affiliate-agreement`}>
                                        Affiliate Agreement{' '}
                                    </Link>{' '}
                                    Terms and Conditions
                                </label>
                            </div>
                        </div>
                    )}
                </div>

                {error && <div className="error-message">{error}</div>}

                <div className="auth-footer">
                    <button type="button" className="auth-success-btn" onClick={handleSubmit} name="doneButton">
                        {isLoading ? 'Loading...' : 'Done'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RoleSelectionAndTerms;
