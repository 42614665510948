export function extractNameAndPrice(receiptItems) {
    const extractedItems = receiptItems.map((item) => {
        const fields = item.LineItemExpenseFields;

        // Extract the ITEM and PRICE fields
        const nameField = fields.find((field) => field.Type.Text === 'ITEM');
        const priceField = fields.find((field) => field.Type.Text === 'PRICE');

        // Return the name and price as an object if both are found
        return {
            name: nameField ? nameField.ValueDetection.Text : 'Unknown Item',
            price: priceField ? parseFloat(priceField.ValueDetection.Text) : 0.0
        };
    });

    return extractedItems;
}

export const convertToISODate = (dateString) => {
    if (!dateString) return null;
    const [month, day, year] = dateString.split('/');
    // Check if month, day, or year is missing
    if (!month || !day || !year) {
        return null; // Return null for invalid date formats
    }
    // Determine if the year is two or four digits
    const fullYear = year.length === 2 ? `20${year}` : year;
    return `${fullYear}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};
