import React, { useEffect, useState } from 'react';
import eyeIcon from '../../user-dashboard/asstes/dash-icons/eyeIcon.svg';
import editIcon from '../assets/icons/edit-icon.svg';
import BellSimple from '../assets/icons/BellSimple.svg';
import deleteIcon from '../../user-dashboard/asstes/dash-icons/deleteIcon.svg';
import DataTable from 'react-data-table-component';
import { formatDate } from '../../user-dashboard/utils/helper';

const MyNetworkTable = ({ data }) => {
    const [networkData, setNetworkData] = useState([]);

    // Process the data when component mounts or when `data` prop changes
    useEffect(() => {
        const fetchNetworkData = () => {
            if (!Array.isArray(data)) {
                console.error('Invalid data format. Expected an array.');
                setNetworkData([]); // Set empty data if prop is not valid
                return;
            }

            // Format the data
            const formattedData = data.map((item) => ({
                id: 1,
                item_id: item.id,
                FirstName: item.homeowner_first_name,
                LastName: item.homeowner_last_name,
                UserCreationDate: formatDate(item.signup_date) || 'N/A',
                SubscriptionRenewal: formatDate(item.renewal_date) || 'N/A',
                remaining_time_to_claim: item?.remaining_time_to_claim,
                email_address: item?.homeowner_email,

                Type: item.type, // Store type for future use (optional)
                REmindUsers: (
                    <div className="fileName bellIcon">
                        <img src={BellSimple} alt="Bell" /> Remind
                    </div>
                ),
                Action: (
                    <div className="actions">
                        <span>
                            <img src={eyeIcon} alt="View" />
                        </span>
                        <span>
                            <img src={editIcon} alt="Edit" />
                        </span>
                        <span>
                            <img src={deleteIcon} alt="Delete" />
                        </span>
                    </div>
                )
            }));

            setNetworkData(formattedData); // Update state with formatted data
        };

        fetchNetworkData();
    }, [data]); // Runs every time `data` changes

    // Define columns for DataTable
    const columns = [
        {
            name: 'First Name',
            selector: (row) => <div className="fileName">{row.FirstName}</div>,
            sortable: true
        },
        {
            name: 'Last Name',
            selector: (row) => <div className="fileName">{row.LastName}</div>,
            sortable: true
        },
        {
            name: 'Email',
            selector: (row) => <div className="fileName">{row.email_address}</div>,
            sortable: true
        },
        {
            name: 'Account Sign Up Date',
            selector: (row) => <div className="fileName">{row.UserCreationDate}</div>,
            sortable: true
        },
        {
            name: 'Renewal Date',
            selector: (row) => <div className="fileName">{row.SubscriptionRenewal}</div>,
            sortable: true
        },
        {
            name: 'Remaining Time to Claim Your Royalty',
            selector: (row) => <div className="fileName">{row.remaining_time_to_claim ? `${row.remaining_time_to_claim} days` : 'N/A'}</div>,
            sortable: true
        }

        // {
        //     name: 'Remind Users',
        //     selector: row => row.REmindUsers,
        //     sortable: false,
        // },
        // {
        //     name: 'Action',
        //     selector: row => row.Action,
        //     sortable: false,
        // },
    ];

    return (
        <div className="recentFilesTable recentEarning">
            <DataTable columns={columns} data={networkData} defaultSortFieldId={1} pagination highlightOnHover persistTableHead responsive />
        </div>
    );
};

export default MyNetworkTable;
