import React, { useEffect, useState } from 'react';
import sellerMenuItem from './menu-item';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import giftIcon from '../assets/icons/gift.svg';
import logoSeller from '../assets/logo.png';
import mobiledownloaddesign from '../../user-dashboard/asstes/images/mobiledownloaddesign.svg';
import { fetchWalletBalance } from '../../axiosCalls/seller';
import DownloadSteps from '../../user-dashboard/components/download_steps/DownloadSteps';

const SellerSiderbar = ({ stateSide, handlerCloseSidebarFromClick }) => {
    const location = useLocation();
    const [activeItem, setActiveItem] = useState('');
    const navigate = useNavigate(); // Initialize navigate function
    const [balance, setBalance] = useState(0);
    const [amount, setAmount] = useState('');

    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        // Fetch wallet balance on component load
        const walletBalance = async () => {
            const res = await fetchWalletBalance();
            setBalance(res.data.balance);
        };

        walletBalance();
    }, []);

    const handleItemClick = (id) => {
        handlerCloseSidebarFromClick('close');
        document.body.classList.remove('no-scroll');
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        if (id === 'logout') {
            // Clear the authentication token or any other user data
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('selectedProperty');

            // Redirect to the login page or another appropriate page
            navigate('/login');
        } else {
            setActiveItem(id);
        }
    };

    const NavMenu_2 = sellerMenuItem.children_2.map((item) => {
        const isActive = activeItem === item.id;
        const activeClassName = isActive ? 'active' : '';

        return (
            <li className={`nav-item ${activeClassName}`} id={item.id} key={item.id}>
                <Link className="nav-link" to={item.url} onClick={() => handleItemClick(item.id)}>
                    <span className="nav-icon" dangerouslySetInnerHTML={{ __html: item.icon }} />
                    <span className="menu-title">{item.title}</span>
                </Link>
            </li>
        );
    });

    const NavMenu_3 = sellerMenuItem.children_3.map((item) => {
        const isActive = activeItem === item.id;
        const activeClassName = isActive ? 'active' : '';

        return (
            <li className={`nav-item ${activeClassName}`} id={item.id} key={item.id}>
                <Link className={item.id === 'logout' ? 'nav-link logoutIcon' : 'nav-link'} to={item.url} onClick={() => handleItemClick(item.id)}>
                    <span className="nav-icon" dangerouslySetInnerHTML={{ __html: item.icon }} />
                    <span className="menu-title">{item.title}</span>
                </Link>
            </li>
        );
    });

    return (
        <>
            <nav className={`sidebar sidebar-offcanvas ${stateSide === 'open' ? 'active' : ''}`} id="sidebar">
                <div className="sidebar-inner">
                    <ul className="seller nav choose-property">
                        <div className="choose-property-img">
                            <img src={logoSeller} alt="lo" />
                        </div>
                        <div className="choose-property-title">Remind Home</div>
                    </ul>
                    <div className="amount-widget account-balance" id="account-balance">
                        <div className="account-balance-widget">
                            <div className="account-balance-title">Account Balance</div>
                            <div className="account-balance-amount">${balance}</div>
                            {/* <div className="account-balance-btn">
                                <img src={giftIcon} alt="GiftIcon" />
                                Withdraw Balance
                            </div> */}
                        </div>
                    </div>

                    {/* <div className='nav seller-program-date'>
            <span className='seller-program-date-title'>Affiliate Program Start Date:</span>
            <span className='seller-program-date-date'>23rd Feb 2024</span>
          </div> */}

                    <div className="nav-divider"></div>
                    <ul className="nav">{NavMenu_2}</ul>
                    <div className="nav-divider"></div>

                    <ul className="nav">{NavMenu_3}</ul>
                    <div className="nav-divider"></div>

                    <ul className="copyRightContent">
                        <span>
                            Remind,
                            {new Date().getFullYear()}
                        </span>
                    </ul>
                </div>
                {!location.pathname.includes('overview') && !location.pathname.includes('user-setting') && (
                    <Link to="#" className="affliateProgramCard">
                        <div className="affliateProgramCard-title">Home reminders in hand!</div>
                        <div className="affliateProgramCard-des">Download the Remind mobile app for easy access and ultimate convenience- ensuring you never miss an important reminder again!</div>
                        <div className="affliateProgramCard-button" onClick={togglePopup}>
                            Download Now
                        </div>
                    </Link>
                )}
            </nav>
            {isOpen && (
                <>
                    <DownloadSteps
                        showModal={isOpen}
                        closeModal={() => {
                            setIsOpen(false);
                        }}
                    />
                </>
            )}
        </>
    );
};

export default SellerSiderbar;
