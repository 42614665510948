import React, { useState, useEffect } from 'react';
import { Badge, Button, Card, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';
import pdfIcon from '../../asstes/dash-icons/pdfIcon.svg';
import eyeIcon from '../../asstes/dash-icons/eyeIcon.svg';
import downloadIcon from '../../asstes/dash-icons/downloadIcon.svg';
import { deleteFile, getFile, getReminderById } from '../../../axiosCalls/userdashboard';
import OffCanvasBootstrap from '../../../off_canvas/OffCanvasBootstrap';
import FilesSideNavAdd from './filesSideNavAdd';
import { confirmDelete, formatDate } from '../../utils/helper';
import types from '../../../redux/actions/actionTypes';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../loader/Loader';

const FilesSideNavView = ({ selectValue, handleClose }) => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [files, setFiles] = useState([]);
    const [showFileModal, setShowFileModal] = useState(false);
    const [fileToView, setFileToView] = useState(null);
    const [loading, setLoading] = useState(false);

    const [reminders, setReminders] = useState([]);

    const fetchFile = async (id) => {
        setLoading(true);
        try {
            const res = await getFile(id);
            if (res.status === 200) {
                setData(res?.data || {});
                setReminders(res?.data?.reminders || []);
            }
        } catch (error) {
            console.error('Error fetching file list:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (selectValue.ID || selectValue.post_id) {
            const id = selectValue.ID || selectValue.post_id;
            fetchFile(id);
        }
    }, [selectValue]);

    useEffect(() => {
        if (data) {
            setFiles(data.file_url || []);
        }
    }, [data]);

    const handleViewFile = (fileUrl) => {
        setFileToView(fileUrl);
        setShowFileModal(true);
    };

    const handleDownloadFile = (fileUrl) => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', fileUrl.split('/').pop());
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };

    const getFileIcon = (file) => {
        if (typeof file === 'string') {
            const fileExtension = file.split('.').pop().toLowerCase();
            return fileExtension === 'pdf' ? pdfIcon : file;
        }
        return pdfIcon; // Default icon for unsupported file types
    };

    const [showEditFiles, setShowEditFiles] = useState(false);

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            {data ? (
                <div className="side-nav">
                    <div className="side-header">
                        <h3>Files</h3>
                    </div>
                    <div className="side-body homeEssentialSideNav">
                        <Form>
                            <Row>
                                {/* File Name */}
                                <Form.Group as={Col} md="6" className="mb-3">
                                    <div className="link-table-main">
                                        <Form.Label>Name</Form.Label>
                                        <p className="text-paragraph">{data ? data.file_name : 'N/A'}</p>
                                    </div>
                                </Form.Group>

                                {/* Upload Date */}
                                <Form.Group as={Col} md="6" className="mb-3">
                                    <div className="link-table-main">
                                        <Form.Label>Upload Dates</Form.Label>
                                        <p className="text-paragraph">{data ? new Date(data.upload_date).toLocaleDateString() : 'N/A'}</p>
                                    </div>
                                </Form.Group>

                                {/* Attached Files */}
                                <Form.Group as={Col} md="12" className="mb-3">
                                    <div className="link-table-main">
                                        <Form.Label>Attached Files</Form.Label>
                                        <div className="listofFiles">
                                            {files.length > 0 ? (
                                                files.map((file, index) => (
                                                    <div className="warrantyFile" key={index}>
                                                        <div className="text">
                                                            <img src={getFileIcon(file)} className="fileItemImage" alt="fileIcon" />
                                                            {file.split('/').pop()}
                                                        </div>
                                                        <div className="actions">
                                                            <span onClick={() => handleViewFile(file)} style={{ cursor: 'pointer' }}>
                                                                <img src={eyeIcon} alt="View" />
                                                            </span>
                                                            <span onClick={() => handleDownloadFile(file)} style={{ cursor: 'pointer' }}>
                                                                <img src={downloadIcon} alt="Download" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <p>No files available.</p>
                                            )}
                                        </div>
                                    </div>
                                </Form.Group>

                                {/* Suggested Reminders */}
                                <Container className="my-4" style={{ maxWidth: '100%' }}>
                                    <Card className="shadow-sm">
                                        <Card.Header className="bg- text-center">
                                            <h5>Reminders</h5>
                                        </Card.Header>
                                        <Card.Body>
                                            <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                                                <Table bordered hover className="text-center align-middle" style={{ minWidth: '600px' }}>
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>Title</th>
                                                            <th>Description</th>
                                                            <th>Frequency</th>
                                                            <th>Date</th>
                                                            <th>Time</th>
                                                        </tr>
                                                    </thead>
                                                    {reminders?.length > 0 ? (
                                                        <tbody>
                                                            {reminders.map((reminder) => (
                                                                <tr key={reminder.id}>
                                                                    <td>{reminder.id}</td>
                                                                    <td>{reminder.title}</td>
                                                                    <td>{reminder.description}</td>
                                                                    <td>
                                                                        <Badge bg="info" className="text-uppercase">
                                                                            {reminder.frequency}
                                                                        </Badge>
                                                                    </td>
                                                                    <td>{formatDate(reminder.date)}</td>
                                                                    <td>{reminder.time || 'Not Specified'}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    ) : (
                                                        <div className="my-3 text-center">No Reminder Found</div>
                                                    )}
                                                </Table>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Container>
                            </Row>
                            <div className="side-footer form-actions d-flex justify-content-between gap-2">
                                <Button
                                    className="button-cancel"
                                    onClick={() => {
                                        handleClose();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <div className="d-flex gap-2">
                                    <Button
                                        className="delete-btn"
                                        onClick={() => {
                                            confirmDelete(async (result) => {
                                                if (result.isConfirmed) {
                                                    const resp = await deleteFile(data.post_id);
                                                    if (resp.status === 200) {
                                                        // show toast
                                                        toast.success('File deleted successfully');
                                                        dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetRecentFiles', data: true } });
                                                        dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetSearchResults', data: true } });

                                                        handleClose();
                                                    }
                                                }
                                            });
                                        }}
                                    >
                                        Delete
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setShowEditFiles(true);
                                        }}
                                        className="button-primary"
                                        type="button"
                                    >
                                        Edit
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                    {/* Modal to View File */}
                    <Modal show={showFileModal} onHide={() => setShowFileModal(false)} size="lg" centered>
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>{fileToView && fileToView.endsWith('.pdf') ? <iframe src={fileToView} style={{ width: '100%', height: '500px' }} frameBorder="0" title="PDF Viewer" /> : <img src={fileToView} alt="File" style={{ width: '100%' }} />}</Modal.Body>
                        <Modal.Footer></Modal.Footer>
                    </Modal>
                </div>
            ) : (
                ''
            )}
            <OffCanvasBootstrap
                placement="end"
                name="end"
                show={showEditFiles}
                handleClose={() => {
                    setShowEditFiles(false);
                    handleClose();
                }}
            >
                <FilesSideNavAdd
                    handleClose={() => {
                        setShowEditFiles(false);
                        handleClose();
                    }}
                    fileData={data}
                />
            </OffCanvasBootstrap>
        </>
    );
};

export default FilesSideNavView;
