import React, { useMemo } from 'react';
import warningIcon from '../../asstes/dash-icons/warningIcon.png';
import icon1 from '../../asstes/dash-icons/sidebar/dashIcon1.svg';

const HomeEssentials = ({ homeEssentials, all }) => {
    const allHomeEssentials = useMemo(() => homeEssentials?.map((p) => ({ ...p?.meta, name: p?.title, ...p })), [homeEssentials]);

    return (
        <>
            {/* {!all && (
                <div className=" col-md-12 mb-3" id="expense-total">
                    <label className="reportSectiontitile">Expense Totals</label>
                    <div className="expenseTable">
                        <div className="expensItem">
                            <div className="expensTitle">Home Essentials</div>
                            <div className="expensAmount">${2000}</div>
                        </div>
                    </div>
                </div>
            )} */}

            <div className="col-md-12 mb-3" id="project-cost">
                <label className="reportSectiontitile">Home Essentials</label>
                <div className="row paddingRight0">
                    {allHomeEssentials?.map((item, index) => (
                        <div
                            className="col-lg-3 col-md-6 col-sm-6 item_each_home_cards"
                            id={index}
                            key={item.id} // Use item.id as the key for unique identification
                        >
                            <div className="dashboard-card">
                                <div className="dashboard-card-card-body d-flex flex-column justify-content-between">
                                    <div className="justify-content-between align-items-center">
                                        <div className="icon-box-card">
                                            {/* Display the first image from the images array */}

                                            <img style={{ width: '32px', height: '32px' }} src={`${item.icon_url || icon1}`} alt={item.model_number} />

                                            {item.warranty && (
                                                <div className="card-warining">
                                                    <img alt="Warning" src={warningIcon} />
                                                    <span>Warning</span>
                                                </div>
                                            )}
                                        </div>
                                        <p
                                            className="dashboard-heading"
                                            dangerouslySetInnerHTML={{
                                                __html: item.name && item.name
                                            }}
                                        />
                                        <p className="dashboard-subheading">{item.type}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default HomeEssentials;
