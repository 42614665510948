import './cameraStyles.css';
import React, { useRef, useState } from 'react';
import { Card, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { ReactComponent as GalleryIcon } from '../../user-dashboard/asstes/dash-icons/gallery.svg';
import { ReactComponent as CameraIcon } from '../../user-dashboard/asstes/dash-icons/camera_one.svg';
import styles from './style.module.css';
import { toast } from 'react-toastify';
import { convertToISODate, extractNameAndPrice, extractProductsFromText } from './getDataFromText';
import VerifyScrapedData from './VerifyScrapeData';
import PurchasesServicesNavAdd from '../../user-dashboard/components/inc/purchasesServicesNavAdd';
import OffCanvasBootstrap from '../../off_canvas/OffCanvasBootstrap';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { uploadFile } from '../../axiosCalls/userdashboard';
import ReceiptDataDisplay from './ReceiptDataDisplay';

export default function PermissionModal({ shown, onCloseModal, selectedQuickAction }) {
    const [isLoading, setIsLoading] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);
    const [imageData, setImageData] = useState(null);

    const [receiptData, setReceiptData] = useState([]);

    const [totalBill, setTotalBill] = useState(null);

    const [isCameraActive, setIsCameraActive] = useState(false);
    const [cameraFacingMode, setCameraFacingMode] = useState(FACING_MODES.ENVIRONMENT);

    function dataUriToFile(dataUri, fileName) {
        // Extract the base64 data and mime type
        const [metadata, base64Data] = dataUri.split(',');
        const mimeType = metadata.match(/:(.*?);/)[1];

        // Decode the base64 data
        const byteString = atob(base64Data);

        // Convert decoded data into a byte array
        const byteArray = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            byteArray[i] = byteString.charCodeAt(i);
        }

        // Create a File object from the byte array
        return new File([byteArray], fileName, { type: mimeType });
    }

    const handleTakePhoto = (dataUri) => {
        const file = dataUriToFile(dataUri, 'capture-photo.png');
        if (file) {
            processFile(file);
        }
        setIsCameraActive(false);
    };

    const switchCamera = () => {
        if (cameraFacingMode === FACING_MODES.ENVIRONMENT) {
            setCameraFacingMode(FACING_MODES.USER);
        } else {
            setCameraFacingMode(FACING_MODES.ENVIRONMENT);
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            processFile(file);
        }
    };

    const processFile = async (file) => {
        setIsLoading(true);
        try {
            const obj = {
                files: file,
                quickActionType: selectedQuickAction.id
            };
            const resp = await uploadFile(obj);
            if (resp.status === 200 || resp.status === 201) {
                const items = extractNameAndPrice(resp?.data?.data?.lineItem || []);
                const summaryList = resp?.data?.data?.summary_list || [];
                setReceiptData(summaryList);
                const receiptDate = summaryList?.find((s) => s.Type.Text === 'INVOICE_RECEIPT_DATE');
                const total = summaryList?.find((s) => s.Type.Text === 'TOTAL');
                const totalBill = total?.ValueDetection?.Text || items?.reduce((i, s = 0) => (s += Number(i.price))) || null;
                setTotalBill(totalBill);
                const vender = summaryList?.find((s) => s.Type.Text === 'VENDOR_NAME');
                setImageData({ products: items, ...(vender?.ValueDetection.Text && { vendorName: vender?.ValueDetection.Text }), ...(receiptDate?.ValueDetection.Text && { date: convertToISODate(receiptDate?.ValueDetection.Text) }) });
                setImagePreview(file);
            }
            // show error
            else {
                toast.error(resp?.data?.message || 'Something went wrong');
            }
        } catch (err) {
            toast.error('Failed to process the image.');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const closeModal = () => {
        setImagePreview(null);
        setImageData(null);
        setIsCameraActive(false);
        onCloseModal();
    };

    const cameraRef = useRef();

    const [showCreatePurchaseService, setShowCreatePurchaseService] = useState(false);

    return (
        <>
            <Modal size={imagePreview && imageData ? 'xl' : 'lg'} show={shown} onHide={closeModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>You can take up by ({selectedQuickAction?.title})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!isLoading && (
                        <Row>
                            <Col md={12}>
                                {imagePreview && imageData ? (
                                    <>
                                        <Row>
                                            <Col lg={12}>
                                                <div className={styles['del-container']} onClick={() => setImagePreview(null)}>
                                                    <GalleryIcon width="70%" height="70%" className={styles['icon']} />
                                                </div>
                                                <img style={{ width: '320px', height: 'auto', textAlign: 'center' }} src={URL.createObjectURL(imagePreview)} alt="Preview" className="img-fluid" />
                                            </Col>
                                            <Col lg={12}>
                                                {/* <ReceiptDataDisplay data={receiptData} /> */}
                                                <VerifyScrapedData setImageData={setImageData} setShowCreatePurchaseService={setShowCreatePurchaseService} closeModal={closeModal} scrapedData={{ ...imageData, totalBill }} />
                                            </Col>
                                        </Row>
                                    </>
                                ) : isCameraActive ? (
                                    <Col md={12}>
                                        <div className="camera-wrapper">
                                            <Camera
                                                ref={cameraRef}
                                                idealFacingMode="user" // "user" for front camera, "environment" for back camera
                                                isImageMirror={false} // Mirrors the image for a natural front-facing experience
                                                isDisplayStartCameraError={true}
                                                idealResolution={{ width: 300, height: 480 }}
                                                onTakePhoto={handleTakePhoto}
                                                imageType={IMAGE_TYPES.PNG}
                                                isMaxResolution={true}
                                            />
                                        </div>
                                        <div className="d-flex gap-2 mt-3">
                                            <button className={styles['action-button']} onClick={switchCamera}>
                                                Switch Camera
                                            </button>
                                            <button className={styles['action-button']} onClick={() => setIsCameraActive(false)}>
                                                Close Camera
                                            </button>
                                        </div>
                                    </Col>
                                ) : (
                                    <div className="d-flex justify-content-around flex-wrap">
                                        <Card
                                            className={styles['action-option']}
                                            onClick={() => {
                                                setIsCameraActive(true);
                                            }}
                                        >
                                            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                                                <CameraIcon width="50%" height="50%" />
                                                <Card.Title className="mt-3">Take Photo</Card.Title>
                                            </Card.Body>
                                        </Card>
                                        <input type="file" id="gallery" accept="image/*" className="d-none" onChange={handleFileChange} />
                                        <label htmlFor="gallery">
                                            <Card className={styles['action-option']}>
                                                <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                                                    <GalleryIcon width="50%" height="50%" />
                                                    <Card.Title className="mt-3">Gallery</Card.Title>
                                                </Card.Body>
                                            </Card>
                                        </label>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    )}

                    <Row>
                        <Col md={12}>
                            {isLoading && (
                                <div className="text-center d-flex gap-2 justify-content-center align-items-center mt-3">
                                    <div>
                                        <strong>Scanning Receipt</strong>
                                    </div>

                                    <div>
                                        <Spinner animation="border" />
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button className={styles['cancel']} onClick={closeModal}>
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>
            <OffCanvasBootstrap
                placement="end"
                name="end"
                show={showCreatePurchaseService}
                handleClose={() => {
                    setShowCreatePurchaseService(false);
                }}
            >
                <PurchasesServicesNavAdd
                    handleClose={() => {
                        setShowCreatePurchaseService(false);
                    }}
                    itemType={selectedQuickAction?.title === 'Add Purchases' ? 'purchase' : 'service'}
                    initialData={{
                        product_name: imageData?.vendorName,
                        vendor: imageData?.vendorName,
                        service_date: imageData?.date,
                        purchase_date: imageData?.date,
                        amount: imageData?.amount,
                        receipt_url: [imagePreview],
                        ...(imageData?.products?.length > 0 && { line_items: JSON.stringify(imageData?.products) })
                    }}
                />
            </OffCanvasBootstrap>
        </>
    );
}
