import React from 'react';
import './DownloadSteps.css';
import iosStepsImg from '../../asstes/images/ios-steps.png';
import androidStepsImg from '../../asstes/images/andriod-steps.png';
import { Col, Modal, Row } from 'react-bootstrap';

const DownloadSteps = ({ showModal, closeModal }) => {
    return (
        <>
            <Modal size="lg" show={showModal} onHide={closeModal}>
                {/* <Modal.Header>
                    <Modal.Title>Download Steps</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <Row className="w-100 m-0">
                        <h5 className="text-center">Download Steps</h5>
                        <p className="text-center">Please follow the steps to download the app.</p>
                        <Col sm={6}>
                            <div className="p-1 rounded-3 w-100" style={{ border: '1px solid grey' }}>
                                <h6 className="text-center my-1">IOS</h6>
                                <img width="100%" height="auto" src={iosStepsImg} alt="iosStepsImg" className="w-100" />
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="p-1 rounded-3 w-100" style={{ border: '1px solid grey' }}>
                                <h6 className="text-center my-1">Android</h6>
                                <img height="auto" src={androidStepsImg} alt="androidStepsImg" className="w-100" />
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button className="action-button cancel" onClick={closeModal}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DownloadSteps;
