import React, { useMemo } from 'react';
import DataTable, { Alignment, Direction } from 'react-data-table-component';

const Tasks = ({ completedTasks, pagination }) => {
    // Filter tasks with 'completed' status and exclude the 'meta' field
    const allCompletedTasks = useMemo(() => {
        return completedTasks?.filter((task) => task?.meta?.status_task === 'completed')?.map(({ meta, ...rest }) => ({ ...meta, ...rest })) || [];
    }, [completedTasks]);

    // Columns for Task Overview (static example remains unchanged)
    const columnsTaskOverview = [
        {
            name: 'Home Essentials',
            selector: (row) => row.name,
            sortable: true,
            reorder: true
        },
        {
            name: '',
            selector: (row) => row.empty,
            sortable: true,
            reorder: true
        },
        {
            name: 'Completed Tasks',
            selector: (row) => row.completed,
            sortable: true,
            reorder: true
        }
    ];

    // Columns for Task Details
    const columnsTask = [
        {
            name: 'Title',
            selector: (row) => <div dangerouslySetInnerHTML={{ __html: row.title }} />,
            sortable: true,
            reorder: true,
            width: '300px'
        },
        {
            name: 'Date',
            selector: (row) => row.date,
            sortable: true,
            reorder: true,
            width: '150px'
        },
        // {
        //     name: 'Time',
        //     selector: (row) => row.time,
        //     sortable: true,
        //     reorder: true,
        //     width: '150px'
        // },
        {
            name: 'Status',
            selector: (row) => (row.status_task === 'completed' ? <span className="services-badge type-green">Completed</span> : <span className="services-badge type-red">Pending</span>),
            sortable: true,
            reorder: true,
            width: '150px'
        }
        // {
        //     name: 'Content',
        //     selector: (row) => <div dangerouslySetInnerHTML={{ __html: row.content }} />,
        //     sortable: true,
        //     reorder: true,
        //     width: '300px'
        // }
    ];

    return (
        <div className="row pe-0 me-0" id="task-detail-overview">
            {/* <div className="col-md-6 mb-3">
                <label className="reportSectiontitile">Task Overview</label>
                <div className="recentPurcheseTable" id="taskTable">
                    <DataTable
                        columns={columnsTaskOverview}
                        data={[
                            { id: 1, name: 'Refrigerator', completed: 20 },
                            { id: 2, name: 'Air Conditioner', completed: 10 },
                            { id: 3, name: 'Lights', completed: 20 },
                            { id: 4, name: 'Other', completed: 50 }
                        ]}
                        defaultSortFieldId={2}
                        pagination={false}
                        highlightOnHover
                        className="proteryReport"
                    />
                </div>
            </div> */}

            <div className="col-md-12 mb-3">
                <label className="reportSectiontitile">Task Details</label>
                <div className="recentPurcheseTable" id="taskTable">
                    <DataTable columns={columnsTask} data={allCompletedTasks} pagination={pagination} paginationPerPage={5} paginationRowsPerPageOptions={[5, 10, 15, 20]} highlightOnHover className="proteryReport" />
                </div>
            </div>
        </div>
    );
};

export default Tasks;
