import { useEffect, useState } from 'react';
import icon1 from '../asstes/dash-icons/sidebar/dashIcon1.svg';
import infoIcon from '../asstes/dash-icons/info.svg';
import warningIcon from '../asstes/dash-icons/warningIcon.png';
import HomeEssentialsSideNav from '../components/inc/homeEssentialsSideNav';
import HomeEssentialsSideNavView from '../components/inc/homeEssentialsSideNavView';
import { addHomeEssential, getHomeEsstentialList, updateHomeEssential } from '../../axiosCalls/userdashboard';
import { toast } from 'react-toastify';
import { isPropertyExist } from '../utils/helper';
import { driver } from 'driver.js';
import AddHomeEssential from '../components/add_home_essential/addHomeEssential';
import QuickAction from '../../quick-action/QuickAction';
import OffCanvasBootstrap from '../../off_canvas/OffCanvasBootstrap';
import Loader from '../components/loader/Loader';

const HomeEssentials = () => {
    const homeEssentialDriver = driver({
        showProgress: 'true',
        steps: [
            {
                element: '#add-home-essential',
                popover: {
                    title: 'Add Home Essential',
                    description: 'Click button and Pick a category and fill out the form to save the data about essential and add this to your dashboard.'
                }
            }
        ]
    });
    const [sideNavWidth, setSideNavWidth] = useState('0px');
    const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState('0%');
    const [continueChange, setContinueChange] = useState('createStep');
    const [valueForView, setValueForView] = useState([]);

    const [showCreateHomeEssential, setShowCreateHomeEssential] = useState(false);
    const [showViewHomeEssential, setShowViewHomeEssential] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        make: '',
        model_number: '',
        purchase_date: '',
        age_of_home_essential: '',
        notes: '',
        warranty: false,
        type_id: '',
        customType: '',
        warranty_data: {
            name: '',
            number: '',
            startDate: '',
            endDate: '',
            notes: '',
            files: []
        },
        files: [],
        images: [],
        linked_reminders: []
    });

    // loader
    const [loader, setLoader] = useState(false);
    const [homeEssentials, setHomeEssentials] = useState([]);

    // Fetch home essentials when the component is mounted
    const fetchHomeEssentials = async () => {
        try {
            setLoader(true);
            const response = await getHomeEsstentialList();
            if (response && response.data && response.data.items) {
                setHomeEssentials(response.data.items);
            } else {
                console.error('No home essential data found');
            }
        } catch (error) {
            console.error('Error fetching home essentials:', error);
        } finally {
            setLoader(false);
        }
    };
    useEffect(() => {
        fetchHomeEssentials();
    }, []);

    function HandlerViewHomeEsstional(id, postID) {
        setValueForView(postID);
        setShowViewHomeEssential(true);
    }
    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div className="row">
                        <div className="col-lg-5 col-md-12 col-sm-12 d-flex align-items-center">
                            <h5 className="mb-2 text-titlecase mb-4">Home Essentials</h5>
                            <img
                                onClick={() => {
                                    homeEssentialDriver.destroy();

                                    homeEssentialDriver.drive();
                                }}
                                src={infoIcon}
                                width={'14px'}
                                className="mb-4 pb-2 ms-2 pointer"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-12 d-flex text-top-subtext">
                            <div className=" top-text-head">
                                <div className="reminder-top-button">
                                    <button
                                        className="filled-btn"
                                        id="add-home-essential"
                                        onClick={() => {
                                            if (isPropertyExist()) setShowCreateHomeEssential(true);
                                        }}
                                    >
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.00065 3.33594V12.6693M3.33398 8.0026H12.6673" stroke="white" strokeLinecap="round" />
                                        </svg>
                                        Add home essential
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row paddingRight0">
                            {!homeEssentials.length && !loader ? (
                                <AddHomeEssential
                                    heading={'Add home essential'}
                                    onClick={() => {
                                        if (isPropertyExist()) setShowCreateHomeEssential(true);
                                    }}
                                />
                            ) : null}
                            {loader ? (
                                <Loader />
                            ) : (
                                !loader &&
                                homeEssentials.map((item, index) => (
                                    <div
                                        className="col-lg-3 col-md-6 col-sm-6 item_each_home_cards"
                                        id={index}
                                        key={item.id} // Use item.id as the key for unique identification
                                        onClick={() => HandlerViewHomeEsstional(index, item.id)}
                                    >
                                        <div className="dashboard-card">
                                            <div className="dashboard-card-card-body d-flex flex-column justify-content-between">
                                                <div className="justify-content-between align-items-center">
                                                    <div className="icon-box-card">
                                                        {/* Display the first image from the images array */}

                                                        <img style={{ width: '32px', height: '32px' }} src={`${item.icon_url || icon1}`} alt={item.model_number} />

                                                        {item.warranty && (
                                                            <div className="card-warining">
                                                                <img alt="Warning" src={warningIcon} />
                                                                <span>Warning</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <p className="dashboard-heading">{item.name}</p>
                                                    <p className="dashboard-subheading">{item.type}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <QuickAction
                sidebarInfoFunc={() => {
                    if (isPropertyExist()) setShowCreateHomeEssential(true);
                }}
            />
            <OffCanvasBootstrap
                placement="end"
                name="end"
                show={showViewHomeEssential}
                handleClose={() => {
                    setShowViewHomeEssential(false);
                }}
            >
                <HomeEssentialsSideNavView
                    fetchHomeEssentials={fetchHomeEssentials}
                    handleClose={() => {
                        setShowViewHomeEssential(false);
                    }}
                    propsValue={valueForView}
                />
            </OffCanvasBootstrap>

            <OffCanvasBootstrap
                placement="end"
                name="end"
                show={showCreateHomeEssential}
                handleClose={() => {
                    setShowCreateHomeEssential(false);
                }}
            >
                <HomeEssentialsSideNav
                    fetchHomeEssentials={fetchHomeEssentials}
                    handlerCancelSideNav={() => {
                        setShowCreateHomeEssential(false);
                    }}
                    handleClose={() => {
                        setShowCreateHomeEssential(false);
                    }}
                    formData={formData}
                />
            </OffCanvasBootstrap>
        </div>
    );
};

export default HomeEssentials;
