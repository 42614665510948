import React, { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import MagnifyingGlass from '../asstes/dash-icons/sidebar/MagnifyingGlass.svg';
import PurchasesServicesTable from '../components/inc/purchasesServicesTable';
import PurchasesServicesNavAdd from '../components/inc/purchasesServicesNavAdd';
import { Link } from 'react-router-dom';
import HomeEssentialsSideNav from '../components/inc/homeEssentialsSideNav';
import { toast } from 'react-toastify';
import infoIcon from '../asstes/dash-icons/info.svg';
import { addHomeEssential, AddPurchaseItem, AddServiceItem, getPurchaseList, getServiceList } from '../../axiosCalls/userdashboard';
import { servicesOnboard } from './driver/constant';
import { driver } from 'driver.js';
import QuickAction from '../../quick-action/QuickAction';
import Loader from '../components/loader/Loader';
import AddlineItem from '../components/inc/addlineItem';
import OffCanvasBootstrap from '../../off_canvas/OffCanvasBootstrap';
import { useDispatch, useSelector } from 'react-redux';
import types from '../../redux/actions/actionTypes';
import { isPropertyExist } from '../utils/helper';

const PurchasesServices = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [editLoading, setEditLoading] = useState(false);
    const servicesDrivers = driver(servicesOnboard);
    const [sideNavWidth, setSideNavWidth] = useState('0px');
    const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState('0%');
    const [continueChange, setContinueChange] = useState('createStep');
    const [valueForView, setValueForView] = useState([]);

    const [selectedHomeEssentials, setSelectedHomeEssentials] = useState([]);
    const [listOfReceipts, setListOfReceipts] = useState([]);
    const [listOfFiles, setListOfFiles] = useState([]);
    const [listOfWarrantyFiles, setListOfWarrantyFiles] = useState([]);
    const [purchaseData, setPurchaseData] = useState({
        files: {
            receipt: null,
            files: null,
            warrantyFiles: null
        }
    });
    const [serviceData, setServiceData] = useState({
        files: {
            receipt: null,
            files: null,
            warrantyFiles: null
        }
    });

    // listing states
    const [purchaseList, setPurchaseList] = useState([]);
    const [serviceList, setServiceList] = useState([]);

    const [isChecked, setIsChecked] = useState(false); // State to hold checkbox value
    const [openModaLineItem, setOpenModaLineItem] = useState(false);
    const [prodLineItem, setProductLineItems] = useState([]);
    const [servLineItem, setServiceLineItems] = useState([]);
    const [selectedTab, setSelectedTab] = useState('purchase');
    const [updateProdItem, setUpdateProItem] = useState(null);
    const [quickAddDetail, setQuickAddDetail] = useState(null);

    const fetchPurchasesAndServicesData = async () => {
        try {
            setIsLoading(true);
            const res = await getPurchaseList();
            setPurchaseList(res.data || []); // Set the response data into state

            const resservice = await getServiceList();
            setServiceList(resservice.data || []);
        } catch (error) {
            console.error('Error fetching purchase data', error);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchPurchasesAndServicesData();
    }, []);
    const dispatch = useDispatch();
    const isGetAllPurchaseServices = useSelector((state) => state.root?.isGetAllPurchaseServices);
    useEffect(() => {
        if (isGetAllPurchaseServices) {
            fetchPurchasesAndServicesData();
            dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetAllPurchaseServices', data: false } });
        }
    }, [dispatch, isGetAllPurchaseServices]);

    const [formData, setFormData] = useState({
        homeEssentialName: '',
        make: '',
        modelNumber: '',
        purchaseDate: '',
        files: [],
        warrantyFiles: [],
        images: [],
        notes: '',
        warrantyData: {
            name: '',
            number: '',
            startDate: '',
            endDate: '',
            notes: '',
            files: []
        },
        linkedReminders: [],
        selectedType: '',
        warrantyCheck: 'unchecked'
    });
    // Handler to update the form data from the child component
    const updateFormData = (updatedData) => {
        setFormData((prevData) => ({
            ...prevData,
            ...updatedData
        }));
    };
    const handlerCancelSideNav = () => {
        // also set all linked states to reset/default
        setServiceData({
            files: {
                receipt: null,
                files: null,
                warrantyFiles: null
            }
        });
        setPurchaseData({
            files: {
                receipt: null,
                files: null,
                warrantyFiles: null
            }
        });
        setProductLineItems([]);
        setServiceLineItems([]);
        setListOfReceipts([]);
        setListOfFiles([]);
        setListOfWarrantyFiles([]);
        setSelectedHomeEssentials([]);
        setSideNavWidth('sidenavPopuWidthHide');
        setsideNavWidthOverlay('0%');
        setIsChecked(false);
        document.body.classList.remove('no-scroll');
    };

    const validateServiceData = (data) => {
        const { serviceName, amount, serviceDate, vendor } = data;

        // Simple checks to ensure required fields are filled
        if (!serviceName) {
            toast.error('Service name is required.');
            return false;
        }
        if (!vendor) {
            toast.error('Vendor is required.');
            return false;
        }
        if (!serviceDate) {
            toast.error('Service date is required.');
            return false;
        }
        if (!amount) {
            toast.error('Service amount is required.');
            return false;
        }
        if (isNaN(amount) || amount <= 0) {
            toast.error('Amount must be a positive number.');
            return false;
        }

        return true;
    };

    const validatePurchaseData = (data) => {
        const { productName, amount, purchaseDate, make } = data;

        // Simple checks to ensure required fields are filled
        if (!productName) {
            toast.error('Product name is required.');
            return false;
        }

        if (!amount || isNaN(amount) || amount <= 0) {
            toast.error('Amount must be a positive number.');
            return false;
        }
        if (!purchaseDate) {
            toast.error('Purchase date is required.');
            return false;
        }

        return true;
    };

    const [showCreatePurchaseService, setShowCreatePurchaseService] = useState(false);

    const handlerReminderSideNave = () => {
        if (isPropertyExist()) {
            setShowCreatePurchaseService(true);
        }
    };

    // Function to handle the submit from parent
    const handleSubmitHomeEssentials = async () => {
        const { homeEssentialName, make, modelNumber, purchaseDate, ageofEssential, linkedReminders, notes, selectedType, warrantyCheck, warrantyData, files, images, warrantyFiles } = formData;
        // Validate required fields
        if (!homeEssentialName) {
            toast.error('Home Essential Name is required');
            return;
        }
        if (!selectedType) {
            toast.error('Please select a Home Essential Type');
            return;
        }
        if (!modelNumber) {
            toast.error('Model Number is required');
            return;
        }
        if (!ageofEssential) {
            toast.error('Age of Home Essential is required');
            return;
        }
        if (!purchaseDate) {
            toast.error('Purchase Date is required');
            return;
        }
        if (!make) {
            toast.error('Make Home Essential is required');
            return;
        }
        if (!files.length) {
            toast.error('At least one file is required');
            return;
        }
        if (!images.length) {
            toast.error('At least one image is required');
            return;
        }

        // If warranty is checked, validate warranty fields
        if (warrantyCheck === 'checked') {
            if (!warrantyData.name) {
                toast.error('Warranty Name is required');
                return;
            }
            if (!warrantyData.number) {
                toast.error('Warranty Number is required');
                return;
            }
            if (!warrantyData.startDate) {
                toast.error('Warranty Start Date is required');
                return;
            }
            if (!warrantyData.endDate) {
                toast.error('Warranty End Date is required');
                return;
            }
        }
        const data = new FormData();
        data.append('type', selectedType);
        data.append('name', homeEssentialName);
        data.append('make', make);
        data.append('model_number', modelNumber);
        data.append('age_of_home_essential', ageofEssential);
        data.append('purchase_date', purchaseDate);
        linkedReminders.forEach((remind) => {
            const reminderData = JSON.stringify({
                id: remind.id,
                date: remind.start_date,
                frequency_id: remind.frequency_id,
                frequency: remind.frequency,
                reminder_frequency: remind.reminder_frequency,
                time: remind.time
            });
            data.append('linked_reminders[]', reminderData);
        });
        data.append('notes', notes);
        data.append('warranty', warrantyCheck === 'checked' ? 'true' : 'false');

        if (warrantyCheck === 'checked') {
            data.append('warranty_data[][name]', warrantyData.name);
            data.append('warranty_data[][number]', warrantyData.number);
            data.append('warranty_data[][start_date]', warrantyData.startDate);
            data.append('warranty_data[][end_date]', warrantyData.endDate);
            data.append('warranty_data[][notes]', warrantyData.notes);
            warrantyFiles.forEach((file) => data.append('warranty_data_files[]', file));
        }

        files.forEach((file) => data.append('files[]', file));
        images.forEach((image) => data.append('images[]', image));

        try {
            const response = await addHomeEssential(data);
            if (response.data.post_id) {
                handlerCancelSideNav();
                await fetchPurchasesAndServicesData();
                setFormData({
                    homeEssentialName: '',
                    make: '',
                    modelNumber: '',
                    ageofEssential: '',
                    purchaseDate: '',
                    linkedReminders: [],
                    notes: '',
                    selectedType: '',
                    warrantyCheck: '',
                    warrantyData: {
                        name: '',
                        number: '',
                        startDate: '',
                        endDate: '',
                        notes: ''
                    },
                    files: [],
                    images: [],
                    warrantyFiles: []
                });
                setSelectedHomeEssentials([]);
                toast.success(response.data.message);
            }
        } catch (error) {
            console.error('Error submitting home essential:', error);
        }
    };

    const [searchQuery, setSearchQuery] = useState('');

    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div className="row">
                        <div className="col-lg-5 col-md-12 col-sm-12 d-flex align-items-center">
                            <h5 className="mb-2 text-titlecase mb-4">Purchases and Services</h5>
                            <img onClick={() => servicesDrivers.drive()} src={infoIcon} width={'14px'} className="mb-4 pb-2 ms-2 pointer" />
                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-12 d-flex text-top-subtext">
                            <div className=" top-text-head">
                                <div className="reminder-top-button">
                                    <button className="filled-btn" onClick={handlerReminderSideNave} id="add-purchase-service">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.00065 3.33594V12.6693M3.33398 8.0026H12.6673" stroke="white" strokeLinecap="round" />
                                        </svg>
                                        Add Purchase or Service
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="row paddingRight0">
                            <div className="col-lg-12 paddingRight0 purcheSearchExpensBtns">
                                <Form className="search-form purchesFilters">
                                    <InputGroup id="services-search" className="search-field ">
                                        <InputGroup.Text id="basic-addon1">
                                            <img src={MagnifyingGlass} />
                                        </InputGroup.Text>
                                        <Form.Control
                                            value={searchQuery}
                                            onChange={(e) => {
                                                setSearchQuery(e.target.value);
                                            }}
                                            type="text"
                                            placeholder="Search "
                                        />
                                    </InputGroup>
                                    <Link to="/property-reports" className="expenisvePrimaryOutlineBtn" id="expense-detail">
                                        <span>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 5.5C7.50555 5.5 7.0222 5.64662 6.61107 5.92133C6.19995 6.19603 5.87952 6.58648 5.6903 7.04329C5.50108 7.50011 5.45157 8.00277 5.54804 8.48773C5.6445 8.97268 5.8826 9.41814 6.23223 9.76777C6.58186 10.1174 7.02732 10.3555 7.51227 10.452C7.99723 10.5484 8.49989 10.4989 8.95671 10.3097C9.41352 10.1205 9.80397 9.80005 10.0787 9.38893C10.3534 8.9778 10.5 8.49445 10.5 8C10.5 7.33696 10.2366 6.70107 9.76777 6.23223C9.29893 5.76339 8.66304 5.5 8 5.5ZM8 9.5C7.70333 9.5 7.41332 9.41203 7.16664 9.2472C6.91997 9.08238 6.72771 8.84811 6.61418 8.57403C6.50065 8.29994 6.47094 7.99834 6.52882 7.70736C6.5867 7.41639 6.72956 7.14912 6.93934 6.93934C7.14912 6.72956 7.41639 6.5867 7.70736 6.52882C7.99834 6.47094 8.29994 6.50065 8.57403 6.61418C8.84811 6.72771 9.08238 6.91997 9.2472 7.16664C9.41203 7.41332 9.5 7.70333 9.5 8C9.5 8.39782 9.34196 8.77936 9.06066 9.06066C8.77936 9.34196 8.39782 9.5 8 9.5ZM15 3.5H1C0.867392 3.5 0.740215 3.55268 0.646447 3.64645C0.552678 3.74021 0.5 3.86739 0.5 4V12C0.5 12.1326 0.552678 12.2598 0.646447 12.3536C0.740215 12.4473 0.867392 12.5 1 12.5H15C15.1326 12.5 15.2598 12.4473 15.3536 12.3536C15.4473 12.2598 15.5 12.1326 15.5 12V4C15.5 3.86739 15.4473 3.74021 15.3536 3.64645C15.2598 3.55268 15.1326 3.5 15 3.5ZM12.1031 11.5H3.89687C3.729 10.9323 3.42175 10.4155 3.00311 9.99689C2.58447 9.57825 2.06775 9.271 1.5 9.10312V6.89687C2.06775 6.729 2.58447 6.42175 3.00311 6.00311C3.42175 5.58447 3.729 5.06775 3.89687 4.5H12.1031C12.271 5.06775 12.5782 5.58447 12.9969 6.00311C13.4155 6.42175 13.9323 6.729 14.5 6.89687V9.10312C13.9323 9.271 13.4155 9.57825 12.9969 9.99689C12.5782 10.4155 12.271 10.9323 12.1031 11.5ZM14.5 5.83563C13.9003 5.57775 13.4223 5.09973 13.1644 4.5H14.5V5.83563ZM2.83562 4.5C2.57774 5.09973 2.09973 5.57775 1.5 5.83563V4.5H2.83562ZM1.5 10.1644C2.09973 10.4223 2.57774 10.9003 2.83562 11.5H1.5V10.1644ZM13.1644 11.5C13.4223 10.9003 13.9003 10.4223 14.5 10.1644V11.5H13.1644Z"
                                                    fill="#00487C"
                                                />
                                            </svg>
                                        </span>
                                        <span> See Expense Totals</span>
                                    </Link>
                                </Form>
                            </div>
                            <div className="col-lg-12 paddingRight0">
                                {isLoading ? (
                                    <Loader />
                                ) : (
                                    <PurchasesServicesTable
                                        searchQuery={searchQuery}
                                        purchaseData={purchaseList}
                                        serviceData={serviceList}
                                        editLoading={editLoading}
                                        setEditLoading={setEditLoading}
                                        deleteLoading={deleteLoading}
                                        setDeleteLoading={setDeleteLoading}
                                        fetchPurchasesAndServicesData={fetchPurchasesAndServicesData}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <OffCanvasBootstrap
                placement="end"
                name="end"
                show={showCreatePurchaseService}
                handleClose={() => {
                    setShowCreatePurchaseService(false);
                }}
            >
                <PurchasesServicesNavAdd
                    handleClose={() => {
                        setShowCreatePurchaseService(false);
                    }}
                    fetchPurchasesAndServicesData={fetchPurchasesAndServicesData}
                />
            </OffCanvasBootstrap>
            <QuickAction sidebarInfoFunc={handlerReminderSideNave} />
        </div>
    );
};

export default PurchasesServices;
