import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import placeholderImage from '../../asstes/dash-icons/placeholderImage.svg';
import pdfIcon from '../../asstes/dash-icons/pdfIcon.svg';
import eyeIcon from '../../asstes/dash-icons/eyeIcon.svg';
import downloadIcon from '../../asstes/dash-icons/downloadIcon.svg';
import { deleteHomeEssentials, getHomeEsstentialList, getReminderById } from '../../../axiosCalls/userdashboard';
import LinkedReminders from './linkedReminders';
import Loader from '../loader/Loader';
import OffCanvasBootstrap from '../../../off_canvas/OffCanvasBootstrap';
import HomeEssentialsSideNav from './homeEssentialsSideNav';
import { confirmDelete, formatDate } from '../../utils/helper';
import { toast } from 'react-toastify';
import types from '../../../redux/actions/actionTypes';
import { useDispatch } from 'react-redux';
import NotificationsList from './NotificationsList';

const HomeEssentialsSideNavView = ({ propsValue, handleClose, fetchHomeEssentials }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [homeEssential, setHomeEssential] = useState(null);
    const [showFileModal, setShowFileModal] = useState(false);
    const [fileToView, setFileToView] = useState(null);
    const [reminders, setReminders] = useState([]);

    const [showEditHomeEssential, setShowEditHomeEssential] = useState(false);

    // Fetch home essential data on component mount
    const fetchHomeEssential = async () => {
        try {
            setIsLoading(true);
            const response = await getHomeEsstentialList(propsValue);
            if (response && response.data) {
                setHomeEssential(response.data);
            }
        } catch (error) {
            console.error('Error fetching home essential data:', error);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchHomeEssential();
    }, [propsValue]);

    // Effect for fetching reminders when homeEssential is available
    useEffect(() => {
        const fetchReminderByID = async () => {
            try {
                setIsLoading(true);
                if (homeEssential && homeEssential?.linked_reminders && homeEssential?.linked_reminders?.length > 0) {
                    // Fetch all reminders based on IDs
                    const reminderPromises = homeEssential?.linked_reminders?.map(async (item) => {
                        const response = await getReminderById(item.id); // Assuming item has an id property
                        return response && response.data ? response.data : null;
                    });

                    // Wait for all promises to resolve
                    const results = await Promise.all(reminderPromises);

                    // Filter out any null responses and set state
                    const filteredResults = results.filter((result) => result !== null);
                    setReminders(filteredResults);
                }
            } catch (error) {
                console.error('Error fetching reminder data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (homeEssential) {
            fetchReminderByID();
        }
    }, [homeEssential]); // This useEffect depends on homeEssential

    // Loading state
    if (!homeEssential || isLoading) {
        return <Loader />; // Show a loading state until data is fetched
    }

    const { name, model_number, purchase_date, age_of_home_essential, linked_reminders, notes, type, make, warranty, warranty_data, files, images, warrantyFiles } = homeEssential;

    // Initialize arrays for files and warranty data
    const filesArray = [];
    const warrantyData = [];

    // Iterate over the object properties
    if (warranty_data) {
        Object.entries(warranty_data).forEach(([key, value]) => {
            if (key === 'files') {
                filesArray.push(...value); // Add all file URLs to the files array
            } else {
                warrantyData.push(value); // Add other data to the warrantyData array
            }
        });
    }

    // Function to handle file viewing
    const handleViewFile = (fileUrl) => {
        setFileToView(fileUrl);
        setShowFileModal(true);
    };

    // Function to handle file download
    const handleDownloadFile = (fileUrl) => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', fileUrl.split('/').pop()); // Set the download attribute with the file name
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link); // Remove the link after download
    };

    if (isLoading) {
        <Loader />;
    }

    return (
        <div className="side-nav">
            <div className="side-header">
                <h3 className="name">{name || 'No Name Available'}</h3>
                <h5 className="type">{type || 'No Type Available'}</h5>
                <h5 className="model-number">Model Number: {model_number || 'No Model Number Available'}</h5>
            </div>
            <div className="side-body homeEssentialSideNav">
                <Form>
                    <Row>
                        {/* Make */}
                        <Form.Group as={Col} md="4" className="mb-3">
                            <div className="link-table-main">
                                <Form.Label>Make</Form.Label>
                                <OverlayTrigger placement="top" overlay={<Tooltip>{make || 'No Purchase Date Available'}</Tooltip>}>
                                    <p
                                        className="text-paragraph"
                                        style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {make || 'NA'}
                                    </p>
                                </OverlayTrigger>
                            </div>
                        </Form.Group>

                        {/* Purchase Date */}
                        <Form.Group as={Col} md="4" className="mb-3">
                            <div className="link-table-main">
                                <Form.Label>Purchase Date</Form.Label>
                                <OverlayTrigger placement="top" overlay={<Tooltip>{purchase_date || 'No Purchase Date Available'}</Tooltip>}>
                                    <p
                                        className="text-paragraph"
                                        style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {purchase_date ? formatDate(purchase_date) : 'No Purchase Date Available'}
                                    </p>
                                </OverlayTrigger>
                            </div>
                        </Form.Group>

                        {/* Age of Home Essential */}
                        <Form.Group as={Col} md="4" className="mb-3">
                            <div className="link-table-main">
                                <Form.Label>Age of Home Essential</Form.Label>
                                <OverlayTrigger placement="top" overlay={<Tooltip>{age_of_home_essential || 'No Age Data Available'}</Tooltip>}>
                                    <p
                                        className="text-paragraph"
                                        style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {formatDate(age_of_home_essential) || 'No Age Data Available'}
                                    </p>
                                </OverlayTrigger>
                            </div>
                        </Form.Group>
                    </Row>

                    <Row>
                        {/* Linked Reminders */}
                        {/* <Form.Group as={Col} md="12" className="mb-3">
                            <div className="link-table-main">
                                <Form.Label>Linked Reminders</Form.Label>
                                <LinkedReminders reminders={reminders} />
                            </div>
                        </Form.Group> */}
                        {/* Linked Reminders */}
                        <Form.Group as={Col} md="12" className="mb-3">
                            <div className="link-table-main">
                                <Form.Label>Notifications</Form.Label>
                                <NotificationsList view notifications={homeEssential?.notification_lists || []} />
                            </div>
                        </Form.Group>

                        {/* Notes */}
                        <Form.Group as={Col} md="12" className="mb-3">
                            <div className="link-table-main">
                                <Form.Label>Notes</Form.Label>
                                <p className="text-paragraph">{notes || 'No Notes Available'}</p>
                            </div>
                        </Form.Group>

                        {/* Files */}
                        <Form.Group as={Col} md="12" className="mb-3">
                            <div className="link-table-main">
                                <Form.Label>Files</Form.Label>
                                <div className="listofFiles">
                                    {files && files.length > 0 ? (
                                        files.map((file, index) => (
                                            <div className="warrantyFile" key={index}>
                                                <div className="text">
                                                    <img src={pdfIcon} alt="pdfIcon" />
                                                    {file.split('/').pop()}
                                                </div>
                                                <div className="actions">
                                                    {/* Eye icon to open modal and view file */}
                                                    <span
                                                        onClick={() => handleViewFile(file)}
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <img src={eyeIcon} alt="View" />
                                                    </span>
                                                    {/* Download icon to download the file */}
                                                    <span
                                                        onClick={() => handleDownloadFile(file)}
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <img src={downloadIcon} alt="Download" />
                                                    </span>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No files available.</p>
                                    )}
                                </div>
                            </div>
                        </Form.Group>

                        {/* Images */}
                        <Form.Group as={Col} md="12" className="mb-3">
                            <div className="link-table-main ">
                                <Form.Label>Images</Form.Label>
                                <div className="listOfImages">
                                    {images && images.length > 0 ? (
                                        images.map((img, index) => (
                                            <div key={index}>
                                                <img src={img || placeholderImage} alt={`image-${index}`} />
                                            </div>
                                        ))
                                    ) : (
                                        <p>No images available.</p>
                                    )}
                                </div>
                            </div>
                        </Form.Group>

                        {/* Warranty Section */}
                        {warrantyData && warrantyData.length > 0 && (
                            <Form.Group md="12" className="mb-3">
                                <div className="link-table-main">
                                    <Form.Label>Warranty Data</Form.Label>
                                    <div className="warranty-section">
                                        {warrantyData.map((item, index) => {
                                            // Extract the first key-value pair from each item object
                                            const key = Object.keys(item)[0];
                                            const value = item[key];

                                            return (
                                                <div key={index}>
                                                    <span>
                                                        <b>{key.replace('_', ' ')}: </b>
                                                        {value || 'N/A'}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </Form.Group>
                        )}

                        {/* Warranty Files */}
                        {filesArray && filesArray.length > 0 && (
                            <Form.Group md="12" className="mb-3">
                                <div className="link-table-main">
                                    <Form.Label>Warranty Files</Form.Label>
                                    <div className="listofFiles">
                                        {filesArray.map((file, index) => (
                                            <div className="warrantyFile" key={index}>
                                                <div className="text">
                                                    <img src={pdfIcon} alt="pdfIcon" />
                                                    {file.split('/').pop()}
                                                </div>
                                                <div className="actions">
                                                    <span
                                                        onClick={() => handleViewFile(file)}
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <img src={eyeIcon} alt="View" />
                                                    </span>
                                                    <span
                                                        onClick={() => handleDownloadFile(file)}
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <img src={downloadIcon} alt="Download" />
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Form.Group>
                        )}
                    </Row>
                </Form>

                {/* Modal to View File */}
                <Modal show={showFileModal} onHide={() => setShowFileModal(false)} size="lg" centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>{fileToView && fileToView.endsWith('.pdf') ? <iframe src={fileToView} style={{ width: '100%', height: '500px' }} frameBorder="0" title="PDF Viewer" /> : <img src={fileToView} alt="File" style={{ width: '100%' }} />}</Modal.Body>
                    <Modal.Footer></Modal.Footer>
                </Modal>
            </div>
            <div className="side-footer form-actions d-flex justify-content-between gap-2">
                <Button
                    className="button-cancel"
                    onClick={() => {
                        handleClose();
                    }}
                >
                    Cancel
                </Button>
                <div className="d-flex gap-2">
                    <Button
                        className="delete-btn"
                        onClick={() => {
                            confirmDelete(async (result) => {
                                if (result.isConfirmed) {
                                    const resp = await deleteHomeEssentials(homeEssential.id);
                                    if (resp.status === 200) {
                                        toast.success('Home Essential Deleted Successfully');
                                        fetchHomeEssentials();
                                        dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetSearchResults', data: true } });
                                        dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetAllReminderEvents', data: true } });
                                        handleClose();
                                    } else {
                                        toast.error('Something went wrong');
                                    }
                                }
                            });
                        }}
                    >
                        Delete
                    </Button>
                    <Button
                        onClick={() => {
                            setShowEditHomeEssential(true);
                        }}
                        className="button-primary"
                        type="button"
                    >
                        Edit
                    </Button>
                </div>
            </div>
            <OffCanvasBootstrap
                placement="end"
                name="end"
                show={showEditHomeEssential}
                handleClose={() => {
                    setShowEditHomeEssential(false);
                }}
            >
                <HomeEssentialsSideNav
                    fetchHomeEssentials={fetchHomeEssentials}
                    fetchHomeEssential={fetchHomeEssential}
                    handlerCancelSideNav={() => {
                        setShowEditHomeEssential(false);
                    }}
                    formData={homeEssential}
                />
            </OffCanvasBootstrap>
        </div>
    );
};

export default HomeEssentialsSideNavView;
