import React, { useEffect, useState } from 'react';
import reminderNextbtn from '../../asstes/dash-icons/reminderNextbtn.svg';
import reminderPrvebtn from '../../asstes/dash-icons/reminderPrvebtn.svg';
import ReminderSideNavEdit from './reminderSideNavEdit';
import ReminderSideNavView from './reminderSideNavView';
import { addNotificationData, delete_notification, get_all_event_lists, get_event_lists, updateCustomReminder } from '../../../axiosCalls/userdashboard';
import { toast } from 'react-toastify';
import Loader from '../loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import types from '../../../redux/actions/actionTypes';
import OffCanvasBootstrap from '../../../off_canvas/OffCanvasBootstrap';

const RemindersCalendar = () => {
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [sideNavWidth, setSideNavWidth] = useState('0px');
    const [sideNavWidthOverlay, setSideNavWidthOverlay] = useState('0%');
    const [continueChange, setContinueChange] = useState('viewStep');
    const [newEvents, setEvents] = useState([]); // API events data
    const [loading, setLoading] = useState(true); // Loading state
    const currentDate = new Date();
    const [monthOffset, setMonthOffset] = useState(0); // New state to track month offset
    const [selectedEventId, setselectedEventId] = useState(null);
    const [reminderData, setReminderData] = useState([]);

    // Frequency mapping
    const frequencyMappings = {
        once: 1,
        daily: 1,
        weekly: 7,
        fortnightly: 14,
        monthly: 30,
        quarterly: 91, // Approximate
        six_months: 182, // Approximate
        yearly: 365,
        yearly_3: 365 * 3
    };

    // Color mapping based on frequency
    const colorMappings = {
        yearly_3: 'purple',
        yearly: 'purple',
        six_months: 'purple',
        daily: 'red',
        once: 'red',
        weekly: 'camel',
        fortnightly: 'camel',
        monthly: 'green',
        quarterly: 'green'
    };

    const isGetAllReminderEvents = useSelector((state) => state.root?.isGetAllReminderEvents);
    const dispatch = useDispatch();
    const fetchEvents = async () => {
        try {
            setLoading(true);
            const res = await get_all_event_lists();
            const formattedEvents = res.data.flatMap((event) => {
                // Combine the 'date' and 'time' fields and create a JavaScript Date object
                const eventDate = new Date(`${event.date}T${event.time}`);
                const frequency = event.reminder_frequency;
                const occurrences = [];

                const interval = frequencyMappings[frequency] || 0;
                const color = colorMappings[frequency] || 'green'; // Default to 'green' if not found

                // Handle "once" frequency separately
                if (frequency === 'once') {
                    occurrences.push({
                        id: event.ID,
                        title: event.title,
                        description: event.description,
                        type: color,
                        date: eventDate // Only show on the event date
                    });
                } else {
                    // Calculate maxOccurrences based on month offset for recurring events
                    const maxOccurrences = 84 + 42 * monthOffset; // 12 months plus offset

                    for (let i = 0; i < maxOccurrences; i++) {
                        const nextOccurrence = new Date(eventDate);
                        nextOccurrence.setDate(eventDate.getDate() + i * interval);
                        occurrences.push({
                            id: event.ID,
                            title: event.title,
                            description: event.description,
                            type: color,
                            date: nextOccurrence // For recurring events
                        });
                    }
                }
                return occurrences;
            });

            setEvents(formattedEvents);
            setLoading(false); // Stop loading once data is fetched
        } catch (error) {
            console.error('Error fetching events:', error);
            setLoading(false);
        } finally {
            setLoading(false); // Stop loading once data is fetched
        }
    };
    // Fetch API events data
    useEffect(() => {
        fetchEvents();
    }, [monthOffset]); // Re-fetch events when monthOffset changes

    // Fetch API events data
    useEffect(() => {
        if (isGetAllReminderEvents) {
            fetchEvents();
            dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetAllReminderEvents', data: false } });
        }
    }, [isGetAllReminderEvents]); // Re-fetch events when monthOffset changes

    // Navigation Handlers
    const nextMonth = () => {
        setMonthOffset((prevOffset) => prevOffset + 1); // Increase month offset
        if (currentMonth === 11) {
            setCurrentMonth(0);
            setCurrentYear((prevYear) => prevYear + 1);
        } else {
            setCurrentMonth((prevMonth) => prevMonth + 1);
        }
    };

    const prevMonth = () => {
        setMonthOffset((prevOffset) => Math.max(prevOffset - 1, 0)); // Decrease month offset but not below zero
        if (currentMonth === 0) {
            setCurrentMonth(11);
            setCurrentYear((prevYear) => prevYear - 1);
        } else {
            setCurrentMonth((prevMonth) => prevMonth - 1);
        }
    };

    // Calendar Date Generation
    const getDaysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();

    const getFirstDayOfMonth = (month, year) => new Date(year, month, 1).getDay();

    const generateCalendarDates = (month, year) => {
        const daysInCurrentMonth = getDaysInMonth(month, year);
        const firstDayOfMonth = getFirstDayOfMonth(month, year);
        const prevMonthDays = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;
        const prevMonth = month === 0 ? 11 : month - 1;
        const prevMonthYear = month === 0 ? year - 1 : year;
        const daysInPrevMonth = getDaysInMonth(prevMonth, prevMonthYear);
        const calendarDates = [];

        for (let i = prevMonthDays; i > 0; i--) {
            calendarDates.push(new Date(prevMonthYear, prevMonth, daysInPrevMonth - i + 1));
        }
        for (let i = 1; i <= daysInCurrentMonth; i++) {
            calendarDates.push(new Date(year, month, i));
        }
        const nextMonthDays = 42 - calendarDates.length;
        const nextMonth = month === 11 ? 0 : month + 1;
        const nextMonthYear = month === 11 ? year + 1 : year;
        for (let i = 1; i <= nextMonthDays; i++) {
            calendarDates.push(new Date(nextMonthYear, nextMonth, i));
        }
        return calendarDates;
    };

    const currentMonthDates = generateCalendarDates(currentMonth, currentYear);

    // Get events for a particular date
    const getEventsForDate = (date) => newEvents.filter((event) => event.date.toDateString() === date.toDateString());

    const [showReminderView, setShowReminderView] = useState(false);

    // Side Navigation Handlers
    const handlerReminderSideNave = (e) => {
        setselectedEventId(e.id);
        setShowReminderView(true);
    };

    const handlerCancelSideNav = () => {
        setSideNavWidth('sidenavPopuWidthHide');
        setSideNavWidthOverlay('0%');
        document.body.classList.remove('no-scroll');
    };

    const handlerChangeToEdit = () => {
        setContinueChange('editStep');
    };

    // Render loading state
    if (loading) {
        return <Loader />;
    }

    const handlerChangeStep = async () => {
        try {
            const responseCustomReminder = await updateCustomReminder(reminderData);
            const response = await addNotificationData(reminderData, responseCustomReminder.data.post_id);
            if (response && response.data) {
                if (response.data.status == 400) {
                    toast.error(response.message);
                } else {
                    toast.success(response.data.message);
                    fetchEvents();
                    handlerCancelSideNav();
                }
            }
        } catch (error) {}
    };

    const suggestedReminderAdd = (data) => {
        setReminderData(data);
    };
    const handlerDeleteCalendar = async () => {
        // Confirmation before deletion
        const confirmed = window.confirm('Are you sure you want to delete this event?');
        if (!confirmed) return;

        try {
            const response = await delete_notification(selectedEventId);
            if (response.status === 200) {
                toast.error(response.data.message);
                handlerCancelSideNav();
                fetchEvents(); // Fetch events again after deletion to refresh the list
            } else {
                toast.error('Something went wrong');
            }
        } catch (error) {
            toast.error('Error deleting event');
            console.error(error);
        }
    };
    if (loading) {
        <Loader />;
    }

    return (
        <div>
            <div className="reminder-navigation">
                <h2 className="card-heading">
                    <span>{new Date(currentYear, currentMonth).toLocaleString('default', { month: 'long', year: 'numeric' })}</span>
                    <img onClick={prevMonth} src={reminderPrvebtn} alt="Previous Month" />
                    <img onClick={nextMonth} src={reminderNextbtn} alt="Next Month" />
                </h2>
            </div>

            <div className="parent-upcomingDateRow">
                <div className="row upcomingDateRow">
                    {currentMonthDates.map((date, index) => (
                        <div className={`dayBox ${date.toDateString() === currentDate.toDateString() ? 'current-day' : ''}`} key={index}>
                            <div className="customDates">
                                <span>
                                    {date.toLocaleString('default', {
                                        month: 'short',
                                        day: '2-digit'
                                    })}
                                </span>
                                <span className="day">
                                    {date.toLocaleString('en-us', {
                                        weekday: 'short'
                                    })}
                                </span>
                            </div>

                            <div className="event-list">
                                {getEventsForDate(date).map((event) => (
                                    <div onClick={() => handlerReminderSideNave(event)} className="parent-event-title" key={event.id}>
                                        {/* show index */}
                                        <div dangerouslySetInnerHTML={{ __html: `${event.title} <br /> ${event.date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}` }} className={`event-calender-title break-text type-sub-${event.type}`} />
                                        {/* <h6 className="text-white">{event.title}</h6>
                                        <div className={`event-calender-des type-sub-${event.type}`}>
                                            {event.description}
                                            <br />
                                            {event.date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                                        </div> */}
                                    </div>
                                ))}
                            </div>

                            <div className="event_count">
                                <a href="#">Events: {getEventsForDate(date).length}</a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <OffCanvasBootstrap
                placement="end"
                name="end"
                closeButton
                show={showReminderView}
                handleClose={() => {
                    setShowReminderView(false);
                }}
            >
                <ReminderSideNavView
                    handleClose={() => {
                        setShowReminderView(false);
                    }}
                    eventID={selectedEventId}
                />
            </OffCanvasBootstrap>
        </div>
    );
};

export default RemindersCalendar;
