import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import DataTable, { Alignment, Direction } from 'react-data-table-component';
import LinkReminders from '../inc/linkReminders';
import { Select } from '../inc/Select';
import pdfIcon from '../../asstes/dash-icons/pdfIcon.svg';
import { ReactComponent as DelIcon } from '../../asstes/dash-icons/deleteIcon.svg';
import { ReactComponent as EditIcon } from '../../asstes/dash-icons/edit-icon.svg';
import { fetchHomeEssentials, getCustomReminders, getProjectsList } from '../../../axiosCalls/userdashboard';
import ScrollToError from '../input/ScrollToError';
import AddlineItem from '../inc/addlineItem';
import AddRemindertModal from '../modals/AddReminderModal';
import checkedIcon from '../../asstes/dash-icons/checkedIcon.svg'; // Add a checked icon for selection
import plusIcon from '../../asstes/dash-icons/plusIcon.svg';
import Loader from '../loader/Loader';
import ReminderSelector from '../reminders_selector/ReminderSelector';

const CreateService = ({ values, errors, handleChange, setFieldValue, touched }) => {
    const getFileIcon = (file) => {
        if (typeof file === 'string' && file.endsWith('pdf')) {
            return pdfIcon;
        } else if (typeof file === 'string') {
            return file;
        }
        if (file.type === 'application/pdf') {
            return pdfIcon;
        } else if (file instanceof File) {
            return URL.createObjectURL(file);
        }
    };
    const handleReminderClick = (reminder) => {
        // Update reminderIds in initialState
        const updatedReminders = values?.reminders?.map((r) => r.id)?.includes(reminder.id) ? values?.reminders?.filter((r) => r.id !== reminder.id) : [...(values.reminders || []), reminder];
        setFieldValue('reminders', updatedReminders);
    };

    const [homeEssentials, setHomeEssentials] = useState([]); // State for home essentials
    const [projectOptions, setProjectOptions] = useState([]);

    const fetchEssentials = async () => {
        try {
            const response = await fetchHomeEssentials(); // Assuming this function returns the home essentials data
            const optionsData = response.data.items.map((item) => ({
                label: `${item.type} - ${item.model_number}`, // Display type and model number as label
                value: item.id // ID as value
            }));
            setHomeEssentials(optionsData);
        } catch (error) {
            console.error('Error fetching home essentials:', error);
        }
    };

    const fetchProjectList = async () => {
        try {
            const response = await getProjectsList();
            const projectOptions = response.data.map((project) => ({
                value: project.ID,
                label: project.title
            }));
            // Update the state with the project options
            setProjectOptions(projectOptions);
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };

    useEffect(() => {
        fetchEssentials();
        fetchProjectList();
    }, []);

    const [selectedIndex, setSelectedIndex] = useState(null);

    const columns = [
        {
            name: 'Service Name',
            selector: (row) => row.name,
            sortable: false,
            reorder: true
        },
        {
            name: 'Make',
            selector: (row) => row.make || 'N/A',
            sortable: false,
            reorder: true
        },
        {
            name: 'Model Number',
            selector: (row) => row.modelNumber || 'N/A',
            sortable: false,
            reorder: true
        },
        {
            name: 'Service Price',
            selector: (row) => row.price,
            sortable: false,
            reorder: true
        },
        {
            name: 'Action',
            // selector: (row) => row.action,
            sortable: false,
            cell: (row, index) => (
                <div className="d-flex gap-1">
                    <div
                        className="BtnAction"
                        onClick={() => {
                            setSelectedIndex(index);
                            setShowItemModal(true);
                        }}
                    >
                        <EditIcon />
                    </div>
                    <div
                        className="BtnAction"
                        onClick={() => {
                            const updatedLineItems = values?.lineItems?.filter((_, i) => i !== index);
                            setFieldValue('lineItems', updatedLineItems);
                        }}
                    >
                        <DelIcon />
                    </div>
                </div>
            ),
            reorder: true
        }
    ];

    const [showItemModal, setShowItemModal] = useState(false);

    useEffect(() => {
        // set amount sum of al prices on line item change
        const totalAmount = values?.lineItems?.reduce((acc, item) => {
            return acc + parseFloat(item.price || 0);
        }, 0);
        setFieldValue('amount', totalAmount);
    }, [setFieldValue, values?.lineItems]);
    return (
        <>
            <Row className="mb-3">
                <ScrollToError />
                {/* <Form.Group as={Col} md="12" className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>
                        Service Name <span className="requiredStar">*</span>
                    </Form.Label>
                    <Form.Control name="serviceName" type="text" placeholder="Furnace" value={values?.serviceName || ''} isInvalid={touched?.serviceName && errors.serviceName} onChange={handleChange} />
                    <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                </Form.Group> */}

                <Form.Group as={Col} md="12" className="mb-3">
                    <Form.Label>
                        Vendor Name <span className="requiredStar">*</span>
                    </Form.Label>
                    <Form.Control name="vendorName" type="text" value={values?.vendorName || ''} placeholder="Home Depot" isInvalid={touched?.vendorName && errors.vendorName} onChange={handleChange} />
                    <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                </Form.Group>

                <DataTable
                    columns={columns}
                    data={values?.lineItems || []}
                    defaultSortFieldId={0}
                    // onRowClicked={handleRowSideNavOpen} // Attach click event handler
                    pagination={false}
                    highlightOnHover={true}
                    pointerOnHover={true}
                    persistTableHead={true}
                    // fixedHeaderScrollHeight={"300px"}
                    // progressPending={loading} // Show loader while data is being fetched
                    noTableHead={false}
                    noContextMenu={false}
                    direction={Direction.AUTO}
                    subHeader={false}
                    subHeaderAlign={Alignment.LEFT}
                    subHeaderWrap={true}
                    responsive={true}
                />
                <div className="reminder-top-button ">
                    <button
                        className="filled-btn ms-auto"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            setSelectedIndex(null);
                            setShowItemModal(true);
                        }}
                    >
                        Add Item
                    </button>
                </div>
                <Form.Group as={Col} md="6" className="mb-3">
                    <Form.Label>
                        Service date <span className="requiredStar">*</span>
                    </Form.Label>
                    <Form.Control name="serviceDate" type="date" placeholder="Name" value={values?.serviceDate || ''} isInvalid={touched?.serviceDate && errors.serviceDate} onChange={handleChange} />
                    <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" className="mb-3 position-relative">
                    <Form.Label>
                        Amount <span className="requiredStar">*</span>
                    </Form.Label>

                    <Form.Control name="amount" type="text" value={values?.amount || ''} placeholder="4,434.32" isInvalid={touched?.amount && errors.amount} onChange={handleChange} />
                    <span className="amount-icon">
                        <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6.5 6.5L5.5 6.5L5.5 2.5L6 2.5C6.53043 2.5 7.03914 2.71072 7.41421 3.08579C7.78929 3.46086 8 3.96957 8 4.5C8 4.63261 8.05268 4.75979 8.14644 4.85356C8.24021 4.94732 8.36739 5 8.5 5C8.63261 5 8.75978 4.94732 8.85355 4.85356C8.94732 4.75979 9 4.63261 9 4.5C8.99917 3.70461 8.68284 2.94203 8.12041 2.3796C7.55798 1.81717 6.79539 1.50083 6 1.5L5.5 1.5L5.5 0.500001C5.5 0.367393 5.44732 0.240216 5.35355 0.146448C5.25979 0.0526796 5.13261 1.21499e-06 5 1.18021e-06C4.86739 1.14543e-06 4.74021 0.0526795 4.64645 0.146448C4.55268 0.240216 4.5 0.367393 4.5 0.500001L4.5 1.5L4 1.5C3.20435 1.5 2.44129 1.81607 1.87868 2.37868C1.31607 2.94129 0.999999 3.70435 0.999999 4.5C0.999999 5.29565 1.31607 6.05871 1.87868 6.62132C2.44129 7.18393 3.20435 7.5 4 7.5L4.5 7.5L4.5 11.5L3.5 11.5C2.96956 11.5 2.46086 11.2893 2.08578 10.9142C1.71071 10.5391 1.5 10.0304 1.5 9.5C1.5 9.36739 1.44732 9.24021 1.35355 9.14645C1.25978 9.05268 1.13261 9 0.999998 9C0.867389 9 0.740213 9.05268 0.646444 9.14645C0.552676 9.24021 0.499998 9.36739 0.499998 9.5C0.500825 10.2954 0.817161 11.058 1.37959 11.6204C1.94202 12.1828 2.7046 12.4992 3.5 12.5L4.5 12.5L4.5 13.5C4.5 13.6326 4.55267 13.7598 4.64644 13.8536C4.74021 13.9473 4.86739 14 5 14C5.1326 14 5.25978 13.9473 5.35355 13.8536C5.44732 13.7598 5.5 13.6326 5.5 13.5L5.5 12.5L6.5 12.5C7.29565 12.5 8.05871 12.1839 8.62132 11.6213C9.18393 11.0587 9.5 10.2957 9.5 9.5C9.5 8.70435 9.18393 7.94129 8.62132 7.37868C8.05871 6.81607 7.29565 6.5 6.5 6.5ZM4 6.5C3.46957 6.5 2.96086 6.28929 2.58578 5.91421C2.21071 5.53914 2 5.03043 2 4.5C2 3.96957 2.21071 3.46086 2.58579 3.08579C2.96086 2.71071 3.46957 2.5 4 2.5L4.5 2.5L4.5 6.5L4 6.5ZM6.5 11.5L5.5 11.5L5.5 7.5L6.5 7.5C7.03043 7.5 7.53914 7.71072 7.91421 8.08579C8.28928 8.46086 8.5 8.96957 8.5 9.5C8.5 10.0304 8.28928 10.5391 7.91421 10.9142C7.53914 11.2893 7.03043 11.5 6.5 11.5Z"
                                fill="#535860"
                            />
                        </svg>
                    </span>
                    <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                </Form.Group>

                <Form.Group md="12" className="mb-3">
                    <div className="files-choosen">
                        <div className="title">Add Receipt</div>

                        <div className="button">
                            <div className="outline-yellow-button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673" stroke="#B78C4E" strokeLinecap="round" />
                                </svg>
                                Add file
                                <input
                                    type="file"
                                    name="receipt"
                                    multiple
                                    accept=".png,.pdf,.jpg,.jpeg"
                                    className="chooeseFile"
                                    onChange={(e) => {
                                        const files = Array.from(e.target.files);
                                        const listOfReceipts = values?.listOfReceipts || [];
                                        const updatedListOfReceipts = [...listOfReceipts, ...files];
                                        setFieldValue('listOfReceipts', updatedListOfReceipts);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="listOfImages">
                        {values?.listOfReceipts?.map((file, index) => (
                            <div className="image-choosed" key={index}>
                                <a href={typeof file === 'string' ? file : URL.createObjectURL(file)} target="_blank" rel="noopener noreferrer">
                                    <img src={getFileIcon(file)} alt="Image file" />
                                </a>
                                <button
                                    onClick={() => {
                                        const updatedList = values?.listOfReceipts?.filter((f, i) => i !== index);
                                        setFieldValue('listOfReceipts', updatedList);
                                    }}
                                    className="image-choosed-crossed"
                                >
                                    &times;
                                </button>
                            </div>
                        ))}
                    </div>
                </Form.Group>

                <Form.Group md=" 12" className="mb-3">
                    <div className="files-choosen">
                        <div className="title">Files</div>

                        <div className="button">
                            <div className="outline-yellow-button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673" stroke="#B78C4E" strokeLinecap="round" />
                                </svg>
                                Add file
                                <input
                                    type="file"
                                    name="files"
                                    multiple
                                    accept=".png,.pdf,.jpg,.jpeg"
                                    className="chooeseFile"
                                    onChange={(e) => {
                                        const files = Array.from(e.target.files);
                                        const listOfFiles = values?.listOfFiles || [];
                                        const updatedFiles = [...listOfFiles, ...files];
                                        setFieldValue('listOfFiles', updatedFiles);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="listOfImages">
                        {values?.listOfFiles?.map((file, index) => (
                            <div className="image-choosed" key={index}>
                                <a href={typeof file === 'string' ? file : URL.createObjectURL(file)} target="_blank" rel="noopener noreferrer">
                                    <img src={getFileIcon(file)} alt="Image file" />
                                </a>
                                <button
                                    onClick={() => {
                                        const updatedList = values?.listOfFiles?.filter((f, i) => i !== index);
                                        setFieldValue('listOfFiles', updatedList);
                                    }}
                                    className="image-choosed-crossed"
                                >
                                    &times;
                                </button>
                            </div>
                        ))}
                    </div>
                </Form.Group>

                {/* <>
                    <ReminderSelector handleReminderClick={handleReminderClick} selectedReminders={(values?.reminders && values?.reminders?.length > 0 && values?.reminders?.map((r) => r.id)) || []} />
                </> */}

                <Form.Group as={Col} md="12" className="mb-3">
                    <Form.Label>Linked Home Essential</Form.Label>
                    <Select
                        multiple
                        options={homeEssentials} // Updated options to use home essentials from API
                        value={values?.linked_home_essential || []}
                        onChange={(selected) => {
                            setFieldValue('linked_home_essential', selected);
                        }} // Use the dedicated handler
                    />
                </Form.Group>

                <Form.Group as={Col} md="12" className="mb-3">
                    <Form.Label>Project</Form.Label>
                    <Form.Select aria-label="Default select example" name="project" value={values?.project || ''} onChange={handleChange}>
                        <option value="">Select Project</option>
                        {projectOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col} md="12" className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Notes</Form.Label>
                    <Form.Control as="textarea" rows={3} name="notes" value={values?.notes || ''} placeholder="Type here..." onChange={handleChange} />
                </Form.Group>

                <Form.Group md="12" className="mb-3">
                    <div className="files-choosen warrantyAddArea">
                        <div className="title">Warranty</div>

                        <div className="button">
                            <label className="switch">
                                <input
                                    checked={values?.warranty}
                                    type="checkbox"
                                    name="warranty"
                                    onChange={(e) => {
                                        setFieldValue('warranty', e.target.checked);
                                    }}
                                />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                    <div className="warrantyForm">
                        {values?.warranty ? (
                            <div className="warrantyAddInnerArea">
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6" className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Warranty Name</Form.Label>
                                        <Form.Control value={values?.warrantyName || ''} type="text" placeholder="Name" name="warrantyName" onChange={handleChange} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" className="mb-3">
                                        <Form.Label>Warranty Number</Form.Label>
                                        <Form.Control value={values?.warrantyNumber || ''} type="text" placeholder="Number" name="warrantyNumber" onChange={handleChange} />
                                    </Form.Group>

                                    <Form.Group md="12" className="mb-3">
                                        <div className="files-choosen">
                                            <div className="title">Files</div>

                                            <div className="button">
                                                <div className="outline-yellow-button">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673" stroke="#B78C4E" strokeLinecap="round" />
                                                    </svg>
                                                    Add file
                                                    <input
                                                        type="file"
                                                        name="warrantyFiles"
                                                        accept=".png,.pdf,.jpg,.jpeg"
                                                        multiple
                                                        className="chooeseFile"
                                                        onChange={(e) => {
                                                            const files = Array.from(e.target.files);
                                                            const listOfWarrantyFiles = values?.files?.warrantyFiles || [];
                                                            const updatedListOfWarrantyFiles = [...listOfWarrantyFiles, ...files];
                                                            setFieldValue('listOfWarrantyFiles', updatedListOfWarrantyFiles);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="listOfImages">
                                            {values?.listOfWarrantyFiles?.map((file, index) => (
                                                <div className="image-choosed" key={index}>
                                                    <a href={typeof file === 'string' ? file : URL.createObjectURL(file)} target="_blank" rel="noopener noreferrer">
                                                        <img src={getFileIcon(file)} alt="Image file" />
                                                    </a>
                                                    <button
                                                        onClick={() => {
                                                            const updatedList = values?.listOfWarrantyFiles?.filter((f, i) => i !== index);
                                                            setFieldValue('listOfWarrantyFiles', updatedList);
                                                        }}
                                                        className="image-choosed-crossed"
                                                    >
                                                        &times;
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md="6" className="mb-3">
                                        <Form.Label>
                                            Start <span className="requiredStar">*</span>
                                        </Form.Label>
                                        <Form.Control isInvalid={touched?.warrantyStart && errors?.warrantyStart} value={values?.warrantyStart || ''} type="date" placeholder="Name" name="warrantyStart" onChange={handleChange} />
                                        {errors?.warrantyStart && touched?.warrantyStart ? <Form.Control.Feedback type="invalid">{errors?.warrantyStart}</Form.Control.Feedback> : null}
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" className="mb-3">
                                        <Form.Label>
                                            End <span className="requiredStar">*</span>
                                        </Form.Label>
                                        <Form.Control isInvalid={touched?.warrantyEnd && errors?.warrantyEnd} value={values?.warrantyEnd || ''} type="date" name="warrantyEnd" placeholder="Name" onChange={handleChange} />
                                        {errors?.warrantyEnd && touched?.warrantyEnd ? <Form.Control.Feedback type="invalid">{errors?.warrantyEnd}</Form.Control.Feedback> : null}
                                    </Form.Group>
                                </Row>
                                <Form.Group as={Col} className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Notes</Form.Label>
                                    <Form.Control as="textarea" rows={3} name="warrantyNotes" value={values?.warrantyNotes || ''} placeholder="Type here..." onChange={handleChange} />
                                </Form.Group>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </Form.Group>
            </Row>

            <AddlineItem
                viewOnly={false}
                type="service"
                selectedIndex={selectedIndex}
                formValues={values}
                setFieldValue={setFieldValue}
                show={showItemModal}
                onClose={() => {
                    setShowItemModal(false);
                }}
            />
        </>
    );
};

export default CreateService;
