import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

const DeactivateAccountSideNav = () => {
    return (
        <div className="side-nav">
            <div className="side-header">
                <h3>Deactivate Account</h3>
                <h5>
                    {/* Are you sure you want to deactivate your account? Please type <b>‘Deactivate’</b> to confirm your decision. */}
                    Are you sure you want to deactivate your account?
                </h5>
                <ul className="deactivate-terms">
                    <li>You will no longer receive service or have access to your account unless your account is reactivated.</li>
                    <li>Future billing will stop until the account is reactivated.</li>
                    <li>Your account and data is retained for 30 days, as stated in Remind's Terms & Conditions and Privacy Policy. After this retention period, your data is permanently deleted and cannot be restored.</li>
                </ul>
                <p className="deactivate-terms-confirm">
                    Please select the <strong style={{ color: '#ae3838' }}>'Deactivate'</strong> button to confirm your decision.
                </p>

                <p className="deaccount-notice mt-2">
                    <b>Warning!</b> This action cannot be cancelled !
                </p>
            </div>
            <div className="side-body">
                <Form>
                    <Row>
                        {/* <Form.Group as={Col} md="12" className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Confirm</Form.Label>
                            <Form.Control type="text" placeholder="For confirm type Deactivate" />
                        </Form.Group> */}
                    </Row>
                </Form>
            </div>
        </div>
    );
};

export default DeactivateAccountSideNav;
