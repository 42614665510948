import React from 'react';
import DataTable, { Alignment, Direction } from 'react-data-table-component';
import { formatDate } from '../../utils/helper';
import { Badge } from 'react-bootstrap';

const PropertyWarranties = ({ propertyWarranties, pagination }) => {
    const columnAPropertyWarranty = [
        {
            name: 'Warranty',
            selector: (row) => <div dangerouslySetInnerHTML={{ __html: row.warranty_name }} />,
            sortable: true,
            reorder: true
        },
        {
            name: 'Start date',
            selector: (row) => formatDate(row.start_date),
            sortable: true,
            reorder: true
        },
        {
            name: 'End date',
            selector: (row) => formatDate(row.end_date),
            sortable: true,
            reorder: true
        },
        {
            name: 'Note',
            selector: (row) => <div dangerouslySetInnerHTML={{ __html: row.note }} />,
            sortable: true,
            reorder: true
        },
        {
            name: 'File name',
            selector: (row) => <div dangerouslySetInnerHTML={{ __html: row.Filename }} />,
            sortable: true,
            reorder: true
        },
        {
            name: 'Type',
            selector: (row) => <span className={`project-tag tag-red`}>{row.type}</span>,
            sortable: true,
            reorder: true
        }
    ];

    return (
        <div className="col-md-12 mb-3" id="property-warranty">
            <label className="reportSectiontitile">Property Warranties</label>
            <div className="recentPurcheseTable">
                <DataTable
                    columns={columnAPropertyWarranty}
                    data={propertyWarranties}
                    defaultSortFieldId={2}
                    selectableRows={false}
                    selectableRowsNoSelectAll={false}
                    selectableRowsVisibleOnly={false}
                    selectableRowsHighlight={false}
                    selectableRowsSingle={false}
                    expandableRows={false}
                    expandOnRowClicked={false}
                    expandOnRowDoubleClicked={false}
                    expandableRowsHideExpander={false}
                    pagination={pagination}
                    paginationPerPage={5}
                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                    highlightOnHover={true}
                    striped={false}
                    pointerOnHover={false}
                    dense={false}
                    persistTableHead={true}
                    noHeader={false}
                    fixedHeader={false}
                    fixedHeaderScrollHeight={'300px'}
                    progressPending={false}
                    noTableHead={false}
                    noContextMenu={false}
                    direction={Direction.AUTO}
                    subHeader={false}
                    subHeaderAlign={Alignment.RIGHT}
                    subHeaderWrap={true}
                    responsive={true}
                    disabled={false}
                    className="proteryReport"
                />
            </div>
        </div>
    );
};

export default PropertyWarranties;
