import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from '../../user-dashboard/asstes/images/logo.png';

// Function to encode special characters to HTML entities
const htmlEntities = (str) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = str;
    return tempDiv.textContent || tempDiv.innerText || '';
};

const drawTable = (doc, rows, headers) => {
    // Ensure headers and row values are HTML encoded for safe rendering
    headers = headers.map(htmlEntities);
    rows = rows.map((row) => row.map(htmlEntities));

    // Identify the "amount" column index
    const amountColumnIndex = headers.findIndex((header) => header.toLowerCase() === 'amount' || header.toLowerCase() === 'cost');

    if (amountColumnIndex === -1) {
        console.warn('No "amount" column found in headers. Skipping total calculation.');
    } else {
        // Calculate the total amount
        const totalAmount = rows.reduce((total, row) => {
            const cellValue = row[amountColumnIndex]?.replace('$', '').trim(); // Remove $ and trim spaces
            const amount = parseFloat(cellValue);
            return total + (isNaN(amount) ? 0 : amount);
        }, 0);

        // Add Total Row
        const totalRow = headers.map((_, index) => (index === amountColumnIndex ? `Total: $${totalAmount.toFixed(2)}` : index === 0 ? 'TOTAL' : ''));
        rows.push(totalRow);
    }

    // Generate the table
    doc.autoTable({
        head: [headers],
        body: rows,
        startY: 30,
        styles: {
            fontSize: 8,
            cellPadding: 2,
            overflow: 'linebreak'
        },
        headStyles: {
            fillColor: [40, 120, 180],
            textColor: [255, 255, 255]
        },
        columnStyles: {
            0: { cellWidth: 'auto' } // Ensure columns adjust dynamically
        },
        theme: 'grid',
        didDrawPage: (data) => {
            // Footer - Page Number
            const pageHeight = doc.internal.pageSize.height;
            doc.setFontSize(10);
            doc.text(`Page ${doc.internal.getNumberOfPages()}`, data.settings.margin.left, pageHeight - 10);
        }
    });
};

const addHeader = (doc, { start_date, end_date }) => {
    // Add Logo
    const logoUrl = logo; // Replace with your logo's path or Base64 string
    const imgWidth = 10;
    const imgHeight = 10;
    doc.addImage(logoUrl, 'PNG', 15, 10, imgWidth, imgHeight);

    // Header - Company Name
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(12);
    doc.text('Remind-Home', 28, 17);

    // Add Dates if provided
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);

    // Text for start and end dates
    const startText = start_date ? `Start Date: ${start_date}` : 'Start Date: N/A';
    const endText = end_date ? `End Date: ${end_date}` : 'End Date: N/A';

    // Define the starting position for dates
    const startX = 250; // Adjust the horizontal position as per your layout
    const startY = 15; // Align with the logo and company name vertically

    // Add start and end dates to the right of the logo and company name
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(8);
    doc.text(startText, startX, startY);
    doc.text(endText, startX + 0.5, startY + 5); // Adjust Y for spacing between the dates
};

const getFileType = (fileUrl) => {
    if (!fileUrl) return 'unknown'; // Handle empty or undefined URLs
    const fileExtension = fileUrl?.split('.').pop().toLowerCase();

    if (fileExtension === 'pdf') {
        return 'pdf';
    } else if (['png', 'jpg', 'jpeg', 'gif'].includes(fileExtension)) {
        return 'image';
    }
    // Add more cases as needed
    return 'unknown'; // Default to unknown type
};

// all above data into single file, purchases, services, home essentials, tasks, files, projects, property warranties
const downloadAllPDF = async ({ start_date, end_date, purchases, services, homeEssentials, tasks, projects, files, property_warranties }) => {
    const doc = new jsPDF('landscape');
    let isFirstPage = true; // Flag to track the first page

    // Helper function to process and add a section
    const addSection = (title, data) => {
        if (!data || data.length === 0) return;

        if (!isFirstPage) {
            doc.addPage(); // Start a new page for subsequent sections
        } else {
            isFirstPage = false; // First section uses the initial page
        }

        // Add Logo
        addHeader(doc, { start_date, end_date });

        // Add Section Title
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(18);
        doc.text(title, doc.internal.pageSize.getWidth() / 2, 25, { align: 'center' });

        // Process Data
        const processedData = data.map((item) => {
            const parsedItem = { ...item };
            return parsedItem;
        });

        // Table Headers
        const headers = Object.keys(processedData[0]).map((key) => ({
            header: key.replace(/_/g, ' ').toUpperCase(),
            dataKey: key
        }));

        // Table Rows
        const rows = processedData.map((item) => {
            const row = {};
            headers.forEach(({ dataKey }) => {
                const value = item[dataKey];
                row[dataKey] = typeof value === 'string' && value.length > 50 ? `${value.slice(0, 47)}...` : value || 'N/A';
            });
            return row;
        });

        // Add Table
        drawTable(
            doc,
            rows.map((row) => headers.map((header) => row[header.dataKey])),
            headers.map((header) => header.header)
        );
    };

    // Add Sections
    if (purchases && purchases.length > 0)
        addSection(
            'Purchases',
            purchases?.map((item) => ({
                title: item.title,
                purchase_date: item.purchase_date,
                amount: item.amount,
                notes: item.notes,
                project_name: item.project_name
            }))
        );
    if (services && services.length > 0)
        addSection(
            'Services',
            services.map((item) => ({ vendor: item.vendor, service_date: item.service_date, amount: item.amount, notes: item.notes, project_name: item.project_name }))
        );
    if (homeEssentials && homeEssentials.length > 0)
        addSection(
            'Home Essentials',
            homeEssentials.map((item) => ({
                'Home Essential Name': item.title,
                Type: item.type_name,
                'Purchase Date': item.purchase_date,
                'Age of Home Essential': item.age,
                ...(item.warranty && {
                    'Warranty Name': item.warranty_name,
                    'Warranty Start': item.warranty_start_date,
                    'Warranty End': item.warranty_end_date
                })
            }))
        );
    if (tasks && tasks.length > 0)
        addSection(
            'Tasks',
            tasks
                ?.filter((item) => item.status_task === 'completed')
                .map((item) => ({
                    title: item.title,
                    date: item.date,
                    status: item.status_task
                }))
        );
    if (projects && projects.length > 0)
        addSection(
            'Projects',
            projects?.map((item) => ({ title: item.title, cost: `$${item.cost}`, start_date: item.start_date, end_date: item.end_date, notes: item.notes }))
        );
    if (files && files.length > 0)
        addSection(
            'Files',
            files?.map((item) => ({ 'File Name': item.file_name, Type: item.file_url && item.file_url?.length >= 0 ? getFileType(item.file_url[0]) : 'N/A', Date: item.upload_date }))
        );
    if (property_warranties && property_warranties.length > 0)
        addSection(
            'Property Warranties',
            property_warranties.map((item) => ({
                'Warranty Name': item.warranty_name,
                'Warranty Number': item.number,
                'File Name': item.files.join(', '),
                'Start Date': item.start_date,
                'End Date': item.end_date,
                Notes: item.note,
                'Linked To': item.type
            }))
        );

    // Save PDF
    const arrays = {
        purchases,
        services,
        homeEssentials,
        tasks,
        projects,
        files,
        property_warranties
    };

    // Filter arrays that are defined and have at least one element
    const arrayNames = Object.keys(arrays).filter((key) => arrays[key] && arrays[key].length > 0);
    // Determine the PDF name
    const pdfName = arrayNames.length === 1 ? arrays[arrayNames[0]][0]?.report_type : 'Complete Report';
    doc.save(`${pdfName}-${new Date().toISOString()}.pdf`);
};

export { downloadAllPDF };
