import React, { useEffect, useState } from 'react';
import '../asstes/styles/loginStyles.css';
import appleIcon from '../asstes/dash-icons/auth/appleIcon.svg';
import fbIcon from '../asstes/dash-icons/auth/fbIcon.svg';
import XIcon from '../asstes/dash-icons/auth/XIcon.svg';
import googleIcon from '../asstes/dash-icons/auth/googleIcon.svg';
import EyeSlash from '../asstes/dash-icons/auth/EyeSlash.svg';
import EyeSlashCross from '../asstes/dash-icons/auth/EyeSlashCross.svg';
import { Link, useNavigate } from 'react-router-dom';
import { InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { loginApi, loginWithGoogle } from '../../axiosCalls/auth';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { getToken } from 'firebase/messaging';
import { messaging } from '../../firebase';
import { save_device_token } from '../../axiosCalls/userdashboard';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPass, setShowPass] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleShowPassword = () => {
        setShowPass((prevShowPass) => !prevShowPass);
    };

    const validateEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const requestNotificationPermission = async () => {
        const permission = Notification.permission;

        if (permission === 'granted') {
            return true; // No need to prompt
        }

        if (permission === 'denied') {
            return false; // Permission already denied
        }

        if (permission === 'default') {
            // Show custom UI to explain the benefit of notifications
            const userAccepted = window.confirm('We’d like to send you notifications to keep you updated. Please allow notifications.');

            if (userAccepted) {
                try {
                    const result = await Notification.requestPermission();
                    if (result === 'granted') {
                        return true;
                    } else {
                        return false;
                    }
                } catch (error) {
                    return false;
                }
            } else {
                return false;
            }
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        if (!email || !password) {
            toast.error('Please fill in all fields.');
            return;
        }

        if (!validateEmail(email)) {
            toast.error('Please enter a valid email address.');
            return;
        }

        setLoading(true);
        try {
            const response = await loginApi(email, password);

            if (response && response.data) {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('user', JSON.stringify(response.data));

                // Ask for notification permission
                const notificationsAllowed = await requestNotificationPermission();

                if (notificationsAllowed) {
                    const deviceToken = await getToken(messaging, {
                        vapidKey: process.env.REACT_APP_FIREBASE_VAP_ID_KEY
                    });

                    if (deviceToken) {
                        const _resp = await save_device_token({ device_token: deviceToken });
                    }
                }
                const user = response.data;
                let redirectUrl = '/dashboard'; // Default redirect URL
                if (user.role === 'homeowner') {
                    redirectUrl = '/dashboard';
                } else if (user.role === 'affiliate') {
                    redirectUrl = '/seller/overview';
                } else if (user.role === 'homeowner_affiliate') {
                    redirectUrl = '/dashboard';
                }

                navigate(redirectUrl);
            } else {
                toast.error('Invalid login response.');
            }
        } catch (error) {
            console.error('Login Error:', error);
            toast.error('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="auth-form-light text-left py-5 px-4">
            <div className="brand-logo">
                <h2 className="auth-title">Log In</h2>
                <div className="auth-logos">
                    <a href={`${process.env.REACT_APP_SITE_URL}/remindhome-login/?loginSocial=google`}>
                        <img src={googleIcon} alt="googleIcon" />
                    </a>

                    <a href={`${process.env.REACT_APP_SITE_URL}/remindhome-login/?loginSocial=twitter`}>
                        <img src={XIcon} alt="XIcon" />
                    </a>
                    <a href={`${process.env.REACT_APP_SITE_URL}/remindhome-login/?loginSocial=facebook`}>
                        <img src={fbIcon} alt="fbIcon" />
                    </a>
                </div>
            </div>
            <h6 className="font-weight-light">Or log in using email</h6>
            <form className="pt-3" onSubmit={handleLogin}>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                        Email <span className="required">*</span>
                    </label>
                    <input type="email" className="form-control form-control-lg" id="exampleInputEmail1" placeholder="mjack@gmail.com" autoComplete="off" required value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <InputGroup>
                    <label className="pass-label flex-row" htmlFor="exampleInputPassword1">
                        Password <span className="required">*</span>
                    </label>
                    <input type={showPass ? 'text' : 'password'} className="form-control form-control-lg auth-password" id="exampleInputPassword1" autoComplete="off" required value={password} onChange={(e) => setPassword(e.target.value)} />
                    <InputGroup.Text id="basic-addon1" onClick={handleShowPassword}>
                        <img src={showPass ? EyeSlashCross : EyeSlash} alt="toggle password visibility" />
                    </InputGroup.Text>
                </InputGroup>
                <div className="my-2 d-flex justify-content-between align-items-center">
                    <div className="form-check"></div>
                    <Link to="/forgot-password" className="auth-link">
                        Forgot password?
                    </Link>
                </div>
                <div className="mt-3">
                    <button type="submit" className="auth-success-btn" disabled={loading}>
                        {loading ? 'Logging in...' : 'Log In'}
                    </button>
                </div>
                <div className="auth-footer">
                    <Link className="auth-link" to="/registration">
                        I don’t have an account
                    </Link>
                </div>
            </form>
        </div>
    );
};

export default Login;
