/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react';
import DataTable, { Alignment, Direction } from 'react-data-table-component';
import lampIcon from '../../asstes/dash-icons/sidebar/Lamp.svg';
import BroomIcon from '../../asstes/dash-icons/sidebar/Broom.svg';
import PurchasesServiceSideView from './purchasesServiceSideView';
import OffCanvasBootstrap from '../../../off_canvas/OffCanvasBootstrap';
import { formatDate } from '../../utils/helper';

const columns = [
    {
        name: 'Type',
        selector: (row) => row.type,
        sortable: true,
        reorder: true
    },
    {
        name: 'Name',
        selector: (row) => row.name || row.vendor,
        sortable: true,
        reorder: true
    },
    // {
    //   name: "Make",
    //   selector: (row) => row.make,
    //   sortable: true,
    //   reorder: true,
    // },
    // {
    //   name: "Model #",
    //   selector: (row) => row.model,
    //   sortable: true,
    //   reorder: true,
    // },
    // {
    //   name: "Project",
    //   selector: (row) => row.project,
    //   sortable: true,
    //   reorder: true,
    // },
    {
        name: 'Date',
        selector: (row) => row.date,
        sortable: true,
        reorder: true
    },
    {
        name: 'Cost',
        selector: (row) => row.cost,
        sortable: true,
        reorder: true
    }
];

const RecentPurchases = ({ purchaseData, serviceData, fetchPurchasesAndServicesData }) => {
    const [continueChange, setContinueChange] = useState(false);
    const [selectedID, setSelectedID] = useState(); // State for storing the service data
    const [itemType, setItemType] = useState(); // State for storing the service data
    const handleRowSideNavOpen = (e) => {
        setContinueChange('viewStep');
        setSelectedID(e.id);
        setItemType(e.itemTye);
    };

    // const handlerUpdatePurchase = async () => {
    //     const { amount, files, project, linked_homeessential, linked_reminders, make, model_number, notes, product_name, purchase_date, warranty, receipt_url, uploaded_files, warranty_data } = purchaseDataU;
    //     const data = new FormData();
    //     data.append('product_name', product_name);
    //     data.append('make', make);
    //     data.append('model_number', model_number);
    //     data.append('purchase_date', purchase_date);
    //     data.append('amount', amount);
    //     linked_reminders.forEach((remind) => {
    //         const reminderData = JSON.stringify({
    //             id: remind.id,
    //             date: remind.start_date,
    //             frequency_id: remind.frequency_id,
    //             frequency: remind.frequency,
    //             reminder_frequency: remind.reminder_frequency,
    //             time: remind.time
    //         });
    //         data.append('linked_reminders[]', reminderData);
    //     });
    //     data.append('linked_homeessential', JSON.stringify(linked_homeessential));
    //     data.append('notes', notes);
    //     data.append('project', project);
    //     data.append('warranty', warranty);
    //     if (warranty) {
    //         data.append('warranty_data[][name]', warranty_data[0].name);
    //         data.append('warranty_data[][number]', warranty_data[1].number);
    //         data.append('warranty_data[][start_date]', warranty_data[2].start_date);
    //         data.append('warranty_data[][end_date]', warranty_data[3].end_date);
    //         data.append('warranty_data[][notes]', warranty_data[4].notes);

    //         if (files && files.warrantyFiles) {
    //             files.warrantyFiles.forEach((file) => data.append('warranty_data_files[]', file));
    //         } else {
    //             warranty_data.files.forEach((file) => data.append('warranty_data_files[]', file));
    //         }
    //     }

    //     if (files && files.receipt) {
    //         files.receipt?.forEach((file) => data.append('receipt[]', file));
    //     } else {
    //         receipt_url?.forEach((file) => data.append('receipt[]', file));
    //     }
    //     if (files && files.files) {
    //         files.files?.forEach((file) => data.append('files[]', file));
    //     } else {
    //         uploaded_files?.forEach((file) => data.append('files[]', file));
    //     }

    //     try {
    //         const res = await updatePurchaseItem(selectedID, data);
    //         if (res.status == 200) {
    //             toast.success(res.data.message);
    //             handlerCancelSideNav();
    //             fetchPurchasesAndServicesData && (await fetchPurchasesAndServicesData());
    //         }
    //     } catch (error) {
    //         toast.error(error);
    //     }
    // };
    // const handlerUpdateService = async () => {
    //     const { service_name, amount, files, project, linked_homeessential, linked_reminders, notes, service_date, vendor, receipt_url, uploaded_files, warranty, warranty_data } = serviceDataU;

    //     const data = new FormData();
    //     data.append('service_name', service_name);
    //     data.append('vendor', vendor);
    //     data.append('service_date', service_date);
    //     data.append('amount', amount);
    //     linked_reminders.forEach((remind) => {
    //         const reminderData = JSON.stringify({
    //             id: remind.id,
    //             date: remind.start_date,
    //             frequency_id: remind.frequency_id,
    //             frequency: remind.frequency,
    //             reminder_frequency: remind.reminder_frequency,
    //             time: remind.time
    //         });
    //         data.append('linked_reminders[]', reminderData);
    //     });
    //     data.append('project', project);
    //     data.append('notes', notes);
    //     data.append('linked_homeessential', JSON.stringify(linked_homeessential));
    //     data.append('warranty', warranty);
    //     if (warranty) {
    //         data.append('warranty_data[][name]', warranty_data[0].name);
    //         data.append('warranty_data[][number]', warranty_data[1].number);
    //         data.append('warranty_data[][start_date]', warranty_data[2].start_date);
    //         data.append('warranty_data[][end_date]', warranty_data[3].end_date);
    //         data.append('warranty_data[][notes]', warranty_data[4].notes);
    //         if (files && files.warrantyFiles) {
    //             files.warrantyFiles.forEach((file) => data.append('warranty_data_files[]', file));
    //         } else {
    //             warranty_data.files.forEach((file) => data.append('warranty_data_files[]', file));
    //         }
    //     }

    //     if (files && files.receipt) {
    //         files.receipt.forEach((file) => data.append('receipt[]', file));
    //     } else {
    //         receipt_url.forEach((file) => data.append('receipt[]', file));
    //     }
    //     if (files && files.files) {
    //         files.files.forEach((file) => data.append('files[]', file));
    //     } else {
    //         uploaded_files.forEach((file) => data.append('files[]', file));
    //     }

    //     try {
    //         const res = await updateServiceItem(selectedID, data);
    //         if (res.status == 200) {
    //             toast.success(res.data.message);
    //             handlerCancelSideNav();
    //             fetchPurchasesAndServicesData && (await fetchPurchasesAndServicesData());
    //         }
    //     } catch (error) {
    //         toast.error(error);
    //     }
    // };

    // Dynamic data mapping
    const mapServiceData = (serviceData) => {
        // Add a fallback for undefined or null serviceData
        return (serviceData || []).map((item) => ({
            id: item.id,
            itemTye: 'service',
            type: (
                <span className={'services-badge type-camel'}>
                    <img src={BroomIcon} alt="Service Icon" />
                    Service
                </span>
            ),
            name: item.vendor,
            make: item.vendor,
            model: '', // No model number for services
            project: item.project, // Assuming project_id is project
            date: formatDate(item.service_date) || null, // Handle missing dates
            cost: `$${item.amount}`
        }));
    };

    const mappedData = useMemo(
        () => [
            ...(purchaseData || []).map((item) => ({
                id: item.id,
                type: (
                    <span className={'services-badge type-green'}>
                        <img src={lampIcon} alt="Type Icon" />
                        Purchase
                    </span>
                ),
                itemTye: 'purchase',
                name: item.product_name,
                make: item.make,
                model: item.model_number,
                project: item.property_id, // Assuming property_id is project
                date: formatDate(item.purchase_date) || null, // Handle missing dates
                cost: `$${item.amount}`
            })),
            ...mapServiceData(serviceData) // This function already has the fallback
        ],
        [purchaseData, serviceData]
    );

    function sortByMostRecentDate(array) {
        return [...array].sort((a, b) => {
            const dateA = a.date ? new Date(a.date).getTime() : 0;
            const dateB = b.date ? new Date(b.date).getTime() : 0;
            return dateB - dateA; // Sort descending
        });
    }

    const sortedData = useMemo(() => sortByMostRecentDate(mappedData), [mappedData]);

    return (
        <div className="recentPurcheseTable mt-3">
            <DataTable
                columns={columns}
                data={sortedData}
                // defaultSortFieldId={2}
                onRowClicked={handleRowSideNavOpen} // Attach click event handler
                selectableRows={false}
                selectableRowsNoSelectAll={false}
                selectableRowsVisibleOnly={false}
                selectableRowsHighlight={false}
                selectableRowsSingle={false}
                expandableRows={false}
                expandOnRowClicked={false}
                expandOnRowDoubleClicked={false}
                expandableRowsHideExpander={false}
                pagination={true}
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                highlightOnHover={true}
                striped={false}
                pointerOnHover={false}
                dense={false}
                persistTableHead={true}
                noHeader={false}
                fixedHeader={false}
                fixedHeaderScrollHeight={'300px'}
                progressPending={false}
                noTableHead={false}
                noContextMenu={false}
                direction={Direction.AUTO}
                subHeader={false}
                subHeaderAlign={Alignment.RIGHT}
                subHeaderWrap={true}
                responsive={true}
                disabled={false}
            />
            {continueChange === 'viewStep' && (
                <OffCanvasBootstrap
                    placement="end"
                    name="end"
                    show={continueChange === 'viewStep'}
                    handleClose={() => {
                        setContinueChange(false);
                    }}
                >
                    <PurchasesServiceSideView
                        handleClose={() => {
                            setContinueChange(false);
                        }}
                        itemType={itemType}
                        selectedId={selectedID}
                    />
                </OffCanvasBootstrap>
            )}
        </div>
    );
};

export default RecentPurchases;
