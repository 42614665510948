import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import dashboardMenuItem from '../inc/menu-items';
import commandImg from '../../asstes/dash-icons/sidebar/Command.svg';
import menuPlusIcon from '../../asstes/dash-icons/menuPlusIcon.svg';
import CaretUpDown from '../../asstes/dash-icons/CaretUpDown.svg';
import '../../asstes/styles/mainSidebarStyles.css';
import SearchModel from '../inc/searchModel';
import NotificationPopup from '../inc/notification-popup';
import propertyImg from '../../asstes/images/propertyImg.jpeg';
import homeIcon from '../../asstes/dash-icons/homeIcon.svg';
import { ReactComponent as DelIcon } from '../../asstes/dash-icons/deleteIcon.svg';
import { addPropertyData, deleteProperties, get_event_lists, getAllPropertiesNotifications, getProperties } from '../../../axiosCalls/userdashboard';
import PropertyNewSideNav from '../inc/propertyNewSideNav';
import { toast } from 'react-toastify';
import { isPlanActive } from '../../utils/helper';
import ConfirmationModal from '../inc/confirmationModa';
import Loader from '../loader/Loader';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import types from '../../../redux/actions/actionTypes';
import PlanEffectModal from './PlanEffectModal';

const MainSidebar = ({ stateSide, handlerCloseSidebarFromClick }) => {
    const navigate = useNavigate(); // Initialize navigate function
    const floatingListRef = useRef(null); // Create a ref for the floating list
    // Add a click listener to detect clicks outside the floating list
    const [showPropertyList, setShowPropertyList] = useState(false); // Toggle for property list
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if the click is outside the floating list
            if (floatingListRef.current && !floatingListRef.current.contains(event.target)) {
                setShowPropertyList(showPropertyList ? false : showPropertyList); // Close the floating list
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setShowPropertyList]);
    const [propertyData, setPropertyData] = useState({}); // Initialize with any default data if needed
    const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState('0%');
    const [sideNavWidth, setsideNavWidth] = useState(stateSide);
    const [activeItem, setActiveItem] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [propertyCheckMain, setPropertyCheckMain] = useState(false);
    const [notificationPopup, setNotificationPopup] = useState(false);
    const [formValues, setFormValues] = useState([]);
    const [properties, setProperties] = useState([]); // State to store properties
    const [notifications, setNotifications] = useState([]);
    const [delConfrm, setDelConfrm] = useState(false);
    const [selectForDelProp, setSelectForDelProp] = useState(null);
    const [currentTotalProperties, setCurrentTotalProperties] = useState(0);
    const [selectedProperty, setSelectedProperty] = useState(() => {
        return JSON.parse(localStorage.getItem('selectedProperty')) || null;
    }); // Load from localStorage
    // const [valueForAdd, setValueForAdd] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [isLoading, setIsLoading] = useState(false); // Loading state
    const isPlnActive = isPlanActive();
    const userSession = JSON.parse(localStorage.getItem('user'));
    const trialDate = new Date(userSession?.meta_data?.trial_end_date);
    const currentDate = new Date();
    const isTrailEnd = currentDate > trialDate;
    const property_allow = userSession?.meta_data?.property_allow && Number(userSession?.meta_data?.property_allow);
    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const res = await get_event_lists();
                const eventData = res.data || [];
                const unreadEvents = eventData?.filter((event) => event.read === null);
                setNotifications(unreadEvents);
            } catch (error) {
                console.error('Failed to fetch events:', error);
            }
        };

        fetchEvents();
    }, []);

    const closeDelConfrmModal = () => {
        setDelConfrm(false);
        setSelectForDelProp(null);
    };
    const handlerCancelSideNav = () => {
        setsideNavWidthOverlay('0%');
        setsideNavWidth('close');
        setPropertyCheckMain(true);
    };

    useEffect(() => {
        handlerFetchProperty();

        const handleKeyPress = (event) => {
            if (event.ctrlKey && event.key === 'f') {
                event.preventDefault();
                setModalShow(true);
            }
        };

        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const handlerFetchProperty = async () => {
        try {
            const response = await getProperties();
            if (response && response.data) {
                setProperties(response.data); // Update state with fetched properties
                const fetchedProperties = response.data;
                setCurrentTotalProperties(fetchedProperties.length);
                // If no property is selected, set the first property as the default
                if (!selectedProperty && fetchedProperties.length > 0) {
                    const firstProperty = fetchedProperties[0];
                    setSelectedProperty(firstProperty);
                    localStorage.setItem('selectedProperty', JSON.stringify(firstProperty));
                }
            }
        } catch (error) {
            console.error('Error fetching properties:', error);
        }
    };
    const handlePropertyClick = (e) => {
        e.stopPropagation(); // Prevent the click event from bubbling up to the document
        setShowPropertyList(!showPropertyList); // Toggle the list visibility
    };

    const handleSelectProperty = (property) => {
        setSelectedProperty(property); // Update the selected property
        localStorage.setItem('selectedProperty', JSON.stringify(property)); // Store in localStorage
        setShowPropertyList(false); // Hide the list after selection
        window.location.reload();
    };

    const handleItemClick = (id) => {
        if (id === 'logout') {
            // Clear the authentication token or any other user data
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('selectedProperty');

            // Redirect to the login page or another appropriate page
            navigate('/login');
        } else {
            setActiveItem(id);
        }

        handlerCloseSidebarFromClick('close');

        setsideNavWidth('close');
        document.body.classList.remove('no-scroll');
        if (id === 'search') {
            // setModalShow(true);
            navigate('/search');
        }

        if (id === 'notification') {
            setNotificationPopup(!notificationPopup);
        } else {
            setNotificationPopup(false);
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    const handlerColseNotification = (val) => {
        setNotificationPopup(val);
    };

    const [planEffectModal, setPlanEffectModal] = useState(false);

    const handleOpenSideNavPropertyAdd = () => {
        if (!isPlnActive || currentTotalProperties < property_allow || property_allow >= 3) {
            setsideNavWidthOverlay('100%');
            setsideNavWidth('sidenavPopuWidth');
            document.body.classList.add('no-scroll');
            setPropertyCheckMain(false);
            return;
        }
        if ([1, 2].includes(property_allow) && isPlnActive) {
            // show warning modal
            setPlanEffectModal(true);
        }
    };
    const handlerAddNewProperties = async () => {
        // Validate required fields

        setIsLoading(true); // Set loading state to true
        const data = new FormData();
        if (formValues.propertyName) data.append('title', formValues.propertyName);
        data.append('address_1', formValues.address1);
        if (formValues.address2) data.append('address_2', formValues.address2);
        if (formValues.city) data.append('city', formValues.city);
        data.append('state', formValues.state);
        data.append('postal_code', formValues.postalCode);
        if (formValues.propertyType) data.append('type', formValues.propertyType);
        if (formValues.propertyCategory) data.append('category', formValues.propertyCategory);
        if (formValues.notes) data.append('notes', formValues.notes);

        if (formValues.postThumNailFiles) {
            for (let i = 0; i < formValues.postThumNailFiles.length; i++) {
                data.append('files[]', formValues.postThumNailFiles[i]);
            }
        }

        if (formValues.postThumNailImage) {
            data.append('property_image', formValues.postThumNailImage);
        }
        try {
            setIsSubmitted(true);
            if (!formValues.propertyName || !formValues.address1 || !formValues.city || !formValues.state || !formValues.postalCode) {
                // toast.error("Please fill out required fields.");
                return;
            }
            const response = await addPropertyData(data);
            if (response && response.data) {
                toast.success(response.data.message);
                setIsLoading(false); // Set loading state to true
                handlerCancelSideNav();
                setFormValues([]);
                setIsSubmitted(false);
                setPropertyCheckMain(true);
                handlerFetchProperty();
                //
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };
    const [allNotificationCount, setAllNotificationCount] = useState(null);
    const [allPropertiesNotifications, setAllPropertiesNotifications] = useState([]);
    const fetchAllPropertiesNotifications = async () => {
        try {
            const res = await getAllPropertiesNotifications();
            if (res?.data?.length > 0) {
                setAllPropertiesNotifications(res?.data || []);
                // count by notificationCount inside res?.data
                const count = res?.data?.reduce((acc, curr) => {
                    return acc + (curr.notifications || [])?.filter((n) => n.status_task !== 'completed')?.length;
                }, 0);
                setAllNotificationCount(count);
            }
        } catch (error) {
            // handle error
        }
    };
    useEffect(() => {
        fetchAllPropertiesNotifications();
    }, []);

    const dispatch = useDispatch();

    const isGetAllReminderEvents = useSelector((state) => state?.root?.isGetAllReminderEvents);

    useEffect(() => {
        if (isGetAllReminderEvents) {
            fetchAllPropertiesNotifications();
            dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetAllReminderEvents', data: false } });
        }
    }, [isGetAllReminderEvents]);

    const location = useLocation();

    const [notificationChildrenVisible, setNotificationChildrenVisible] = useState(false); // To toggle children visibility

    // Function to handle notification item click
    const handleNotificationClick = () => {
        setNotificationChildrenVisible(!notificationChildrenVisible); // Toggle child visibility
        // setNotificationPopup(!notificationPopup); // Toggle the popup
    };

    // Function to handle child click
    const [selectedPropertyForEvents, setSelectedPropertyForEvents] = useState(null);
    const handleNotificationChildClick = (child) => {
        setSelectedPropertyForEvents(child);
        setNotificationPopup(true); // Show the popup
        // You can add more logic here, such as specific actions for each child
    };

    // Render function for the notification children
    const renderNotificationChildren = () => {
        if (!notificationChildrenVisible) return null;

        return (
            <>
                {allPropertiesNotifications.map((child, index) => (
                    <li style={{ cursor: 'pointer' }} className={`nav-item`} key={index}>
                        <div className={`align-items-center d-flex nav-link ${!isPlnActive && isTrailEnd ? 'disabled' : ''} `} onClick={() => handleNotificationChildClick(child)}>
                            <img src={homeIcon} className="me-2" alt="homeIcon" />
                            <span className="menu-title">{child?.property}</span>
                            <div className="notify-badge">{child.notifications?.filter((n) => n.status_task !== 'completed')?.length}</div>
                        </div>
                    </li>
                ))}
            </>
        );
    };

    // Notification menu item
    const NavMenu = dashboardMenuItem.children_1.map((item) => {
        const isActive = location.pathname === item.url;
        const activeClassName = isActive ? 'active' : '';
        if (item.id === 'notification') {
            return (
                <li className={`nav-item ${activeClassName}`} id={item.id} key={item.id} onClick={handleNotificationClick}>
                    <span className="nav-link">
                        <span className="nav-icon" dangerouslySetInnerHTML={{ __html: item.icon }} />
                        <span className="menu-title">{item.title || ''}</span>
                        {(allNotificationCount || allNotificationCount === 0) && <div className="notify-badge">{allNotificationCount}</div>}
                    </span>
                    {renderNotificationChildren()} {/* Render children conditionally */}
                </li>
            );
        }

        return (
            <li className={`nav-item ${activeClassName}`} id={item.id} key={item.id}>
                <Link className={`nav-link ${!isPlnActive && isTrailEnd ? 'disabled' : ''} `} to={item.url} onClick={() => handleItemClick(item.id)}>
                    <span className="nav-icon" dangerouslySetInnerHTML={{ __html: item.icon }} />
                    <span className="menu-title">{item.title}</span>
                </Link>
            </li>
        );
    });

    const NavMenu_2 = dashboardMenuItem.children_2.map((item) => {
        const isActive = location.pathname === item.url;
        const activeClassName = isActive ? 'active' : '';

        return (
            <li className={`nav-item  ${activeClassName}`} id={item.id} key={item.id}>
                <Link className={`nav-link ${!isPlnActive && isTrailEnd ? 'disabled' : ''} `} to={item.url} onClick={() => handleItemClick(item.id)}>
                    <span className="nav-icon" dangerouslySetInnerHTML={{ __html: item.icon }} />
                    <span className="menu-title">{item.title}</span>
                    {item.addIcon && (
                        <div className="add-icon">
                            <img src={menuPlusIcon} />
                        </div>
                    )}
                </Link>
            </li>
        );
    });

    const NavMenu_3 = dashboardMenuItem.children_3.map((item) => {
        const isActive = location.pathname === item.url;
        const activeClassName = isActive ? 'active' : '';

        return (
            <li className={`nav-item ${activeClassName}`} id={item.id} key={item.id}>
                <Link className={item.id === 'logout' ? 'nav-link logoutIcon' : 'nav-link'} to={item.url} onClick={() => handleItemClick(item.id)}>
                    <span className="nav-icon" dangerouslySetInnerHTML={{ __html: item.icon }} />
                    <span className="menu-title">{item.title}</span>
                </Link>
            </li>
        );
    });
    const deletePropertiesFunc = () => {
        setIsLoading(true);
        const cloneProperty = [...properties];
        deleteProperties(selectForDelProp?.id)
            .then((resp) => {
                const findPropIndx = cloneProperty.findIndex((prop) => prop?.id === selectForDelProp?.id);
                if (findPropIndx > -1) {
                    cloneProperty.splice(findPropIndx, 1);
                }
                setProperties(cloneProperty);
                closeDelConfrmModal();
            })
            .finally(() => setIsLoading(false));
    };

    const [searchQuery, setSearchQuery] = useState('');
    const [place, setPlace] = useState(null);

    return (
        <>
            <nav className={`sidebar sidebar-offcanvas ${stateSide === 'open' ? 'active' : ''}`} id="sidebar">
                <div className="sidebar-inner">
                    {selectedProperty ? (
                        <>
                            <ul className={`nav choose-property ${!isPlnActive && isTrailEnd ? 'disabled' : ''}`} id="add-property" onClick={handlePropertyClick}>
                                <div className="choose-property-img">{selectedProperty.thumbnail_url ? <img src={selectedProperty.thumbnail_url} alt="propertyImg" /> : <img src={homeIcon} alt="homeIcon" />}</div>
                                <div className="choose-property-title" dangerouslySetInnerHTML={{ __html: selectedProperty.title }}></div>

                                <div className="choose-property-notify">
                                    <div className="total-properties">{properties.length}</div>
                                    <div className="choose-property-icons">
                                        <img src={CaretUpDown} alt="CaretUpDown" />
                                    </div>
                                </div>
                            </ul>
                        </>
                    ) : (
                        <ul className={`nav choose-property ${!isPlnActive && isTrailEnd ? 'disabled' : ''}`} id="add-property" onClick={handleOpenSideNavPropertyAdd}>
                            <div className="choose-property-img-add">
                                <img src={homeIcon} alt="homeIcon" />
                            </div>
                            <div className="choose-property-title-add">Add property</div>
                            <div className="choose-property-notify">
                                <div className="choose-property-icons-add">
                                    <img src={menuPlusIcon} alt="menuPlusIcon" />
                                </div>
                            </div>
                        </ul>
                    )}

                    {/* Floating list of properties */}
                    {showPropertyList && (
                        <div className="floating-property-list" ref={floatingListRef}>
                            <ul>
                                {properties.map((property) => (
                                    <li className="d-flex" key={property.id} onClick={() => handleSelectProperty(property)}>
                                        <span className="" dangerouslySetInnerHTML={{ __html: property.title }}></span>

                                        {selectedProperty?.id !== property?.id && (
                                            <DelIcon
                                                className="ms-auto"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectForDelProp(property);
                                                    setDelConfrm(true);
                                                }}
                                            />
                                        )}
                                    </li>
                                ))}
                            </ul>
                            {properties?.length < 5 && (
                                <ul className="nav choose-property" onClick={handleOpenSideNavPropertyAdd}>
                                    <div className="choose-property-img-add">
                                        <img src={homeIcon} alt="homeIcon" />
                                    </div>
                                    <div className="choose-property-title-add">Add Property</div>
                                    <div className="choose-property-notify">
                                        <div className="choose-property-icons-add">
                                            <img src={menuPlusIcon} alt="menuPlusIcon" />
                                        </div>
                                    </div>
                                </ul>
                            )}
                        </div>
                    )}

                    <ul className="nav">{NavMenu}</ul>
                    <div className="nav-divider"></div>
                    <ul className="nav">{NavMenu_2}</ul>
                    <div className="nav-divider"></div>
                    <ul className="nav">{NavMenu_3}</ul>
                    <div className="nav-divider"></div>
                    <ul className="copyRightContent">
                        <span>Remind, {new Date().getFullYear()}</span>
                    </ul>
                </div>
                {/* <Link to="/seller/overview" className="affliateProgramCard">
          <div className="affliateProgramCard-title">
            remind Affiliate Program
          </div>
          <div className="affliateProgramCard-des">
            Velit adipiscing ornare arcu at mauris purus quis cursus.
          </div>
          <div className="affliateProgramCard-button">Read more</div>
        </Link> */}
            </nav>
            <SearchModel show={modalShow} onHide={() => setModalShow(false)} />
            {notificationPopup && <NotificationPopup fetchAllPropertiesNotifications={fetchAllPropertiesNotifications} selectedPropertyForEvents={selectedPropertyForEvents} handlerColseNotification={handlerColseNotification} />}

            <div
                onClick={() => {
                    setFormValues([]);
                    setPlace(null);
                    setSearchQuery('');
                    handlerCancelSideNav();
                }}
                style={{ width: sideNavWidthOverlay }}
                className="sidenav-overlay"
            ></div>

            <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>
                <PropertyNewSideNav place={place} setPlace={setPlace} searchQuery={searchQuery} setSearchQuery={setSearchQuery} setFormData={setFormValues} propertyCheckMain={propertyCheckMain} isSubmitted={isSubmitted} />

                <div className="side-footer">
                    <button
                        className="footer-button-cancel"
                        onClick={() => {
                            setFormValues([]);
                            setPlace(null);
                            setSearchQuery('');
                            handlerCancelSideNav();
                        }}
                    >
                        Cancel
                    </button>

                    <button className="footer-button-primary " disabled={isLoading ? true : false} onClick={handlerAddNewProperties}>
                        {isLoading ? <Loader size="sm" /> : 'Save'}
                    </button>
                </div>
            </div>

            <ConfirmationModal show={delConfrm} handleClose={closeDelConfrmModal} loading={isLoading} hanndleDelete={deletePropertiesFunc} />

            <PlanEffectModal
                property_allow={property_allow}
                setsideNavWidthOverlay={setsideNavWidthOverlay}
                setsideNavWidth={setsideNavWidth}
                setPropertyCheckMain={setPropertyCheckMain}
                showModal={planEffectModal}
                closeModal={() => {
                    setPlanEffectModal(false);
                }}
            />
        </>
    );
};

export default MainSidebar;
